/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSparkMath = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="134.821"
    height="33.954"
    viewBox="0 0 134.821 33.954"
    {...props}
  >
    <g transform="translate(-21.05 -24.699)">
      <g transform="translate(21.05 24.699)">
        <path
          d="M119.174,79.45a8.04,8.04,0,0,1-1.422,5,4.942,4.942,0,0,1-7.375.405v6.4h-3.032V72.562h2.8l.125,1.373a4.454,4.454,0,0,1,3.631-1.622,4.6,4.6,0,0,1,3.875,1.841,8.254,8.254,0,0,1,1.4,5.11Zm-3.02-.262a5.63,5.63,0,0,0-.805-3.207,2.616,2.616,0,0,0-2.3-1.185,2.79,2.79,0,0,0-2.671,1.535v5.99a2.817,2.817,0,0,0,2.7,1.572,2.613,2.613,0,0,0,2.265-1.167A6.2,6.2,0,0,0,116.154,79.188Z"
          transform="translate(-80.14 -57.302)"
          fill="#637885"
        />
        <path
          d="M160.138,86.064a4.674,4.674,0,0,1-.35-1.261,5.1,5.1,0,0,1-6.863.349,3.711,3.711,0,0,1-1.286-2.87,3.834,3.834,0,0,1,1.6-3.314,7.773,7.773,0,0,1,4.586-1.154h1.86V76.93a2.367,2.367,0,0,0-.587-1.679,2.326,2.326,0,0,0-1.784-.63,2.673,2.673,0,0,0-1.7.518,1.6,1.6,0,0,0-.661,1.316h-3.032a3.367,3.367,0,0,1,.736-2.078,4.907,4.907,0,0,1,2-1.516,7.035,7.035,0,0,1,2.827-.549,5.669,5.669,0,0,1,3.781,1.192,4.251,4.251,0,0,1,1.448,3.351v6.09a6.894,6.894,0,0,0,.512,2.907v.212Zm-3.332-2.184a3.448,3.448,0,0,0,1.691-.437,2.918,2.918,0,0,0,1.192-1.173V79.725h-1.635a4.471,4.471,0,0,0-2.533.586,1.9,1.9,0,0,0-.849,1.66,1.771,1.771,0,0,0,.58,1.391A2.247,2.247,0,0,0,156.806,83.881Z"
          transform="translate(-110.471 -57.302)"
          fill="#637885"
        />
        <path
          d="M203.921,75.332a7.509,7.509,0,0,0-1.235-.1,2.781,2.781,0,0,0-2.808,1.6v9.235h-3.033v-13.5h2.9l.075,1.51a3.416,3.416,0,0,1,3.045-1.76,2.844,2.844,0,0,1,1.073.175Z"
          transform="translate(-141.425 -57.302)"
          fill="#637885"
        />
        <path
          d="M230.976,68.51l-1.348,1.385V74.3H226.6V55.132h3.032V66.189L230.577,65l3.731-4.205h3.644l-5.017,5.628,5.553,7.874h-3.507Z"
          transform="translate(-161.797 -45.538)"
          fill="#637885"
        />
        <g transform="translate(10.988 10.247)">
          <path
            d="M66.326,70.943a2.194,2.194,0,0,0-.843-1.847,10.142,10.142,0,0,0-3.038-1.311,14.639,14.639,0,0,1-3.494-1.473,4.662,4.662,0,0,1-2.483-4.068,4.428,4.428,0,0,1,1.791-3.619A7.248,7.248,0,0,1,62.907,57.2a7.857,7.857,0,0,1,3.382.7,5.553,5.553,0,0,1,2.334,1.991,5.1,5.1,0,0,1,.849,2.864H66.326a2.847,2.847,0,0,0-.892-2.228,3.681,3.681,0,0,0-2.552-.8,3.845,3.845,0,0,0-2.4.661,2.2,2.2,0,0,0-.855,1.847,1.991,1.991,0,0,0,.923,1.666,10.626,10.626,0,0,0,3.045,1.3A14.024,14.024,0,0,1,67,66.631a5.425,5.425,0,0,1,1.885,1.847,4.815,4.815,0,0,1,.6,2.44,4.324,4.324,0,0,1-1.741,3.612,7.551,7.551,0,0,1-4.723,1.341,8.87,8.87,0,0,1-3.625-.73,6.088,6.088,0,0,1-2.571-2.015,5.024,5.024,0,0,1-.917-3h3.157a2.938,2.938,0,0,0,1.023,2.4,4.481,4.481,0,0,0,2.933.848,3.9,3.9,0,0,0,2.477-.667A2.154,2.154,0,0,0,66.326,70.943Z"
            transform="translate(-55.906 -57.203)"
            fill="#637885"
          />
        </g>
        <path
          d="M39.713,47.265a.237.237,0,0,1-.143-.049l-2.3-1.753a.237.237,0,0,1,.287-.376l2.3,1.753a.236.236,0,0,1-.144.425Z"
          transform="translate(-32.094 -38.626)"
          fill="#637885"
        />
        <path
          d="M50.575,55.55a.235.235,0,0,1-.143-.049l-2.848-2.173a.236.236,0,1,1,.287-.376l2.848,2.173a.236.236,0,0,1-.144.424Z"
          transform="translate(-39.155 -44.013)"
          fill="#637885"
        />
        <path
          d="M84.457,28.736a.262.262,0,0,1-.044,0,.236.236,0,0,1-.188-.277l.683-3.564a.236.236,0,1,1,.464.089l-.683,3.564A.237.237,0,0,1,84.457,28.736Z"
          transform="translate(-64.306 -24.699)"
          fill="#637885"
        />
        <path
          d="M81.418,44.155a.234.234,0,0,1-.044,0,.236.236,0,0,1-.188-.277l.723-3.774a.236.236,0,1,1,.464.089l-.723,3.774A.236.236,0,0,1,81.418,44.155Z"
          transform="translate(-62.225 -35.113)"
          fill="#637885"
        />
        <path
          d="M26.371,69.726H26.36l-5.084-.236a.237.237,0,0,1-.225-.247.231.231,0,0,1,.247-.225l5.084.236a.236.236,0,0,1-.011.473Z"
          transform="translate(-21.05 -55.046)"
          fill="#637885"
        />
        <path
          d="M43.327,70.511h-.011L41.3,70.418a.236.236,0,0,1,.022-.472l2.012.093a.236.236,0,0,1-.011.473Z"
          transform="translate(-34.764 -55.681)"
          fill="#637885"
        />
        <path
          d="M35.464,89.5a.237.237,0,0,1-.1-.452l1.3-.579a.236.236,0,0,1,.193.432l-1.3.579A.238.238,0,0,1,35.464,89.5Z"
          transform="translate(-30.758 -68.353)"
          fill="#637885"
        />
        <path
          d="M43.057,83.907a.236.236,0,0,1-.1-.452l3.571-1.594a.237.237,0,0,1,.193.432l-3.571,1.594A.237.237,0,0,1,43.057,83.907Z"
          transform="translate(-35.957 -63.826)"
          fill="#637885"
        />
        <path
          d="M101.626,48.041a.237.237,0,0,1-.16-.411l2.008-1.847a.236.236,0,0,1,.32.348l-2.008,1.847A.237.237,0,0,1,101.626,48.041Z"
          transform="translate(-76.062 -39.094)"
          fill="#637885"
        />
        <path
          d="M95.76,55.506a.236.236,0,0,1-.16-.41l.972-.894a.237.237,0,0,1,.32.348l-.972.894A.235.235,0,0,1,95.76,55.506Z"
          transform="translate(-72.046 -44.858)"
          fill="#637885"
        />
        <path
          d="M63.043,30.661a.236.236,0,0,1-.227-.172l-.8-2.849a.236.236,0,1,1,.455-.128l.8,2.849a.236.236,0,0,1-.164.292A.222.222,0,0,1,63.043,30.661Z"
          transform="translate(-49.093 -26.507)"
          fill="#637885"
        />
        <path
          d="M66.63,43.39a.236.236,0,0,1-.228-.172l-.848-3.01a.236.236,0,1,1,.455-.128l.848,3.009a.236.236,0,0,1-.164.292A.224.224,0,0,1,66.63,43.39Z"
          transform="translate(-51.518 -35.113)"
          fill="#637885"
        />
        <path
          d="M43.576,32.192a.237.237,0,0,1-.233-.276l.2-1.146-.833-.812a.236.236,0,0,1,.131-.4l1.151-.167.515-1.043a.237.237,0,0,1,.212-.132h0a.237.237,0,0,1,.212.132l.514,1.043,1.151.167a.237.237,0,0,1,.131.4l-.833.812.2,1.146a.236.236,0,0,1-.343.249l-1.03-.541-1.03.541A.233.233,0,0,1,43.576,32.192Zm-.2-2.239.579.564a.237.237,0,0,1,.068.209l-.137.8.716-.376a.238.238,0,0,1,.22,0l.715.376-.137-.8a.237.237,0,0,1,.068-.209l.579-.564-.8-.116a.236.236,0,0,1-.178-.129l-.358-.725-.358.725a.236.236,0,0,1-.178.129Z"
          transform="translate(-35.831 -27.104)"
          fill="#637885"
        />
        <path
          d="M91.944,37.125a.24.24,0,0,1-.09-.018.237.237,0,0,1-.145-.2l-.077-1.161-1-.595a.236.236,0,0,1,.033-.423l1.08-.432L92,33.16a.237.237,0,0,1,.412-.1l.744.894,1.159-.106a.236.236,0,0,1,.222.362l-.62.984.459,1.069a.237.237,0,0,1-.276.322L92.975,36.3l-.875.767A.237.237,0,0,1,91.944,37.125Zm-.658-2.138.695.413a.236.236,0,0,1,.115.188l.053.807.608-.533a.235.235,0,0,1,.214-.051l.784.2-.319-.743a.238.238,0,0,1,.017-.219l.431-.684-.805.074a.238.238,0,0,1-.2-.084l-.517-.621-.178.788a.236.236,0,0,1-.143.167Zm.663-.52h0Z"
          transform="translate(-68.617 -30.367)"
          fill="#637885"
        />
        <path
          d="M41.933,101.7a.238.238,0,0,1-.158-.061l-.864-.778-1.131.271a.237.237,0,0,1-.271-.326l.473-1.063-.607-.992a.235.235,0,0,1,0-.249.238.238,0,0,1,.225-.109l1.156.121.756-.884a.236.236,0,0,1,.411.1l.242,1.138,1.074.445a.236.236,0,0,1,.028.423l-1.007.582-.091,1.159a.237.237,0,0,1-.236.218Zm-.957-1.334a.238.238,0,0,1,.158.061l.6.541.063-.806a.237.237,0,0,1,.118-.186l.7-.4-.747-.31a.236.236,0,0,1-.141-.169l-.169-.791-.525.615a.235.235,0,0,1-.2.081l-.8-.084.422.689a.238.238,0,0,1,.014.22l-.328.739.786-.189A.251.251,0,0,1,40.976,100.364Z"
          transform="translate(-33.573 -74.58)"
          fill="#637885"
        />
      </g>
      <g transform="translate(100.343 34.226)">
        <path
          d="M276.665,58.087l5.241,13.926,5.228-13.926h4.08V76.255H288.07V70.266l.312-8.011-5.366,14h-2.258L275.4,62.267l.312,8v5.989h-3.145V58.087Z"
          transform="translate(-272.572 -57.089)"
          fill="#637885"
        />
        <path
          d="M341.246,82.069a3.834,3.834,0,0,1,1.6-3.313,7.77,7.77,0,0,1,4.586-1.154h1.86v-.886a2.366,2.366,0,0,0-.587-1.678,2.325,2.325,0,0,0-1.784-.63,2.672,2.672,0,0,0-1.7.518,1.6,1.6,0,0,0-.661,1.316h-3.032a3.368,3.368,0,0,1,.736-2.078,4.91,4.91,0,0,1,2-1.516A7.039,7.039,0,0,1,347.1,72.1a5.666,5.666,0,0,1,3.781,1.192,4.251,4.251,0,0,1,1.448,3.35V82.73a6.9,6.9,0,0,0,.511,2.908v.212h-3.095a4.676,4.676,0,0,1-.349-1.26,5.1,5.1,0,0,1-6.863.349A3.712,3.712,0,0,1,341.246,82.069Zm3.032-.312a1.772,1.772,0,0,0,.58,1.391,2.247,2.247,0,0,0,1.554.518,3.449,3.449,0,0,0,1.691-.437,2.924,2.924,0,0,0,1.192-1.173V79.511H347.66a4.468,4.468,0,0,0-2.533.587A1.9,1.9,0,0,0,344.278,81.757Z"
          transform="translate(-319.596 -66.683)"
          fill="#637885"
        />
        <path
          d="M381.857,65.762h2.221V62.48h3.032v3.282h2.383v2.246H387.11v7.537a1.658,1.658,0,0,0,.306,1.117,1.418,1.418,0,0,0,1.092.343,4.673,4.673,0,0,0,1.061-.125v2.346a7.482,7.482,0,0,1-2,.287q-3.494,0-3.494-3.856V68.008h-2.221Z"
          transform="translate(-347.405 -60.097)"
          fill="#637885"
        />
        <path
          d="M413.284,54.92h3.033v7.138a4.744,4.744,0,0,1,3.756-1.722q4.317,0,4.38,4.929v8.822H421.42v-8.71a2.666,2.666,0,0,0-.605-1.978,2.474,2.474,0,0,0-1.778-.58,2.92,2.92,0,0,0-2.72,1.622v9.646h-3.033Z"
          transform="translate(-368.924 -54.92)"
          fill="#637885"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconSparkMath)
