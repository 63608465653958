/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconVideoAISuite = (props) => (
  <SVG
    width="47"
    height="47"
    viewBox="0 0 47 47"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.8352 4.16092H31.9004V0H29.1264V4.16092H22.1916C19.9031 4.16092 18.0306 5.86689 18.0306 7.97509V15.2567H20.8046V7.97509C20.8046 7.40643 21.4426 6.93486 22.1916 6.93486H38.8491C39.5981 6.93486 40.2361 7.40643 40.2361 7.97509V22.5244C40.2361 23.0931 39.5981 23.5647 38.8491 23.5647H26.3802V26.3386H38.8491C41.1376 26.3386 43.01 24.6326 43.01 22.5244V7.96122C43.01 5.85302 41.1376 4.14705 38.8491 4.14705L38.8352 4.16092Z"
      fill="#637885"
    />
    <path
      d="M29.1403 13.8558C29.1403 11.9418 27.5869 10.3884 25.6729 10.3884C23.7589 10.3884 22.2054 11.9418 22.2054 13.8558C22.2054 15.7699 23.7589 17.3233 25.6729 17.3233C27.5869 17.3233 29.1403 15.7699 29.1403 13.8558ZM25.6729 14.5493C25.2845 14.5493 24.9794 14.2442 24.9794 13.8558C24.9794 13.4675 25.2845 13.1623 25.6729 13.1623C26.0612 13.1623 26.3664 13.4675 26.3664 13.8558C26.3664 14.2442 26.0612 14.5493 25.6729 14.5493Z"
      fill="#637885"
    />
    <path
      d="M38.849 13.8558C38.849 11.9418 37.2956 10.3884 35.3816 10.3884C33.4676 10.3884 31.9142 11.9418 31.9142 13.8558C31.9142 15.7699 33.4676 17.3233 35.3816 17.3233C37.2956 17.3233 38.849 15.7699 38.849 13.8558ZM34.6881 13.8558C34.6881 13.4814 34.9933 13.1623 35.3816 13.1623C35.77 13.1623 36.0751 13.4675 36.0751 13.8558C36.0751 14.2442 35.77 14.5493 35.3816 14.5493C34.9933 14.5493 34.6881 14.2442 34.6881 13.8558Z"
      fill="#637885"
    />
    <path
      d="M33.2735 19.4037H27.7256V22.1777H33.2735V19.4037Z"
      fill="#637885"
    />
    <path
      d="M12.4827 40.2222C5.58871 40.2222 0 34.6334 0 27.7394C0 20.8454 5.58871 15.2567 12.4827 15.2567C19.3767 15.2567 24.9655 20.8454 24.9655 27.7394C24.9655 34.6334 19.3767 40.2222 12.4827 40.2222ZM12.4827 37.7256C17.998 37.7256 22.4689 33.2547 22.4689 27.7394C22.4689 22.2242 17.998 17.7532 12.4827 17.7532C6.96752 17.7532 2.49655 22.2242 2.49655 27.7394C2.49655 33.2547 6.96752 37.7256 12.4827 37.7256ZM10.7625 23.2639L16.8527 27.324C17.0821 27.4769 17.1442 27.787 16.9911 28.0164C16.9546 28.0712 16.9075 28.1183 16.8527 28.1549L10.7625 32.215C10.5331 32.368 10.223 32.306 10.0701 32.0766C10.0154 31.9946 9.9862 31.8982 9.9862 31.7996V23.6793C9.9862 23.4036 10.2098 23.18 10.4855 23.18C10.5841 23.18 10.6805 23.2092 10.7625 23.2639Z"
      fill="#637885"
    />
  </SVG>
)

export default withIconStyles(IconVideoAISuite)
