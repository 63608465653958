/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconDataStudioSubscription = (props) => (
  <SVG
    width="95"
    height="81"
    viewBox="0 0 95 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M82.4956 71.9344H82.5141C89.7334 71.5651 95.2725 65.4167 94.9032 58.1974C94.5708 51.6059 89.3825 46.3068 82.8095 45.8268C82.7356 45.8268 82.6433 45.8452 82.5879 45.9006C82.5325 45.956 82.4956 46.0299 82.4956 46.1222L82.2002 71.639C82.2002 71.8051 82.3294 71.9344 82.4956 71.9344Z"
      fill="#3F3D56"
    />
    <path
      d="M72.2298 50.0549C72.3222 50.0549 72.4329 50.0919 72.5068 50.1657L81.2955 59.1575C81.2955 59.1575 81.4063 59.3237 81.4063 59.4345L81.277 71.6389C81.277 71.7497 81.2401 71.842 81.1478 71.9159C81.0739 71.9897 80.9631 72.0267 80.8524 72.0082C73.5962 71.4543 68.1678 65.1213 68.7217 57.8466C68.9433 55.0032 70.0696 52.3075 71.9529 50.1657C72.0267 50.0734 72.1375 50.0365 72.2483 50.0365L72.2298 50.0549Z"
      fill="#E6E6E6"
    />
    <path
      d="M81.1664 45.716C81.2587 45.716 81.351 45.7529 81.4248 45.8268C81.4987 45.9006 81.5541 46.0114 81.5356 46.1222L81.4064 57.145C81.4064 57.3481 81.2218 57.5327 81.0187 57.5327C80.9079 57.5327 80.8155 57.4958 80.7417 57.422L73.0793 49.5749C72.9316 49.4272 72.9316 49.1872 73.0793 49.0395C75.3134 47.0823 78.1383 45.9191 81.0925 45.7714H81.111L81.1664 45.716Z"
      fill="#1AB395"
    />
    <path
      d="M77.2152 77.2334C77.2152 78.5259 76.1258 79.5599 74.8333 79.5414C73.5409 79.5414 72.5069 78.452 72.5254 77.1596C72.5254 75.8671 73.5963 74.8332 74.8887 74.8516C76.1812 74.8516 77.2152 75.9225 77.2152 77.215V77.2334Z"
      fill="#1AB395"
    />
    <path
      d="M83.9359 77.3073C83.9359 78.5997 82.8465 79.6337 81.554 79.6152C80.2616 79.6152 79.2276 78.5259 79.2461 77.2334C79.2461 75.941 80.317 74.907 81.6094 74.9255C82.9019 74.9255 83.9359 75.9964 83.9359 77.2888V77.3073Z"
      fill="#3F3D56"
    />
    <path
      d="M90.638 77.3811C90.638 78.6736 89.5487 79.7076 88.2562 79.6891C86.9637 79.6891 85.9298 78.5997 85.9482 77.3073C85.9482 76.0148 87.0191 74.9809 88.3116 74.9993C89.604 74.9993 90.638 76.0702 90.638 77.3627V77.3811Z"
      fill="#E6E6E6"
    />
    <path
      d="M41.1186 51.569C41.8324 51.569 42.4111 50.9903 42.4111 50.2765C42.4111 49.5627 41.8324 48.9841 41.1186 48.9841C40.4048 48.9841 39.8262 49.5627 39.8262 50.2765C39.8262 50.9903 40.4048 51.569 41.1186 51.569Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 49.2795C62.2225 49.261 62.6287 49.6303 62.6472 50.1103C62.6656 50.5904 62.2963 50.9966 61.8163 51.0151C61.7978 51.0151 61.7609 51.0151 61.7424 51.0151H48.2271C47.747 50.9966 47.3777 50.5904 47.3962 50.1103C47.4146 49.6672 47.7839 49.298 48.2271 49.2795H61.7424ZM61.7424 49.0579H48.2271C47.6362 49.0579 47.1377 49.538 47.1377 50.1473C47.1377 50.7566 47.6178 51.2366 48.2271 51.2366H61.7424C62.3333 51.2366 62.8318 50.7566 62.8318 50.1473C62.8318 49.538 62.3517 49.0579 61.7424 49.0579Z"
      fill="#3F3D56"
    />
    <path
      d="M56.4249 51.2366H46.2515C45.6606 51.2366 45.1621 50.7566 45.1621 50.1473C45.1621 49.538 45.6422 49.0579 46.2515 49.0579H56.4249C57.0158 49.0579 57.5143 49.538 57.5143 50.1473C57.5143 50.7566 57.0342 51.2366 56.4249 51.2366Z"
      fill="#1AB395"
    />
    <path
      d="M41.1186 56.9049C41.8324 56.9049 42.4111 56.3263 42.4111 55.6125C42.4111 54.8987 41.8324 54.32 41.1186 54.32C40.4048 54.32 39.8262 54.8987 39.8262 55.6125C39.8262 56.3263 40.4048 56.9049 41.1186 56.9049Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 54.6155C62.2225 54.597 62.6287 54.9663 62.6472 55.4463C62.6656 55.9264 62.2963 56.3326 61.8163 56.351C61.7978 56.351 61.7609 56.351 61.7424 56.351H48.2271C47.747 56.3326 47.3777 55.9264 47.3962 55.4463C47.4146 55.0032 47.7839 54.6339 48.2271 54.6155H61.7424ZM61.7424 54.3939H48.2271C47.6362 54.3939 47.1377 54.8739 47.1377 55.4832C47.1377 56.0925 47.6178 56.5726 48.2271 56.5726H61.7424C62.3333 56.5726 62.8318 56.0925 62.8318 55.4832C62.8318 54.8739 62.3517 54.3939 61.7424 54.3939Z"
      fill="#3F3D56"
    />
    <path
      d="M60.2654 56.5726H46.2515C45.6606 56.5726 45.1621 56.0925 45.1621 55.4832C45.1621 54.8739 45.6422 54.3939 46.2515 54.3939H60.2654C60.8562 54.3939 61.3547 54.8739 61.3547 55.4832C61.3547 56.0925 60.8747 56.5726 60.2654 56.5726Z"
      fill="#1AB395"
    />
    <path
      d="M41.1186 62.2409C41.8324 62.2409 42.4111 61.6623 42.4111 60.9485C42.4111 60.2347 41.8324 59.656 41.1186 59.656C40.4048 59.656 39.8262 60.2347 39.8262 60.9485C39.8262 61.6623 40.4048 62.2409 41.1186 62.2409Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 59.9515C62.2225 59.933 62.6287 60.3023 62.6472 60.7823C62.6656 61.2624 62.2963 61.6686 61.8163 61.687C61.7978 61.687 61.7609 61.687 61.7424 61.687H48.2271C47.747 61.6686 47.3777 61.2624 47.3962 60.7823C47.4146 60.3392 47.7839 59.9699 48.2271 59.9515H61.7424ZM61.7424 59.7299H48.2271C47.6362 59.7299 47.1377 60.2099 47.1377 60.8192C47.1377 61.4285 47.6178 61.9086 48.2271 61.9086H61.7424C62.3333 61.9086 62.8318 61.4285 62.8318 60.8192C62.8318 60.2099 62.3517 59.7299 61.7424 59.7299Z"
      fill="#3F3D56"
    />
    <path
      d="M51.9383 61.9086H46.2515C45.6606 61.9086 45.1621 61.4285 45.1621 60.8192C45.1621 60.2099 45.6422 59.7299 46.2515 59.7299H51.9383C52.5291 59.7299 53.0276 60.2099 53.0276 60.8192C53.0276 61.4285 52.5476 61.9086 51.9383 61.9086Z"
      fill="#1AB395"
    />
    <path
      d="M61.8349 42.8726H55.8896C55.5572 42.8726 55.2803 42.5956 55.2803 42.2633V22.618C55.2803 22.2856 55.5572 22.0087 55.8896 22.0087H61.8349C62.1672 22.0087 62.4442 22.2856 62.4442 22.618V42.2633C62.4442 42.5956 62.1672 42.8726 61.8349 42.8726Z"
      fill="#1AB395"
    />
    <path
      d="M72.6545 42.8726H66.7277C66.3954 42.8726 66.1184 42.5956 66.1184 42.2633V14.8632C66.1184 14.5309 66.3954 14.2539 66.7277 14.2539H72.673C73.0053 14.2539 73.2823 14.5309 73.2823 14.8632V42.2633C73.2823 42.5956 73.0053 42.8726 72.673 42.8726H72.6545Z"
      fill="#1AB395"
    />
    <path
      d="M83.4926 42.8726H77.5473C77.2149 42.8726 76.938 42.5956 76.938 42.2633V22.618C76.938 22.2856 77.2149 22.0087 77.5473 22.0087H83.4926C83.8249 22.0087 84.1019 22.2856 84.1019 22.618V42.2633C84.1019 42.5956 83.8249 42.8726 83.4926 42.8726Z"
      fill="#1AB395"
    />
    <path
      d="M94.3124 42.8726H88.3671C88.0348 42.8726 87.7763 42.6141 87.7578 42.3002V11.7614C87.7578 11.429 88.0348 11.1705 88.3671 11.189H94.3124C94.6448 11.189 94.9032 11.4475 94.9217 11.7614V42.2818C94.9217 42.6141 94.6448 42.8726 94.3124 42.8541V42.8726Z"
      fill="#1AB395"
    />
    <path
      d="M58.8622 19.553C59.5352 19.553 60.0808 19.0074 60.0808 18.3344C60.0808 17.6614 59.5352 17.1158 58.8622 17.1158C58.1891 17.1158 57.6436 17.6614 57.6436 18.3344C57.6436 19.0074 58.1891 19.553 58.8622 19.553Z"
      fill="#3F3D56"
    />
    <path
      d="M69.7003 11.5767C70.3733 11.5767 70.9189 11.0311 70.9189 10.3581C70.9189 9.68511 70.3733 9.13953 69.7003 9.13953C69.0273 9.13953 68.4817 9.68511 68.4817 10.3581C68.4817 11.0311 69.0273 11.5767 69.7003 11.5767Z"
      fill="#3F3D56"
    />
    <path
      d="M80.5201 19.553C81.1931 19.553 81.7387 19.0074 81.7387 18.3344C81.7387 17.6614 81.1931 17.1158 80.5201 17.1158C79.8471 17.1158 79.3015 17.6614 79.3015 18.3344C79.3015 19.0074 79.8471 19.553 80.5201 19.553Z"
      fill="#3F3D56"
    />
    <path
      d="M91.3397 7.69935C92.0127 7.69935 92.5583 7.15376 92.5583 6.48075C92.5583 5.80773 92.0127 5.26215 91.3397 5.26215C90.6667 5.26215 90.1211 5.80773 90.1211 6.48075C90.1211 7.15376 90.6667 7.69935 91.3397 7.69935Z"
      fill="#3F3D56"
    />
    <path
      d="M80.5385 18.6114L69.6819 10.4874L58.9729 18.5006L58.7329 18.1682L69.6819 9.97038L80.4831 18.0575L91.2105 6.31458H91.2474L91.4505 6.66538L80.52 18.6114H80.5385Z"
      fill="#3F3D56"
    />
    <path
      d="M22.9871 45.6975C35.5297 45.6975 45.6974 35.5298 45.6974 22.9872C45.6974 10.4447 35.5297 0.276947 22.9871 0.276947C10.4446 0.276947 0.276855 10.4447 0.276855 22.9872C0.276855 35.5298 10.4446 45.6975 22.9871 45.6975Z"
      fill="white"
    />
    <path
      d="M12.463 15.6018C13.3123 12.5368 15.5464 10.0442 18.4821 8.86254C21.8979 8.124 25.4614 9.15796 27.9724 11.5952C32.0344 15.6387 33.0315 29.5418 30.2435 33.9362C29.6896 33.8992 27.7693 33.8808 27.2154 33.8623L26.4215 31.222V33.8439C25.5352 33.8254 24.6305 33.8069 23.7258 33.7885C17.3928 33.7146 12.2599 28.6371 12.0937 22.3041C11.946 20.07 12.0568 17.8174 12.463 15.6018Z"
      fill="#2F2E41"
    />
    <path
      d="M22.6549 25.5167C26.2239 25.5167 29.1172 22.6235 29.1172 19.0545C29.1172 15.4855 26.2239 12.5922 22.6549 12.5922C19.0859 12.5922 16.1926 15.4855 16.1926 19.0545C16.1926 22.6235 19.0859 25.5167 22.6549 25.5167Z"
      fill="#9E616A"
    />
    <path
      d="M17.0603 13.3677L24.5196 9.63803C27.6215 11.0413 29.7264 13.9955 30.0033 17.3928L30.188 19.6084L27.0676 18.8145L26.7353 15.9711L26.1998 18.5929L24.7596 18.2236V13.8108L23.3379 18.2236L18.1866 17.042L17.0603 13.3677Z"
      fill="#2F2E41"
    />
    <path
      d="M39.8261 38.0167C31.6467 47.3223 17.5035 48.3563 8.0686 40.3061L10.0073 34.8778C10.912 32.9022 12.8138 31.5913 14.974 31.4621L16.2111 31.3882L19.1653 28.8402C19.1653 28.8402 27.1231 28.5633 27.0123 28.4156L31.8313 31.3143L33.7331 31.4067C35.8933 31.5359 37.7951 32.8468 38.6998 34.8224L39.8261 38.0167Z"
      fill="#1AB395"
    />
    <path
      d="M22.9872 45.9745C10.2842 45.9745 0 35.6902 0 22.9872C0 10.2842 10.2842 0 22.9872 0C35.6902 0 45.9745 10.2842 45.9745 22.9872C45.9745 35.6902 35.6902 45.9745 22.9872 45.9745ZM22.9872 0.572373C10.5981 0.572373 0.572373 10.6166 0.572373 22.9872C0.572373 35.3579 10.6166 45.4021 22.9872 45.4021C35.3579 45.4021 45.4021 35.3579 45.4021 22.9872C45.4021 10.6166 35.3763 0.572373 22.9872 0.572373Z"
      fill="#3F3D56"
    />
    <path
      d="M32.4036 81H3.48952C1.88318 81 0.572266 79.6891 0.572266 78.0827V51.9198C0.572266 50.3134 1.88318 49.0025 3.48952 49.0025H32.4036C34.0099 49.0025 35.3208 50.3134 35.3208 51.9198V78.0827C35.3208 79.6891 34.0099 81 32.4036 81Z"
      fill="#E6E6E6"
    />
    <path
      d="M20.9194 78.8028H5.5207C4.00669 78.8028 2.78809 77.5842 2.78809 76.0702V54.0246C2.78809 52.5106 4.00669 51.292 5.5207 51.292H30.3728C31.8868 51.292 33.1054 52.5106 33.1054 54.0246V66.6168C33.1054 73.3376 27.6401 78.8028 20.9194 78.8028Z"
      fill="white"
    />
    <path
      d="M27.8987 58.7329H8.01334C7.66253 58.7144 7.38557 58.4005 7.4225 58.0312C7.44096 57.7174 7.69945 57.4589 8.01334 57.4404H27.8987C28.2495 57.4589 28.5264 57.7727 28.4895 58.142C28.4711 58.4559 28.2126 58.7144 27.8987 58.7329Z"
      fill="#1AB395"
    />
    <path
      d="M11.1152 61.2993H8.01328C7.66247 61.3178 7.34859 61.0593 7.31166 60.7085C7.27473 60.3576 7.55169 60.0438 7.9025 60.0068C7.93942 60.0068 7.97635 60.0068 7.99481 60.0068H11.0967C11.4475 60.0253 11.7245 60.3392 11.6875 60.7085C11.6691 61.0223 11.4106 61.2808 11.0967 61.2993H11.1152Z"
      fill="#1AB395"
    />
    <path
      d="M16.6173 63.8657H7.99478C7.64397 63.8473 7.36702 63.5334 7.40394 63.1641C7.42241 62.8502 7.6809 62.5918 7.99478 62.5733H16.6173C16.9681 62.5548 17.282 62.8133 17.3189 63.1641C17.3558 63.5149 17.0789 63.8288 16.7281 63.8657C16.6912 63.8657 16.6542 63.8657 16.6358 63.8657H16.6173Z"
      fill="#1AB395"
    />
    <path
      d="M41.3585 12.4076C44.7338 12.4076 47.47 9.67137 47.47 6.2961C47.47 2.92083 44.7338 0.184631 41.3585 0.184631C37.9833 0.184631 35.2471 2.92083 35.2471 6.2961C35.2471 9.67137 37.9833 12.4076 41.3585 12.4076Z"
      fill="#3F3D56"
    />
    <path
      d="M40.5462 10.0442C40.3431 10.0442 40.1584 9.97038 39.9923 9.8596L37.8874 8.25326C37.4628 7.97631 37.3335 7.38547 37.6105 6.96081C37.8874 6.53614 38.4783 6.4069 38.9029 6.68385C38.9398 6.70231 38.9768 6.73924 39.0137 6.77617L40.38 7.8286L43.5927 3.63735C43.9066 3.23115 44.4974 3.1573 44.9036 3.47118C45.3098 3.78506 45.3837 4.3759 45.0698 4.7821L41.2847 9.71189C41.1001 9.93345 40.8231 10.0627 40.5277 10.0627L40.5462 10.0442Z"
      fill="white"
    />
    <path
      d="M27.8987 66.3399H8.01334C7.66253 66.3214 7.38557 66.0075 7.4225 65.6383C7.44096 65.3244 7.69945 65.0659 8.01334 65.0474H27.8987C28.2495 65.0659 28.5264 65.3798 28.4895 65.749C28.4711 66.0629 28.2126 66.3214 27.8987 66.3399Z"
      fill="#CCCCCC"
    />
    <path
      d="M27.8987 68.814H8.01334C7.66253 68.7955 7.38557 68.4817 7.4225 68.1124C7.44096 67.7985 7.69945 67.54 8.01334 67.5215H27.8987C28.2495 67.54 28.5264 67.8539 28.4895 68.2232C28.4711 68.537 28.2126 68.7955 27.8987 68.814Z"
      fill="#CCCCCC"
    />
    <path
      d="M27.8987 71.3066H8.01334C7.66253 71.2881 7.38557 70.9742 7.4225 70.605C7.44096 70.2911 7.69945 70.0326 8.01334 70.0141H27.8987C28.2495 70.0326 28.5264 70.3465 28.4895 70.7158C28.4711 71.0296 28.2126 71.2881 27.8987 71.3066Z"
      fill="#CCCCCC"
    />
    <path
      d="M27.8987 73.7807H8.01334C7.66253 73.7622 7.38557 73.4484 7.4225 73.0791C7.44096 72.7652 7.69945 72.5067 8.01334 72.4883H27.8987C28.2495 72.5067 28.5264 72.8206 28.4895 73.1899C28.4711 73.5038 28.2126 73.7622 27.8987 73.7807Z"
      fill="#CCCCCC"
    />
    <path
      d="M41.1186 67.5769C41.8324 67.5769 42.4111 66.9983 42.4111 66.2845C42.4111 65.5707 41.8324 64.992 41.1186 64.992C40.4048 64.992 39.8262 65.5707 39.8262 66.2845C39.8262 66.9983 40.4048 67.5769 41.1186 67.5769Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 65.2874C62.2225 65.269 62.6287 65.6383 62.6472 66.1183C62.6656 66.5984 62.2963 67.0046 61.8163 67.023C61.7978 67.023 61.7609 67.023 61.7424 67.023H48.2271C47.747 67.0046 47.3777 66.5984 47.3962 66.1183C47.4146 65.6752 47.7839 65.3059 48.2271 65.2874H61.7424ZM61.7424 65.0659H48.2271C47.6362 65.0659 47.1377 65.5459 47.1377 66.1552C47.1377 66.7645 47.6178 67.2446 48.2271 67.2446H61.7424C62.3333 67.2446 62.8318 66.7645 62.8318 66.1552C62.8318 65.5459 62.3517 65.0659 61.7424 65.0659Z"
      fill="#3F3D56"
    />
    <path
      d="M56.4249 67.2261H46.2515C45.6606 67.2261 45.1621 66.7461 45.1621 66.1368C45.1621 65.5275 45.6422 65.0474 46.2515 65.0474H56.4249C57.0158 65.0474 57.5143 65.5275 57.5143 66.1368C57.5143 66.7461 57.0342 67.2261 56.4249 67.2261Z"
      fill="#1AB395"
    />
    <path
      d="M41.1186 72.9129C41.8324 72.9129 42.4111 72.3343 42.4111 71.6205C42.4111 70.9067 41.8324 70.328 41.1186 70.328C40.4048 70.328 39.8262 70.9067 39.8262 71.6205C39.8262 72.3343 40.4048 72.9129 41.1186 72.9129Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 70.605C62.2225 70.5865 62.6287 70.9558 62.6472 71.4359C62.6656 71.9159 62.2963 72.3221 61.8163 72.3406C61.7978 72.3406 61.7609 72.3406 61.7424 72.3406H48.2271C47.747 72.3221 47.3777 71.9159 47.3962 71.4359C47.4146 70.9927 47.7839 70.6235 48.2271 70.605H61.7424ZM61.7424 70.3834H48.2271C47.6362 70.3834 47.1377 70.8635 47.1377 71.4728C47.1377 72.0821 47.6178 72.5621 48.2271 72.5621H61.7424C62.3333 72.5621 62.8318 72.0821 62.8318 71.4728C62.8318 70.8635 62.3517 70.3834 61.7424 70.3834Z"
      fill="#3F3D56"
    />
    <path
      d="M60.2654 72.5621H46.2515C45.6606 72.5621 45.1621 72.0821 45.1621 71.4728C45.1621 70.8635 45.6422 70.3834 46.2515 70.3834H60.2654C60.8562 70.3834 61.3547 70.8635 61.3547 71.4728C61.3547 72.0821 60.8747 72.5621 60.2654 72.5621Z"
      fill="#1AB395"
    />
    <path
      d="M41.1186 78.2304C41.8324 78.2304 42.4111 77.6518 42.4111 76.938C42.4111 76.2242 41.8324 75.6455 41.1186 75.6455C40.4048 75.6455 39.8262 76.2242 39.8262 76.938C39.8262 77.6518 40.4048 78.2304 41.1186 78.2304Z"
      fill="#3F3D56"
    />
    <path
      d="M61.7424 75.941C62.2225 75.9225 62.6287 76.2918 62.6472 76.7718C62.6656 77.2519 62.2963 77.6581 61.8163 77.6765C61.7978 77.6765 61.7609 77.6765 61.7424 77.6765H48.2271C47.747 77.6581 47.3777 77.2519 47.3962 76.7718C47.4146 76.3287 47.7839 75.9594 48.2271 75.941H61.7424ZM61.7424 75.7194H48.2271C47.6362 75.7194 47.1377 76.1994 47.1377 76.8087C47.1377 77.418 47.6178 77.8981 48.2271 77.8981H61.7424C62.3333 77.8981 62.8318 77.418 62.8318 76.8087C62.8318 76.1994 62.3517 75.7194 61.7424 75.7194Z"
      fill="#3F3D56"
    />
    <path
      d="M51.9383 77.8981H46.2515C45.6606 77.8981 45.1621 77.418 45.1621 76.8087C45.1621 76.1994 45.6422 75.7194 46.2515 75.7194H51.9383C52.5291 75.7194 53.0276 76.1994 53.0276 76.8087C53.0276 77.418 52.5476 77.8981 51.9383 77.8981Z"
      fill="#1AB395"
    />
  </SVG>
)

export default withIconStyles(IconDataStudioSubscription)
