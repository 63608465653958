const TOGGLE = 'toggle'
const DROP_DOWN = 'dropdown'
const DRAG_DROP = 'dragdrop'
const TEXT_INPUT = 'input'

const DASHED_LINE = 'dashedline'
const HIGHLIGHT = 'highlight'
const EMPTY = 'empty'
const PRE_FILLED = 'preFilled'

export const displayStyleOptions = [
  { value: TOGGLE, label: 'Toggle' },
  { value: DROP_DOWN, label: 'Text Drop Down' },
  { value: DRAG_DROP, label: 'Drag Drop' },
  { value: TEXT_INPUT, label: 'Text Input' },
]

export const displayStyles = {
  TOGGLE,
  DROP_DOWN,
  DRAG_DROP,
  TEXT_INPUT,
}

export const subOptions = {
  DASHED_LINE,
  HIGHLIGHT,
  EMPTY,
  PRE_FILLED,
}
