/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSparkCS = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="108.345"
    height="33.953"
    viewBox="0 0 108.345 33.953"
    {...props}
  >
    <g transform="translate(-123.876 -29.622)">
      <path
        d="M222,84.373a8.04,8.04,0,0,1-1.423,5,4.942,4.942,0,0,1-7.375.405v6.4h-3.032V77.485h2.8l.125,1.373a4.454,4.454,0,0,1,3.631-1.622,4.6,4.6,0,0,1,3.875,1.841,8.254,8.254,0,0,1,1.4,5.11Zm-3.02-.262a5.631,5.631,0,0,0-.8-3.207,2.617,2.617,0,0,0-2.3-1.186,2.79,2.79,0,0,0-2.67,1.535v5.99a2.817,2.817,0,0,0,2.7,1.572,2.614,2.614,0,0,0,2.265-1.167A6.2,6.2,0,0,0,218.979,84.111Z"
        transform="translate(-59.09 -32.604)"
        fill="#637885"
      />
      <path
        d="M262.963,90.987a4.673,4.673,0,0,1-.349-1.261,5.1,5.1,0,0,1-6.863.349,3.711,3.711,0,0,1-1.285-2.87,3.834,3.834,0,0,1,1.6-3.313,7.771,7.771,0,0,1,4.586-1.154h1.859v-.886a2.364,2.364,0,0,0-.587-1.678,2.325,2.325,0,0,0-1.784-.63,2.674,2.674,0,0,0-1.7.518,1.6,1.6,0,0,0-.661,1.316h-3.032a3.365,3.365,0,0,1,.736-2.078,4.9,4.9,0,0,1,2-1.516,7.035,7.035,0,0,1,2.826-.549,5.667,5.667,0,0,1,3.781,1.192,4.251,4.251,0,0,1,1.448,3.351v6.09a6.9,6.9,0,0,0,.512,2.907v.212ZM259.631,88.8a3.448,3.448,0,0,0,1.691-.437,2.918,2.918,0,0,0,1.192-1.173V84.648h-1.635a4.47,4.47,0,0,0-2.533.586,1.9,1.9,0,0,0-.849,1.66,1.772,1.772,0,0,0,.58,1.391A2.246,2.246,0,0,0,259.631,88.8Z"
        transform="translate(-89.422 -32.604)"
        fill="#637885"
      />
      <path
        d="M306.746,80.255a7.508,7.508,0,0,0-1.235-.1,2.781,2.781,0,0,0-2.808,1.6v9.234h-3.032v-13.5h2.895l.075,1.51a3.417,3.417,0,0,1,3.045-1.76,2.842,2.842,0,0,1,1.073.175Z"
        transform="translate(-120.377 -32.604)"
        fill="#637885"
      />
      <path
        d="M333.8,73.432l-1.348,1.385v4.405h-3.033V60.055h3.033V71.111l.948-1.185,3.731-4.205h3.644l-5.016,5.628,5.553,7.874h-3.506Z"
        transform="translate(-140.748 -20.84)"
        fill="#637885"
      />
      <g transform="translate(134.864 39.868)">
        <path
          d="M169.151,75.866a2.193,2.193,0,0,0-.842-1.847,10.141,10.141,0,0,0-3.039-1.31,14.657,14.657,0,0,1-3.494-1.472,4.662,4.662,0,0,1-2.483-4.068,4.427,4.427,0,0,1,1.791-3.619,7.245,7.245,0,0,1,4.648-1.423,7.856,7.856,0,0,1,3.382.7,5.554,5.554,0,0,1,2.334,1.99,5.106,5.106,0,0,1,.848,2.864h-3.145a2.847,2.847,0,0,0-.892-2.227,3.681,3.681,0,0,0-2.552-.805,3.844,3.844,0,0,0-2.4.662,2.2,2.2,0,0,0-.855,1.847,1.991,1.991,0,0,0,.923,1.666,10.629,10.629,0,0,0,3.045,1.3,14.031,14.031,0,0,1,3.407,1.435,5.427,5.427,0,0,1,1.884,1.847,4.811,4.811,0,0,1,.6,2.44,4.324,4.324,0,0,1-1.741,3.612,7.551,7.551,0,0,1-4.723,1.341,8.87,8.87,0,0,1-3.625-.73,6.084,6.084,0,0,1-2.57-2.015,5.024,5.024,0,0,1-.917-3h3.157a2.939,2.939,0,0,0,1.023,2.4,4.482,4.482,0,0,0,2.933.848,3.894,3.894,0,0,0,2.477-.667A2.154,2.154,0,0,0,169.151,75.866Z"
          transform="translate(-158.731 -62.126)"
          fill="#637885"
        />
      </g>
      <path
        d="M142.538,52.187a.237.237,0,0,1-.143-.048l-2.3-1.753a.237.237,0,0,1,.287-.376l2.3,1.753a.236.236,0,0,1-.144.424Z"
        transform="translate(-11.043 -13.928)"
        fill="#637885"
      />
      <path
        d="M153.4,60.473a.237.237,0,0,1-.143-.049l-2.848-2.173a.236.236,0,1,1,.287-.376l2.848,2.173a.236.236,0,0,1-.144.424Z"
        transform="translate(-18.105 -19.314)"
        fill="#637885"
      />
      <path
        d="M187.282,33.658a.268.268,0,0,1-.044,0,.237.237,0,0,1-.188-.277l.682-3.564a.236.236,0,0,1,.464.089l-.683,3.564A.237.237,0,0,1,187.282,33.658Z"
        transform="translate(-43.256)"
        fill="#637885"
      />
      <path
        d="M184.243,49.078a.236.236,0,0,1-.232-.281l.723-3.774a.236.236,0,0,1,.464.089l-.723,3.774A.237.237,0,0,1,184.243,49.078Z"
        transform="translate(-41.175 -10.415)"
        fill="#637885"
      />
      <path
        d="M129.2,74.648h-.012l-5.084-.236a.236.236,0,0,1-.225-.247.232.232,0,0,1,.247-.225l5.084.236a.236.236,0,0,1-.011.473Z"
        transform="translate(0 -30.347)"
        fill="#637885"
      />
      <path
        d="M146.152,75.434h-.011l-2.012-.093a.236.236,0,0,1-.225-.247.234.234,0,0,1,.247-.225l2.012.093a.236.236,0,0,1-.011.473Z"
        transform="translate(-13.714 -30.983)"
        fill="#637885"
      />
      <path
        d="M138.29,94.426a.236.236,0,0,1-.1-.452l1.3-.578a.236.236,0,0,1,.193.432l-1.3.579A.243.243,0,0,1,138.29,94.426Z"
        transform="translate(-9.708 -43.655)"
        fill="#637885"
      />
      <path
        d="M145.883,88.83a.237.237,0,0,1-.1-.452l3.571-1.594a.236.236,0,0,1,.193.432l-3.57,1.594A.238.238,0,0,1,145.883,88.83Z"
        transform="translate(-14.908 -39.128)"
        fill="#637885"
      />
      <path
        d="M204.451,52.964a.236.236,0,0,1-.16-.41l2.008-1.847a.236.236,0,0,1,.32.348L204.611,52.9A.236.236,0,0,1,204.451,52.964Z"
        transform="translate(-55.012 -14.396)"
        fill="#637885"
      />
      <path
        d="M198.586,60.428a.236.236,0,0,1-.16-.41l.972-.894a.237.237,0,0,1,.32.348l-.972.894A.235.235,0,0,1,198.586,60.428Z"
        transform="translate(-50.996 -20.159)"
        fill="#637885"
      />
      <path
        d="M165.869,35.584a.236.236,0,0,1-.228-.172l-.8-2.849a.236.236,0,1,1,.455-.128l.8,2.849a.236.236,0,0,1-.227.3Z"
        transform="translate(-28.043 -1.808)"
        fill="#637885"
      />
      <path
        d="M169.455,48.313a.236.236,0,0,1-.228-.172l-.848-3.01a.236.236,0,0,1,.455-.128l.848,3.01a.236.236,0,0,1-.164.292A.222.222,0,0,1,169.455,48.313Z"
        transform="translate(-30.468 -10.414)"
        fill="#637885"
      />
      <path
        d="M146.4,37.115a.237.237,0,0,1-.233-.276l.2-1.146-.833-.812a.237.237,0,0,1,.131-.4l1.151-.167.514-1.043a.237.237,0,0,1,.212-.132h0a.237.237,0,0,1,.212.132l.514,1.043,1.151.167a.236.236,0,0,1,.131.4l-.833.812.2,1.146a.236.236,0,0,1-.343.249l-1.03-.541-1.03.541A.239.239,0,0,1,146.4,37.115Zm-.2-2.239.579.564a.236.236,0,0,1,.068.209l-.137.8.716-.376a.237.237,0,0,1,.22,0l.715.376-.136-.8a.236.236,0,0,1,.068-.209l.579-.564-.8-.116a.237.237,0,0,1-.178-.129l-.357-.725-.358.725a.237.237,0,0,1-.178.129Z"
        transform="translate(-14.78 -2.406)"
        fill="#637885"
      />
      <path
        d="M194.77,42.048a.232.232,0,0,1-.09-.018.235.235,0,0,1-.145-.2l-.077-1.161-1-.595a.236.236,0,0,1,.033-.422l1.08-.432.257-1.135a.236.236,0,0,1,.412-.1l.744.894,1.159-.106a.236.236,0,0,1,.222.362l-.62.984.459,1.069a.237.237,0,0,1-.276.322l-1.127-.286-.874.767A.235.235,0,0,1,194.77,42.048Zm-.659-2.138.695.413a.235.235,0,0,1,.115.188l.053.807.608-.533a.235.235,0,0,1,.214-.051l.783.2-.319-.743a.238.238,0,0,1,.017-.219l.431-.684-.805.074a.238.238,0,0,1-.2-.084l-.517-.621-.178.788a.237.237,0,0,1-.143.167Zm.663-.52h0Z"
        transform="translate(-47.567 -5.668)"
        fill="#637885"
      />
      <path
        d="M144.758,106.621a.237.237,0,0,1-.158-.061l-.864-.778-1.131.271a.237.237,0,0,1-.271-.326l.473-1.063-.607-.992a.237.237,0,0,1,.226-.359l1.157.121.755-.884a.236.236,0,0,1,.411.1l.242,1.137,1.075.445a.236.236,0,0,1,.028.423l-1.007.582-.091,1.16a.237.237,0,0,1-.236.218Zm-.957-1.334a.237.237,0,0,1,.158.061l.6.541.063-.806a.237.237,0,0,1,.118-.186l.7-.4-.747-.31a.235.235,0,0,1-.141-.169l-.169-.791-.525.615a.236.236,0,0,1-.2.081l-.8-.084.422.689a.237.237,0,0,1,.014.22l-.328.739.786-.189A.234.234,0,0,1,143.8,105.287Z"
        transform="translate(-12.522 -49.882)"
        fill="#637885"
      />
      <g transform="translate(203.166 40.25)">
        <path
          d="M389.421,75.482a6.376,6.376,0,0,1-2.085,4.406,7.109,7.109,0,0,1-4.836,1.582,6.884,6.884,0,0,1-3.715-1,6.563,6.563,0,0,1-2.479-2.843,10.252,10.252,0,0,1-.909-4.279V71.7a10.335,10.335,0,0,1,.885-4.4,6.67,6.67,0,0,1,2.54-2.934,7.086,7.086,0,0,1,3.824-1.03,6.821,6.821,0,0,1,4.7,1.582,6.608,6.608,0,0,1,2.073,4.479h-3.054a4.363,4.363,0,0,0-1.109-2.745,3.668,3.668,0,0,0-2.612-.842,3.581,3.581,0,0,0-3.073,1.462,7.306,7.306,0,0,0-1.1,4.289v1.565a7.752,7.752,0,0,0,1.024,4.368,3.4,3.4,0,0,0,3,1.5,3.965,3.965,0,0,0,2.715-.812,4.237,4.237,0,0,0,1.152-2.7Z"
          transform="translate(-375.397 -63.336)"
          fill="#637885"
        />
        <path
          d="M435.85,76.681a2.131,2.131,0,0,0-.818-1.794,9.845,9.845,0,0,0-2.951-1.273,14.24,14.24,0,0,1-3.394-1.43,4.529,4.529,0,0,1-2.412-3.952,4.3,4.3,0,0,1,1.739-3.515,7.04,7.04,0,0,1,4.515-1.382,7.63,7.63,0,0,1,3.285.679,5.393,5.393,0,0,1,2.267,1.933,4.958,4.958,0,0,1,.824,2.782H435.85a2.766,2.766,0,0,0-.867-2.164,3.579,3.579,0,0,0-2.478-.782,3.738,3.738,0,0,0-2.334.642,2.141,2.141,0,0,0-.83,1.794,1.933,1.933,0,0,0,.9,1.618A10.311,10.311,0,0,0,433.2,71.1a13.624,13.624,0,0,1,3.309,1.394,5.268,5.268,0,0,1,1.83,1.794,4.676,4.676,0,0,1,.582,2.37,4.2,4.2,0,0,1-1.691,3.509,7.336,7.336,0,0,1-4.588,1.3,8.624,8.624,0,0,1-3.522-.709,5.917,5.917,0,0,1-2.5-1.958,4.878,4.878,0,0,1-.891-2.909H428.8a2.853,2.853,0,0,0,.994,2.327,4.35,4.35,0,0,0,2.849.824,3.782,3.782,0,0,0,2.406-.648A2.092,2.092,0,0,0,435.85,76.681Z"
          transform="translate(-409.862 -63.336)"
          fill="#637885"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconSparkCS)
