import { LANGUAGES_OPTIONS } from '@edulastic/constants/const/languages'
import { stackedTranslationMap } from '../../assessment/components/Common/StackedTranslator/constants'

export const thumbnailData = {
  fileNames: [
    'Learning-23.jpg-3.jpg',
    'Learning-25.jpg-3.jpg',
    'Learning-26.jpg-3.jpg',
    'Learning-27.jpg-3.jpg',
    'abstract-1231855_960_720.jpg-3.jpg',
    'abstract-1235607_960_720.jpg-3.jpg',
    'abstract-1278077_960_720.jpg-3.jpg',
    'abstract-21851_960_720.jpg-3.jpg',
    'animal-21649_960_720.jpg-3.jpg',
    'background-1113715_960_720.jpg-3.jpg',
    'background-1130516_960_720.jpg-3.jpg',
    'background-1210554_960_720.jpg-3.jpg',
    'background-17309_960_720.jpg-3.jpg',
    'background-texture-1382002_960_720.jpg-3.jpg',
    'ball-443852_960_720.jpg-3.jpg',
    'banner-1036483_960.jpg-3.jpg',
    'banner-1036483_960_720.jpg-3.jpg',
    'banner-1183443.jpg-3.jpg',
    'banner-1183445_960_720.jpg-3.jpg',
    'banner-1246873_960_720.jpg-3.jpg',
    'biological-2.jpg-3.jpg',
    'biological.jpg-3.jpg',
    'blue-1321195_960_720.jpg-3.jpg',
    'bokeh-1129079_960_720.jpg-3.jpg',
    'bokeh-1332105_960_720.jpg-3.jpg',
    'bokeh-1332107_960_720.jpg-3.jpg',
    'book.jpeg-3.jpg',
    'brush-1313564_960_720.jpg-3.jpg',
    'brush-1317908_960_720.jpg-3.jpg',
    'brush-1356383_960_720.jpg-3.jpg',
    'clouds-428168_960_720.jpg-3.jpg',
    'connect.jpg-3.jpg',
    'connection.png-3.jpg',
    'cube-358630_960_720.jpg-3.jpg',
    'cubes-1213779_960_720.jpg-3.jpg',
    'data-3.jpg-3.jpg',
    'data-7.jpg-3.jpg',
    'earth-6.png-3.jpg',
    'earth.jpg-3.jpg',
    'energy.jpg-3.jpg',
    'expression-2.jpg-3.jpg',
    'expression-2.png-3.jpg',
    'features.jpg-3.jpg',
    'flash-1043778_960_720.jpg-3.jpg',
    'flash-113275_960_720.jpg-3.jpg',
    'forms.jpg-3.jpg',
    'fractal-1078325_960_720.jpg-3.jpg',
    'fractal-1118515_960_720.jpg-3.jpg',
    'fractal-1232494_960_720.jpg-3.jpg',
    'fractal-1280081_960_720.jpg-3.jpg',
    'fractal-1280107_960_720.jpg-3.jpg',
    'fractal-169358_960_720.jpg-3.jpg',
    'frequencies.jpg-3.jpg',
    'frequently-1.jpg-3.jpg',
    'function-51.png-3.jpg',
    'function.png-3.jpg',
    'generate.png-3.jpg',
    'geometric-2.jpg-3.jpg',
    'idea-3.jpg-3.jpg',
    'idea-4.jpg-3.jpg',
    'idea-5.jpg-3.jpg',
    'information-3.png-3.jpg',
    'information-5.png-3.jpg',
    'kinetic.jpg-3.jpg',
    'laboratory.png-3.jpg',
    'landscape-914149_960_720.jpg-3.jpg',
    'learning-14.jpg-3.jpg',
    'learning-15.jpg-3.jpg',
    'learning-5.jpg-3.jpg',
    'learning-6.jpeg-3.jpg',
    'learning-6.jpg-3.jpg',
    'learning-7.jpg-3.jpg',
    'leaves-1031355_960_720.jpg-3.jpg',
    'light.jpg-3.jpg',
    'lines-593191_960_720.jpg-3.jpg',
    'low-poly-1239778_960_720.jpg-3.jpg',
    'main-road-1373497_960_720.jpg-3.jpg',
    'matchstick-20237_960_720.jpg-3.jpg',
    'matrix.png-3.jpg',
    'matter-2.jpg-3.jpg',
    'meanings.png-3.jpg',
    'moon-4.png-3.jpg',
    'moon-769918_960_720.jpg-3.jpg',
    'motion.jpg-3.jpg',
    'natural-3.png-3.jpg',
    'nebulae-1199180_960_720.jpg-3.jpg',
    'night-675377_960_720.jpg-3.jpg',
    'numbers-3.jpg-3.jpg',
    'objects.png-3.jpg',
    'organic.png-3.jpg',
    'particles-1356381_960_720.jpg-3.jpg',
    'pattern-1341253_960_720.jpg-3.jpg',
    'pattern.png-3.jpg',
    'person-695656_960_720.jpg-3.jpg',
    'perspective.jpg-3.jpg',
    'pixels-1275701_960_720.jpg-3.jpg',
    'plane-6.png-3.jpg',
    'plot-12.png-3.jpg',
    'plot-4.png-3.jpg',
    'plot.png-3.jpg',
    'polygons-1.png-3.jpg',
    'print-3.jpeg-3.jpg',
    'print-4.jpg-3.jpg',
    'question.jpg-3.jpg',
    'reaction.png-3.jpg',
    'read-2.png-3.jpg',
    'read.png-3.jpg',
    'reflection.jpg-3.jpg',
    'reflexive.jpg-3.jpg',
    'relative.png-3.jpg',
    'rotation.jpg-3.jpg',
    'satellite.png-3.jpg',
    'science-1.png-3.jpg',
    'science.png-3.jpg',
    'sense.png-3.jpg',
    'solar-1.jpg-3.jpg',
    'space-5.png-3.jpg',
    'speed-5.png-3.jpg',
    'spellings-1.jpg-3.jpg',
    'star-1206587_960_720.jpg-3.jpg',
    'star-2.png-3.jpg',
    'storage.jpg-3.jpg',
    'structure-8.jpg-3.jpg',
    'sun-203792_960_720.jpg-3.jpg',
    'sunrise-1014710_960_720.jpg-3.jpg',
    'system.jpg-3.jpg',
    'technical.png-3.jpg',
    'technology.jpg-3.jpg',
    'text-8.jpg-3.jpg',
    'texture-1289287_960_720.jpg-3.jpg',
    'theater-1308794_960_720.jpg-3.jpg',
    'theater-1328407_960_720.jpg-3.jpg',
    'thermal-2.png-3.jpg',
    'triangle-10.jpg-3.jpg',
    'trignometric.png-3.jpg',
    'types-4.jpg-3.jpg',
    'universe.png-3.jpg',
    'wall-175686_960_720.jpg-3.jpg',
    'waves-1.jpg-3.jpg',
    'weather-3.jpg-3.jpg',
    'write.jpg-3.jpg',
  ],
}

export const canvasSyncFireBaseStatus = {
  INPROGRESS: 'active',
  COMPLETED: 'completed',
  FAILED: 'failed',
}

export const canvasSyncStatus = {
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

const yesNoOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]
const yesNoCapitilizeOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
]

export const fieldsMapping = {
  additional: [
    {
      label: 'SIS ID',
      fieldName: 'sisId',
      placeholder: 'Enter SIS ID',
    },
    {
      label: 'Student Number',
      fieldName: 'studentNumber',
      placeholder: 'Enter Student Number',
    },
    {
      label: 'Free Reduced Lunch',
      fieldName: 'frlStatus',
      options: yesNoCapitilizeOptions,
    },
    {
      label: 'Individual Education Plan',
      fieldName: 'iepStatus',
      options: yesNoCapitilizeOptions,
    },
    {
      label: 'English Language Learner',
      fieldName: 'ellStatus',
      options: yesNoCapitilizeOptions,
    },
    {
      label: 'Special ED',
      fieldName: 'sedStatus',
      options: yesNoCapitilizeOptions,
    },
    {
      label: 'Hispanic Ethnicity',
      fieldName: 'hispanicEthnicity',
      options: yesNoCapitilizeOptions,
    },
    {
      label: 'Race',
      fieldName: 'race',
      placeholder: 'Race',
    },
    {
      label: 'DOB',
      fieldName: 'dob',
      isDate: true,
      format: 'DD MMM, YYYY',
    },
    {
      label: 'Gender',
      fieldName: 'gender',
      options: [
        {
          label: 'Male',
          value: 'male',
        },
        {
          label: 'Female',
          value: 'female',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    },
    {
      label: 'Parents/Guardians',
      fieldName: 'contactEmails',
      placeholder: 'Enter email comma separated...',
    },
  ],
  accommodations: [
    {
      label: 'Text to Speech',
      fieldName: 'tts',
      options: yesNoOptions,
    },
    {
      label: 'Speech to Text',
      fieldName: 'stt',
      options: yesNoOptions,
    },
    {
      label: 'Immersive Reader',
      fieldName: 'ir',
      options: yesNoOptions,
    },
    {
      label: 'Preferred Language',
      fieldName: 'preferredLanguage',
      options: LANGUAGES_OPTIONS,
    },
    {
      label: 'Stacked Translation (BETA)',
      fieldName: 'stackedTranslation',
      options: [
        {
          label: 'Stacked',
          value: stackedTranslationMap.ES_EN,
        },
        {
          label: 'No',
          value: stackedTranslationMap.DISABLED,
        },
      ],
    },
    {
      label: 'Extra time on the test',
      fieldName: 'extraTimeOnTest',
      options: [
        {
          label: 'Not Applicable',
          value: 1,
        },
        {
          label: '1.2x',
          value: 1.2,
        },
        {
          label: '1.5x',
          value: 1.5,
        },
        {
          label: '2x',
          value: 2,
        },
        {
          label: 'Unlimited',
          value: -1,
        },
      ],
    },
  ],
}
