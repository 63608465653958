/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAdminDashboardAddOn = (props) => (
  <SVG
    width="121"
    height="81"
    viewBox="0 0 121 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96.3191 35.845L96.6781 32.968C96.7378 32.4588 96.8977 31.9665 97.1485 31.5195C97.3993 31.0724 97.736 30.6794 98.1394 30.3631C98.5428 30.0467 99.0049 29.8134 99.4989 29.6764C99.9929 29.5395 100.509 29.5017 101.018 29.5651C101.526 29.6286 102.017 29.7921 102.463 30.0463C102.908 30.3004 103.298 30.6401 103.612 31.0458C103.925 31.4516 104.155 31.9153 104.288 32.4103C104.421 32.9053 104.455 33.4218 104.388 33.93L104.029 36.809C104.012 36.9462 103.941 37.0709 103.832 37.1558C103.723 37.2408 103.584 37.279 103.447 37.262L96.7732 36.429C96.7049 36.4206 96.639 36.3988 96.5792 36.3649C96.5195 36.3309 96.467 36.2855 96.4248 36.2313C96.3826 36.177 96.3516 36.115 96.3335 36.0487C96.3153 35.9824 96.3104 35.9132 96.3191 35.845Z"
      fill="#2F2E41"
    />
    <path
      d="M99.516 36.703C101.089 36.703 102.364 35.4279 102.364 33.855C102.364 32.2821 101.089 31.007 99.516 31.007C97.9431 31.007 96.668 32.2821 96.668 33.855C96.668 35.4279 97.9431 36.703 99.516 36.703Z"
      fill="#FFB8B8"
    />
    <path
      d="M95.7379 33.081C95.8398 32.2726 96.2581 31.5377 96.9011 31.0374C97.5441 30.537 98.3593 30.3121 99.1679 30.412L99.7429 30.484C100.551 30.5859 101.286 31.0042 101.787 31.6472C102.287 32.2902 102.512 33.1054 102.412 33.914L102.405 33.972L101.189 33.82L100.919 32.607L100.691 33.758L100.063 33.68L99.9269 33.068L99.812 33.649L95.7319 33.142L95.7379 33.081Z"
      fill="#2F2E41"
    />
    <path
      d="M85.5858 44.015C85.6728 44.186 85.7963 44.3358 85.9475 44.4539C86.0988 44.572 86.274 44.6555 86.461 44.6985C86.6479 44.7415 86.8421 44.7429 87.0297 44.7028C87.2173 44.6626 87.3938 44.5818 87.5468 44.466L90.1338 45.981L91.4338 44.633L87.7508 42.544C87.5396 42.2992 87.2458 42.1405 86.9253 42.098C86.6047 42.0556 86.2798 42.1324 86.0121 42.3138C85.7445 42.4952 85.5527 42.7685 85.4734 43.082C85.3941 43.3954 85.4327 43.7271 85.5818 44.014L85.5858 44.015Z"
      fill="#FFB8B8"
    />
    <path
      d="M94.0652 46.739C92.2237 46.6105 90.3983 46.3092 88.6132 45.839L88.5542 45.824L89.0832 43.148L94.2122 44.161L96.9982 40.237L100.198 39.912L100.109 40.029C100.067 40.084 95.9442 45.512 95.3022 46.423C95.1392 46.651 94.6762 46.739 94.0652 46.739Z"
      fill="#3F3D56"
    />
    <path
      d="M94.5771 56.781L94.5011 56.745C93.4748 56.2111 92.6172 55.4023 92.0241 54.409C91.1881 52.877 95.1011 46.377 95.3741 45.926V43.862L96.7811 40.062L98.5761 39.048L97.0351 42.648L94.5771 56.781Z"
      fill="#3F3D56"
    />
    <path
      d="M92.7781 78.966H91.063L90.2471 72.35H92.7791L92.7781 78.966Z"
      fill="#FFB8B8"
    />
    <path
      d="M93.2161 80.629H87.6851V80.559C87.6851 79.988 87.9119 79.4403 88.3157 79.0366C88.7194 78.6328 89.2671 78.406 89.8381 78.406H93.217L93.2161 80.629Z"
      fill="#2F2E41"
    />
    <path
      d="M111.203 77.463L109.573 77.997L106.737 71.964L109.143 71.176L111.203 77.463Z"
      fill="#FFB8B8"
    />
    <path
      d="M112.14 78.907L106.884 80.629L106.862 80.563C106.684 80.0205 106.729 79.4296 106.987 78.9203C107.245 78.411 107.694 78.0249 108.237 77.847L111.447 76.795L112.14 78.907Z"
      fill="#2F2E41"
    />
    <path
      d="M107.878 76.381L102.988 68.217L98.8601 59.495L95.1451 67.195L93.6691 77.174L88.4941 77.246L88.5141 77.165L96.4341 45.697L102.634 46.624L102.352 50.718L102.52 50.957C103.92 52.947 105.37 55.005 104.539 57.224L106.895 64.218L112.109 75.864L107.878 76.381Z"
      fill="#2F2E41"
    />
    <path
      d="M96.7219 52.521C95.8219 52.521 94.7978 51.495 95.3298 49.743L95.2958 49.718L96.5739 44.073C96.2586 43.5992 96.1343 43.0238 96.2257 42.4622C96.3172 41.9005 96.6176 41.3943 97.0668 41.045L97.7669 39.782L100.867 37.818L102.434 50.835L102.411 50.857C101.276 51.939 97.4759 52.521 96.7219 52.521Z"
      fill="#1AB395"
    />
    <path
      d="M103.063 55.458C102.94 55.4598 102.817 55.4467 102.697 55.419C102.577 55.389 102.382 55.235 102.08 54.255C100.88 50.355 99.4679 38.623 100.496 37.475L100.519 37.449L102.206 37.73C102.65 37.3835 103.198 37.1974 103.761 37.202C103.901 37.2196 104.035 37.2657 104.157 37.3374C104.278 37.4091 104.383 37.505 104.466 37.619L104.478 37.634L105.015 45.279L106.984 54.279L106.937 54.3C105.711 54.8793 104.405 55.2697 103.063 55.458Z"
      fill="#3F3D56"
    />
    <path
      d="M101.929 52.47C102.121 52.4635 102.309 52.4151 102.48 52.3282C102.651 52.2413 102.8 52.1179 102.919 51.9668C103.037 51.8156 103.12 51.6405 103.163 51.4536C103.206 51.2667 103.208 51.0726 103.168 50.885L105.637 49.185L104.97 47.432L101.517 49.883C101.206 49.971 100.939 50.1699 100.765 50.4421C100.591 50.7142 100.523 51.0406 100.574 51.3595C100.625 51.6784 100.791 51.9675 101.041 52.1722C101.29 52.3769 101.606 52.4828 101.929 52.47Z"
      fill="#FFB8B8"
    />
    <path
      d="M104.823 50.548L102.623 48.935L105.715 44.719L103.343 40.532L104.404 37.497L104.473 37.628C104.505 37.689 107.673 43.728 108.226 44.692C108.801 45.698 105.026 50.306 104.862 50.501L104.823 50.548Z"
      fill="#3F3D56"
    />
    <path
      d="M55.3491 69.522L53.4771 68.734L52.1931 59.35H35.0931L33.7011 68.695L32.0261 69.532C31.948 69.5735 31.8859 69.6397 31.8495 69.7202C31.8131 69.8007 31.8044 69.8911 31.8247 69.9771C31.845 70.0631 31.8933 70.14 31.9619 70.1957C32.0305 70.2514 32.1157 70.2828 32.2041 70.285H55.1941C55.2844 70.2824 55.3712 70.2493 55.4404 70.191C55.5095 70.1328 55.5568 70.0528 55.5747 69.9642C55.5926 69.8756 55.5799 69.7835 55.5387 69.7031C55.4976 69.6226 55.4304 69.5584 55.3481 69.521L55.3491 69.522Z"
      fill="#E6E6E6"
    />
    <path
      d="M85.7379 60.599H1.73788C1.29726 60.5979 0.875039 60.4222 0.563843 60.1102C0.252647 59.7983 0.0778796 59.3756 0.0778809 58.935V47.09H87.3989V58.935C87.3989 59.3756 87.2241 59.7983 86.9129 60.1102C86.6017 60.4222 86.1785 60.5979 85.7379 60.599Z"
      fill="#CCCCCC"
    />
    <path
      d="M87.486 52.689H0V2.006C0.00052948 1.47413 0.212052 0.964203 0.588135 0.58812C0.964218 0.212036 1.47413 0.00052948 2.006 0H85.479C86.0109 0.00052948 86.5208 0.212036 86.8969 0.58812C87.273 0.964203 87.4845 1.47413 87.485 2.006L87.486 52.689Z"
      fill="#3F3D56"
    />
    <path
      d="M82.261 49.009H5.22499C4.81478 49.0087 4.42144 48.8456 4.13138 48.5556C3.84132 48.2655 3.67824 47.8722 3.67798 47.462V5.22099C3.67824 4.81078 3.84132 4.41746 4.13138 4.1274C4.42144 3.83733 4.81478 3.67426 5.22499 3.674H82.261C82.6712 3.67426 83.0645 3.83733 83.3546 4.1274C83.6446 4.41746 83.8077 4.81078 83.808 5.22099V47.46C83.8081 47.6633 83.7682 47.8646 83.6905 48.0525C83.6129 48.2403 83.499 48.411 83.3553 48.5549C83.2116 48.6987 83.0411 48.8129 82.8533 48.8908C82.6655 48.9687 82.4643 49.0089 82.261 49.009Z"
      fill="white"
    />
    <path
      d="M65.3479 70.385H22.0599C22.0151 70.3867 21.9711 70.3733 21.9348 70.3471C21.8985 70.3208 21.8721 70.2831 21.8599 70.24C21.853 70.211 21.8527 70.1809 21.859 70.1517C21.8653 70.1226 21.878 70.0952 21.8962 70.0717C21.9144 70.0481 21.9376 70.0289 21.9642 70.0155C21.9908 70.002 22.0201 69.9947 22.0499 69.994H65.3289C65.3756 69.9924 65.4215 70.0067 65.459 70.0344C65.4966 70.0622 65.5237 70.1019 65.5359 70.147C65.5414 70.1755 65.5406 70.2048 65.5337 70.233C65.5267 70.2612 65.5137 70.2876 65.4955 70.3102C65.4773 70.3329 65.4544 70.3513 65.4284 70.3642C65.4024 70.3771 65.3739 70.3842 65.3448 70.385H65.3479Z"
      fill="#CCCCCC"
    />
    <path d="M81.4379 10.506H62.8379V24.716H81.4379V10.506Z" fill="#F2F2F2" />
    <path d="M80.5142 11.212H63.7632V24.01H80.5142V11.212Z" fill="white" />
    <path
      d="M72.4321 20.705H72.4391C73.5738 20.6468 74.6436 20.1582 75.4308 19.3389C76.2179 18.5196 76.6632 17.4311 76.6759 16.295C76.6886 15.1589 76.268 14.0606 75.4994 13.2238C74.7308 12.3871 73.6722 11.8747 72.5391 11.791C72.5251 11.7898 72.511 11.7916 72.4977 11.7963C72.4845 11.8009 72.4723 11.8083 72.4621 11.818C72.4517 11.8273 72.4435 11.8387 72.4378 11.8515C72.4321 11.8642 72.4292 11.878 72.4291 11.892L72.3291 20.602C72.3291 20.6285 72.3396 20.654 72.3584 20.6727C72.3772 20.6915 72.4026 20.702 72.4291 20.702L72.4321 20.705Z"
      fill="#3F3D56"
    />
    <path
      d="M68.93 13.231C68.9646 13.2314 68.9976 13.2454 69.022 13.27L72.022 16.337C72.034 16.3493 72.0435 16.3638 72.0498 16.3798C72.0562 16.3958 72.0592 16.4128 72.059 16.43L72.012 20.598C72.0119 20.6159 72.0081 20.6335 72.0009 20.6499C71.9936 20.6662 71.9831 20.6809 71.97 20.693C71.9565 20.7052 71.9407 20.7146 71.9235 20.7204C71.9063 20.7263 71.8881 20.7285 71.87 20.727C71.0371 20.663 70.2383 20.3683 69.5634 19.8761C68.8884 19.3839 68.3638 18.7135 68.0482 17.94C67.7327 17.1665 67.6387 16.3204 67.7767 15.4965C67.9148 14.6726 68.2795 13.9034 68.83 13.275C68.8425 13.2609 68.8579 13.2497 68.8752 13.2422C68.8924 13.2346 68.9111 13.2308 68.93 13.231Z"
      fill="#E6E6E6"
    />
    <path
      d="M71.9789 11.755C72.0125 11.7554 72.0447 11.7687 72.0689 11.792C72.0824 11.8048 72.0929 11.8204 72.0998 11.8377C72.1067 11.8549 72.1098 11.8734 72.1089 11.892L72.0669 15.653C72.0667 15.679 72.0587 15.7043 72.044 15.7257C72.0294 15.7472 72.0087 15.7637 71.9846 15.7734C71.9605 15.783 71.934 15.7853 71.9086 15.7799C71.8832 15.7745 71.86 15.7616 71.8419 15.743L69.2249 13.067C69.2125 13.0543 69.2027 13.0392 69.1963 13.0226C69.1899 13.006 69.1869 12.9883 69.1876 12.9705C69.1882 12.9527 69.1925 12.9353 69.2001 12.9192C69.2077 12.9031 69.2185 12.8887 69.2319 12.877C69.9927 12.2069 70.9575 11.8133 71.9699 11.76L71.9789 11.755Z"
      fill="#1AB395"
    />
    <path
      d="M70.6312 22.511C70.629 22.669 70.58 22.8229 70.4905 22.9531C70.401 23.0834 70.2749 23.1842 70.1281 23.2428C69.9814 23.3015 69.8205 23.3154 69.6659 23.2827C69.5112 23.25 69.3697 23.1723 69.2592 23.0593C69.1487 22.9463 69.0742 22.803 69.045 22.6477C69.0158 22.4924 69.0333 22.3319 69.0952 22.1865C69.1572 22.0411 69.2607 21.9173 69.393 21.8307C69.5252 21.7441 69.6801 21.6986 69.8382 21.7C69.9436 21.7009 70.0478 21.7226 70.1447 21.7639C70.2417 21.8052 70.3295 21.8653 70.4032 21.9407C70.4769 22.016 70.535 22.1052 70.5741 22.2031C70.6132 22.3009 70.6326 22.4056 70.6312 22.511Z"
      fill="#1AB395"
    />
    <path
      d="M72.9261 22.537C72.9239 22.695 72.875 22.8489 72.7854 22.9791C72.6959 23.1094 72.5698 23.2102 72.423 23.2689C72.2763 23.3275 72.1154 23.3413 71.9608 23.3087C71.8062 23.276 71.6647 23.1983 71.5542 23.0853C71.4437 22.9723 71.3691 22.8291 71.3399 22.6737C71.3108 22.5184 71.3282 22.3579 71.3901 22.2125C71.452 22.0671 71.5557 21.9433 71.6879 21.8567C71.8201 21.7701 71.9751 21.7246 72.1331 21.726C72.2385 21.7269 72.3426 21.7486 72.4396 21.7899C72.5366 21.8312 72.6245 21.8913 72.6982 21.9667C72.7719 22.042 72.8299 22.1312 72.869 22.2291C72.9081 22.327 72.9275 22.4316 72.9261 22.537Z"
      fill="#3F3D56"
    />
    <path
      d="M75.2152 22.563C75.213 22.721 75.164 22.8749 75.0745 23.0051C74.985 23.1354 74.8589 23.2362 74.7121 23.2948C74.5654 23.3535 74.4045 23.3674 74.2498 23.3347C74.0952 23.302 73.9537 23.2243 73.8432 23.1113C73.7327 22.9983 73.6582 22.855 73.629 22.6997C73.5998 22.5444 73.6173 22.3839 73.6792 22.2385C73.7412 22.0931 73.8447 21.9693 73.977 21.8827C74.1092 21.7961 74.2641 21.7506 74.4221 21.752C74.5275 21.7529 74.6318 21.7746 74.7287 21.8159C74.8257 21.8572 74.9135 21.9173 74.9872 21.9927C75.0609 22.068 75.119 22.1572 75.1581 22.2551C75.1972 22.3529 75.2166 22.4576 75.2152 22.563Z"
      fill="#E6E6E6"
    />
    <path d="M81.2431 26.952H62.6431V41.162H81.2431V26.952Z" fill="#F2F2F2" />
    <path d="M80.3179 27.658H63.5669V40.456H80.3179V27.658Z" fill="white" />
    <path
      d="M66.4099 31.866C66.7943 31.866 67.1059 31.5544 67.1059 31.17C67.1059 30.7856 66.7943 30.474 66.4099 30.474C66.0255 30.474 65.7139 30.7856 65.7139 31.17C65.7139 31.5544 66.0255 31.866 66.4099 31.866Z"
      fill="#3F3D56"
    />
    <path
      d="M77.5851 30.628C77.6489 30.6251 77.7126 30.6351 77.7723 30.6575C77.8321 30.6798 77.8867 30.7141 77.9329 30.7582C77.979 30.8022 78.0157 30.8552 78.0408 30.9139C78.0659 30.9725 78.0789 31.0357 78.0789 31.0995C78.0789 31.1633 78.0659 31.2265 78.0408 31.2851C78.0157 31.3438 77.979 31.3968 77.9329 31.4408C77.8867 31.4849 77.8321 31.5192 77.7723 31.5415C77.7126 31.5639 77.6489 31.5739 77.5851 31.571H70.2701C70.1488 31.5654 70.0344 31.5133 69.9505 31.4255C69.8666 31.3377 69.8198 31.2209 69.8198 31.0995C69.8198 30.9781 69.8666 30.8613 69.9505 30.7735C70.0344 30.6857 70.1488 30.6336 70.2701 30.628H77.5851ZM77.5851 30.512H70.2701C70.1145 30.512 69.9651 30.5738 69.855 30.6839C69.745 30.794 69.6831 30.9433 69.6831 31.099C69.6831 31.2547 69.745 31.404 69.855 31.5141C69.9651 31.6242 70.1145 31.686 70.2701 31.686H77.5851C77.7408 31.686 77.8901 31.6242 78.0002 31.5141C78.1103 31.404 78.1721 31.2547 78.1721 31.099C78.1721 30.9433 78.1103 30.794 78.0002 30.6839C77.8901 30.5738 77.7408 30.512 77.5851 30.512Z"
      fill="#3F3D56"
    />
    <path
      d="M74.698 31.687H69.191C69.114 31.687 69.0376 31.6718 68.9664 31.6423C68.8952 31.6128 68.8304 31.5696 68.7759 31.5151C68.7214 31.4605 68.6782 31.3958 68.6487 31.3246C68.6192 31.2534 68.604 31.1771 68.604 31.1C68.604 31.0229 68.6192 30.9466 68.6487 30.8753C68.6782 30.8041 68.7214 30.7394 68.7759 30.6849C68.8304 30.6304 68.8952 30.5872 68.9664 30.5577C69.0376 30.5282 69.114 30.513 69.191 30.513H74.698C74.7751 30.513 74.8515 30.5282 74.9227 30.5577C74.9939 30.5872 75.0586 30.6304 75.1131 30.6849C75.1676 30.7394 75.2109 30.8041 75.2404 30.8753C75.2699 30.9466 75.285 31.0229 75.285 31.1C75.285 31.1771 75.2699 31.2534 75.2404 31.3246C75.2109 31.3958 75.1676 31.4605 75.1131 31.5151C75.0586 31.5696 74.9939 31.6128 74.9227 31.6423C74.8515 31.6718 74.7751 31.687 74.698 31.687Z"
      fill="#1AB395"
    />
    <path
      d="M66.4099 34.754C66.7943 34.754 67.1059 34.4424 67.1059 34.058C67.1059 33.6736 66.7943 33.362 66.4099 33.362C66.0255 33.362 65.7139 33.6736 65.7139 34.058C65.7139 34.4424 66.0255 34.754 66.4099 34.754Z"
      fill="#3F3D56"
    />
    <path
      d="M77.5842 33.516C77.6479 33.513 77.7116 33.5231 77.7714 33.5454C77.8311 33.5678 77.8857 33.6021 77.9319 33.6461C77.978 33.6902 78.0148 33.7432 78.0399 33.8019C78.065 33.8605 78.0779 33.9237 78.0779 33.9875C78.0779 34.0513 78.065 34.1144 78.0399 34.1731C78.0148 34.2318 77.978 34.2848 77.9319 34.3288C77.8857 34.3729 77.8311 34.4072 77.7714 34.4295C77.7116 34.4519 77.6479 34.4619 77.5842 34.459H70.2692C70.1479 34.4534 70.0334 34.4013 69.9495 34.3135C69.8657 34.2257 69.8188 34.1089 69.8188 33.9875C69.8188 33.8661 69.8657 33.7493 69.9495 33.6615C70.0334 33.5737 70.1479 33.5216 70.2692 33.516H77.5842ZM77.5842 33.4H70.2692C70.1135 33.4 69.9641 33.4618 69.8541 33.5719C69.744 33.682 69.6821 33.8313 69.6821 33.987C69.6821 34.1427 69.744 34.292 69.8541 34.4021C69.9641 34.5121 70.1135 34.574 70.2692 34.574H77.5842C77.6613 34.574 77.7376 34.5588 77.8088 34.5293C77.88 34.4998 77.9447 34.4566 77.9992 34.4021C78.0537 34.3476 78.097 34.2828 78.1265 34.2116C78.156 34.1404 78.1711 34.0641 78.1711 33.987C78.1711 33.9099 78.156 33.8336 78.1265 33.7624C78.097 33.6911 78.0537 33.6264 77.9992 33.5719C77.9447 33.5174 77.88 33.4742 77.8088 33.4447C77.7376 33.4152 77.6613 33.4 77.5842 33.4Z"
      fill="#3F3D56"
    />
    <path
      d="M76.777 34.575H69.191C69.0354 34.575 68.886 34.5132 68.7759 34.4031C68.6659 34.293 68.604 34.1437 68.604 33.988C68.604 33.8323 68.6659 33.683 68.7759 33.5729C68.886 33.4628 69.0354 33.401 69.191 33.401H76.777C76.9327 33.401 77.082 33.4628 77.1921 33.5729C77.3022 33.683 77.364 33.8323 77.364 33.988C77.364 34.1437 77.3022 34.293 77.1921 34.4031C77.082 34.5132 76.9327 34.575 76.777 34.575Z"
      fill="#1AB395"
    />
    <path
      d="M66.4099 37.642C66.7943 37.642 67.1059 37.3304 67.1059 36.946C67.1059 36.5616 66.7943 36.25 66.4099 36.25C66.0255 36.25 65.7139 36.5616 65.7139 36.946C65.7139 37.3304 66.0255 37.642 66.4099 37.642Z"
      fill="#3F3D56"
    />
    <path
      d="M77.5842 36.404C77.6479 36.4011 77.7116 36.4111 77.7714 36.4334C77.8311 36.4558 77.8857 36.4901 77.9319 36.5342C77.978 36.5782 78.0148 36.6312 78.0399 36.6899C78.065 36.7485 78.0779 36.8117 78.0779 36.8755C78.0779 36.9393 78.065 37.0025 78.0399 37.0611C78.0148 37.1198 77.978 37.1728 77.9319 37.2168C77.8857 37.2609 77.8311 37.2952 77.7714 37.3175C77.7116 37.3399 77.6479 37.3499 77.5842 37.347H70.2692C70.1479 37.3414 70.0334 37.2893 69.9495 37.2015C69.8657 37.1137 69.8188 36.9969 69.8188 36.8755C69.8188 36.7541 69.8657 36.6373 69.9495 36.5495C70.0334 36.4617 70.1479 36.4096 70.2692 36.404H77.5842ZM77.5842 36.288H70.2692C70.1135 36.288 69.9641 36.3498 69.8541 36.4599C69.744 36.57 69.6821 36.7193 69.6821 36.875C69.6821 37.0307 69.744 37.18 69.8541 37.2901C69.9641 37.4002 70.1135 37.462 70.2692 37.462H77.5842C77.7398 37.462 77.8891 37.4002 77.9992 37.2901C78.1093 37.18 78.1711 37.0307 78.1711 36.875C78.1711 36.7193 78.1093 36.57 77.9992 36.4599C77.8891 36.3498 77.7398 36.288 77.5842 36.288Z"
      fill="#3F3D56"
    />
    <path
      d="M72.272 37.463H69.191C69.0354 37.463 68.886 37.4011 68.7759 37.2911C68.6659 37.181 68.604 37.0317 68.604 36.876C68.604 36.7203 68.6659 36.571 68.7759 36.4609C68.886 36.3508 69.0354 36.289 69.191 36.289H72.272C72.4277 36.289 72.577 36.3508 72.6871 36.4609C72.7972 36.571 72.859 36.7203 72.859 36.876C72.859 37.0317 72.7972 37.181 72.6871 37.2911C72.577 37.4011 72.4277 37.463 72.272 37.463Z"
      fill="#1AB395"
    />
    <path d="M58.5309 13.02H6.56592V39.652H58.5309V13.02Z" fill="#F2F2F2" />
    <path d="M56.7991 14.344H8.29907V38.329H56.7991V14.344Z" fill="white" />
    <path
      d="M50.7839 34.674H15.4279C15.3985 34.674 15.3702 34.6623 15.3494 34.6415C15.3286 34.6207 15.3169 34.5924 15.3169 34.563V17.613C15.3154 17.5976 15.3172 17.5821 15.3221 17.5674C15.327 17.5527 15.3349 17.5393 15.3453 17.5278C15.3557 17.5164 15.3684 17.5072 15.3825 17.501C15.3966 17.4947 15.4119 17.4915 15.4274 17.4915C15.4429 17.4915 15.4582 17.4947 15.4723 17.501C15.4865 17.5072 15.4991 17.5164 15.5095 17.5278C15.5199 17.5393 15.5278 17.5527 15.5327 17.5674C15.5376 17.5821 15.5394 17.5976 15.5379 17.613V34.448H50.7839C50.7993 34.4465 50.8148 34.4483 50.8295 34.4532C50.8442 34.4581 50.8577 34.466 50.8691 34.4764C50.8806 34.4868 50.8897 34.4994 50.8959 34.5136C50.9022 34.5277 50.9054 34.543 50.9054 34.5585C50.9054 34.5739 50.9022 34.5893 50.8959 34.6034C50.8897 34.6175 50.8806 34.6302 50.8691 34.6406C50.8577 34.651 50.8442 34.6589 50.8295 34.6638C50.8148 34.6687 50.7993 34.6705 50.7839 34.669V34.674Z"
      fill="#3F3D56"
    />
    <path
      d="M22.9829 33.452H19.767C19.6801 33.4517 19.5968 33.4171 19.5353 33.3556C19.4739 33.2942 19.4392 33.2109 19.439 33.124V28.691C19.4392 28.6041 19.4739 28.5208 19.5353 28.4594C19.5968 28.3979 19.6801 28.3633 19.767 28.363H22.9829C23.0699 28.3633 23.1531 28.3979 23.2146 28.4594C23.2761 28.5208 23.3107 28.6041 23.3109 28.691V33.124C23.3107 33.2109 23.2761 33.2942 23.2146 33.3556C23.1531 33.4171 23.0699 33.4517 22.9829 33.452Z"
      fill="#1AB395"
    />
    <path
      d="M28.8462 33.452H25.6322C25.5453 33.4517 25.462 33.4171 25.4006 33.3556C25.3391 33.2942 25.3045 33.2109 25.3042 33.124V24.486C25.3045 24.3991 25.3391 24.3158 25.4006 24.2544C25.462 24.1929 25.5453 24.1583 25.6322 24.158H28.8482C28.9351 24.1583 29.0184 24.1929 29.0799 24.2544C29.1413 24.3158 29.1759 24.3991 29.1762 24.486V33.124C29.1761 33.1672 29.1674 33.21 29.1508 33.2498C29.1341 33.2897 29.1098 33.3259 29.0791 33.3563C29.0485 33.3868 29.0122 33.4109 28.9722 33.4273C28.9322 33.4437 28.8894 33.4521 28.8462 33.452Z"
      fill="#1AB395"
    />
    <path
      d="M34.711 33.452H31.495C31.4081 33.4517 31.3248 33.4171 31.2633 33.3556C31.2019 33.2942 31.1673 33.2109 31.167 33.124V28.691C31.1673 28.6041 31.2019 28.5208 31.2633 28.4594C31.3248 28.3979 31.4081 28.3633 31.495 28.363H34.711C34.7979 28.3633 34.8812 28.3979 34.9426 28.4594C35.0041 28.5208 35.0387 28.6041 35.039 28.691V33.124C35.0387 33.2109 35.0041 33.2942 34.9426 33.3556C34.8812 33.4171 34.7979 33.4517 34.711 33.452Z"
      fill="#1AB395"
    />
    <path
      d="M40.5753 33.452H37.3593C37.2742 33.4536 37.1919 33.4215 37.1305 33.3626C37.069 33.3038 37.0333 33.223 37.0312 33.138V22.812C37.0333 22.727 37.069 22.6462 37.1305 22.5873C37.1919 22.5285 37.2742 22.4964 37.3593 22.498H40.5753C40.6603 22.4964 40.7426 22.5285 40.804 22.5873C40.8655 22.6462 40.9011 22.727 40.9032 22.812V33.139C40.9009 33.2239 40.8651 33.3044 40.8037 33.363C40.7423 33.4216 40.6602 33.4536 40.5753 33.452Z"
      fill="#1AB395"
    />
    <path
      d="M46.439 33.452H43.223C43.1361 33.4517 43.0528 33.4171 42.9914 33.3556C42.9299 33.2942 42.8953 33.2109 42.895 33.124V19.839C42.8953 19.7521 42.9299 19.6688 42.9914 19.6074C43.0528 19.5459 43.1361 19.5113 43.223 19.511H46.439C46.5259 19.5113 46.6092 19.5459 46.6707 19.6074C46.7321 19.6688 46.7668 19.7521 46.767 19.839V33.124C46.7668 33.2109 46.7321 33.2942 46.6707 33.3556C46.6092 33.4171 46.5259 33.4517 46.439 33.452Z"
      fill="#1AB395"
    />
    <path
      d="M21.374 27.035C21.7407 27.035 22.038 26.7377 22.038 26.371C22.038 26.0043 21.7407 25.707 21.374 25.707C21.0072 25.707 20.71 26.0043 20.71 26.371C20.71 26.7377 21.0072 27.035 21.374 27.035Z"
      fill="#3F3D56"
    />
    <path
      d="M27.2392 22.72C27.6059 22.72 27.9032 22.4227 27.9032 22.056C27.9032 21.6893 27.6059 21.392 27.2392 21.392C26.8725 21.392 26.5752 21.6893 26.5752 22.056C26.5752 22.4227 26.8725 22.72 27.2392 22.72Z"
      fill="#3F3D56"
    />
    <path
      d="M33.103 27.035C33.4697 27.035 33.767 26.7377 33.767 26.371C33.767 26.0043 33.4697 25.707 33.103 25.707C32.7362 25.707 32.439 26.0043 32.439 26.371C32.439 26.7377 32.7362 27.035 33.103 27.035Z"
      fill="#3F3D56"
    />
    <path
      d="M38.9672 20.618C39.3339 20.618 39.6312 20.3207 39.6312 19.954C39.6312 19.5873 39.3339 19.29 38.9672 19.29C38.6005 19.29 38.3032 19.5873 38.3032 19.954C38.3032 20.3207 38.6005 20.618 38.9672 20.618Z"
      fill="#3F3D56"
    />
    <path
      d="M44.832 18.183C45.1987 18.183 45.496 17.8857 45.496 17.519C45.496 17.1523 45.1987 16.855 44.832 16.855C44.4653 16.855 44.168 17.1523 44.168 17.519C44.168 17.8857 44.4653 18.183 44.832 18.183Z"
      fill="#3F3D56"
    />
    <path
      d="M33.1171 26.52L27.2392 22.12L21.4391 26.463L21.3062 26.286L27.2372 21.844L33.0871 26.226L38.9012 19.864L38.9241 19.855L44.7881 17.492L44.8712 17.697L39.0322 20.051L33.1171 26.52Z"
      fill="#3F3D56"
    />
    <path
      d="M120.78 80.999H71.9039C71.8699 80.999 71.8371 80.9854 71.813 80.9613C71.789 80.9373 71.7754 80.9045 71.7754 80.8705C71.7754 80.8364 71.789 80.8037 71.813 80.7796C71.8371 80.7555 71.8699 80.742 71.9039 80.742H120.778C120.812 80.742 120.845 80.7555 120.869 80.7796C120.893 80.8037 120.906 80.8364 120.906 80.8705C120.906 80.9045 120.893 80.9373 120.869 80.9613C120.845 80.9854 120.812 80.999 120.778 80.999H120.78Z"
      fill="#CCCCCC"
    />
  </SVG>
)

export default withIconStyles(IconAdminDashboardAddOn)
