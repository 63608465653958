/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'

const IconSparkPhonics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.007"
    height="48.656"
    viewBox="0 0 44.007 48.656"
  >
    <g transform="translate(-24.459 0)">
      <path
        d="M40.82,21.56a3.085,3.085,0,0,1,1.207.243,1.425,1.425,0,0,0,1.98-1.313V7.236A1.426,1.426,0,0,0,42.582,5.81H30.623a5.958,5.958,0,0,0-11.912,0H7.72A1.426,1.426,0,0,0,6.295,7.236V18.718q-.167-.009-.337-.009a5.958,5.958,0,0,0,0,11.916q.169,0,.337-.009V42.1A1.426,1.426,0,0,0,7.72,43.523H18.766a5.959,5.959,0,0,0,11.8,0H42.582A1.426,1.426,0,0,0,44.007,42.1V28.844a1.425,1.425,0,0,0-1.98-1.313A3.108,3.108,0,1,1,40.82,21.56Zm0,9.065q.169,0,.337-.009V40.672h-12a1.425,1.425,0,0,0-1.413,1.613,3.161,3.161,0,0,1,.028.413,3.107,3.107,0,0,1-6.214,0,3.178,3.178,0,0,1,.028-.413,1.425,1.425,0,0,0-1.413-1.613H9.145V28.844a1.425,1.425,0,0,0-1.98-1.313,3.107,3.107,0,1,1,0-5.728,1.425,1.425,0,0,0,1.98-1.313V8.661H20.318a1.425,1.425,0,0,0,1.368-1.827,3.107,3.107,0,1,1,5.962,0,1.425,1.425,0,0,0,1.368,1.827H41.157V18.718q-.167-.009-.337-.009a5.958,5.958,0,0,0,0,11.916Z"
        transform="translate(24.459 0)"
        fill="#b0bac1"
      />
    </g>
  </svg>
)

export default withIconStyles(IconSparkPhonics)
