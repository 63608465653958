import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSpecs = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="44.892"
    height="32.579"
    viewBox="0 0 44.892 32.579"
    {...props}
  >
    <g transform="translate(0 -70.215)">
      <g transform="translate(0 70.215)">
        <path
          d="M42.871,86.923c-.008-.013-10.485-14.879-10.485-14.879a4.313,4.313,0,0,0-5.821-1.167l-.238.15a4.354,4.354,0,0,0-1.315,6.013.877.877,0,1,0,1.468-.96,2.584,2.584,0,0,1,.78-3.569l.238-.15a2.56,2.56,0,0,1,3.455.693L38.4,83.619a9.891,9.891,0,0,0-12.914,6.608,4.283,4.283,0,0,0-3.039-1.144,4.283,4.283,0,0,0-3.039,1.144A9.89,9.89,0,0,0,6.5,83.617L13.95,73.054a2.56,2.56,0,0,1,3.455-.693l.238.15a2.584,2.584,0,0,1,.78,3.569.877.877,0,0,0,1.468.96,4.354,4.354,0,0,0-1.315-6.013l-.238-.149a4.313,4.313,0,0,0-5.821,1.167S2.044,86.891,2.036,86.9a9.883,9.883,0,0,0,3.785,15.018.877.877,0,0,0,.722-1.6,8.135,8.135,0,1,1,6.739-.023.877.877,0,1,0,.733,1.593,9.921,9.921,0,0,0,5.76-8.9,2.732,2.732,0,0,1,5.343,0,9.888,9.888,0,1,0,17.754-6.072ZM35,101.041a8.134,8.134,0,1,1,8.134-8.134A8.144,8.144,0,0,1,35,101.041Z"
          transform="translate(0 -70.215)"
          fill="#637885"
        />
        <path
          d="M104.827,422.564a1.066,1.066,0,1,0-.452,1.331A1.074,1.074,0,0,0,104.827,422.564Z"
          transform="translate(-93.843 -391.459)"
          fill="#637885"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconSpecs)
