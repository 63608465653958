/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAttendanceAddOn = (props) => (
  <SVG
    width="144"
    height="100"
    viewBox="0 0 144 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_32_1617)">
      <path
        d="M111 5H20C17.2386 5 15 7.23858 15 10V70C15 72.7614 17.2386 75 20 75H111C113.761 75 116 72.7614 116 70V10C116 7.23858 113.761 5 111 5Z"
        fill="white"
      />
    </g>
    <path
      d="M138.139 61.794C137.198 62.7866 136.07 63.5844 134.821 64.142C134.704 64.198 134.587 64.252 134.468 64.303H134.458C133.625 64.6634 132.768 64.9694 131.895 65.219C126.761 66.6757 121.364 66.9577 116.106 66.044L115.806 65.99V53.809C115.906 53.767 116.006 53.725 116.106 53.684C116.763 53.412 117.427 53.162 118.099 52.934C119.066 52.6006 120.047 52.3173 121.042 52.084C124.286 51.2995 127.634 51.0295 130.962 51.284C131.567 51.3293 132.171 51.396 132.774 51.484C134.064 51.6421 135.325 51.9835 136.519 52.498C136.689 52.578 136.855 52.66 137.019 52.749C138.058 53.2737 138.909 54.1076 139.455 55.136C139.646 55.5152 139.784 55.919 139.864 56.336C139.894 56.48 139.915 56.626 139.932 56.77C139.995 57.6857 139.868 58.6045 139.56 59.469C139.251 60.3334 138.767 61.1249 138.139 61.794Z"
      fill="#C7E6E0"
    />
    <path
      d="M139.921 56.766C139.906 56.7697 139.891 56.7717 139.876 56.772C137.303 56.8847 134.733 57.0513 132.167 57.272H132.137C129.502 57.5 126.873 57.786 124.249 58.13L123.919 58.174C122.104 58.416 120.293 58.6843 118.486 58.979C117.686 59.1123 116.89 59.2487 116.098 59.388C115.998 59.406 115.898 59.423 115.798 59.442V58.987C115.898 58.967 115.998 58.951 116.098 58.933C118.745 58.4663 121.4 58.0573 124.065 57.706C124.731 57.616 125.402 57.531 126.078 57.451C128.018 57.217 129.962 57.0146 131.909 56.844L132.336 56.808C134.828 56.596 137.323 56.4353 139.823 56.326C139.835 56.3244 139.847 56.3244 139.859 56.326C140.094 56.353 140.145 56.714 139.921 56.766Z"
      fill="white"
    />
    <path
      d="M134.577 63.862C131.34 63.8491 128.239 62.5611 125.946 60.2771C125.306 59.6396 124.743 58.9302 124.266 58.163C124.113 57.918 123.756 58.188 123.907 58.431C125.713 61.2607 128.561 63.264 131.835 64.006C132.754 64.2143 133.693 64.315 134.635 64.306C134.922 64.305 134.862 63.86 134.577 63.862Z"
      fill="white"
    />
    <path
      d="M121.029 52.084C121.015 52.1263 120.991 52.1643 120.958 52.1941C120.925 52.224 120.884 52.2446 120.841 52.254C120.106 52.4465 119.397 52.7237 118.726 53.08C117.747 53.5972 116.858 54.2692 116.093 55.07C116.052 55.111 116.013 55.151 115.976 55.193C115.913 55.259 115.853 55.327 115.793 55.393V54.738C115.893 54.638 115.993 54.538 116.093 54.438C116.697 53.8599 117.366 53.3539 118.086 52.93C119.053 52.5993 120.034 52.3173 121.029 52.084Z"
      fill="white"
    />
    <path
      d="M136.77 52.373C134.731 53.2494 133.025 54.7538 131.9 56.667C131.877 56.7217 131.877 56.7831 131.899 56.8379C131.922 56.8928 131.965 56.9366 132.019 56.96C132.074 56.9817 132.135 56.9814 132.19 56.9593C132.245 56.9371 132.289 56.8948 132.313 56.841C133.387 55.0341 135.007 53.6149 136.939 52.788C136.966 52.7769 136.991 52.7606 137.012 52.7399C137.033 52.7192 137.05 52.6946 137.061 52.6675C137.072 52.6404 137.078 52.6112 137.079 52.5818C137.079 52.5524 137.073 52.5233 137.062 52.496C137.051 52.4688 137.035 52.4439 137.014 52.423C136.993 52.4021 136.969 52.3854 136.941 52.374C136.914 52.3626 136.885 52.3566 136.856 52.3564C136.826 52.3563 136.797 52.3619 136.77 52.373Z"
      fill="white"
    />
    <path
      d="M142.918 39.614C142.546 40.9303 141.918 42.1604 141.07 43.234C140.993 43.334 140.914 43.44 140.831 43.534C140.254 44.2444 139.632 44.9161 138.967 45.545C136.578 47.8221 133.879 49.7505 130.95 51.273C126.367 53.7068 121.282 55.0451 116.094 55.183H115.794V48.372C115.894 48.186 115.994 47.999 116.094 47.815C117.419 45.4304 119.035 43.2195 120.905 41.233C120.95 41.183 120.997 41.133 121.043 41.086C121.746 40.344 122.483 39.6376 123.254 38.967C126.219 36.3556 129.647 34.3222 133.361 32.972C134.577 32.5121 135.852 32.2289 137.148 32.131C137.336 32.121 137.522 32.116 137.71 32.118C138.874 32.1001 140.015 32.4432 140.977 33.1C141.322 33.3478 141.631 33.6415 141.896 33.973C141.989 34.089 142.075 34.207 142.157 34.329C142.641 35.1099 142.959 35.9825 143.09 36.892C143.22 37.8015 143.162 38.7282 142.918 39.614Z"
      fill="#C7E6E0"
    />
    <path
      d="M142.158 34.332C142.147 34.3419 142.135 34.3506 142.122 34.358C139.899 35.6513 137.704 36.9917 135.537 38.379H135.529L135.502 38.396C133.274 39.8234 131.078 41.2983 128.914 42.821L128.641 43.013C127.146 44.069 125.667 45.1477 124.204 46.249C121.44 48.331 118.737 50.49 116.094 52.726C115.994 52.81 115.894 52.896 115.794 52.981V52.397C115.894 52.311 115.994 52.226 116.094 52.142C117.107 51.2873 118.128 50.4447 119.158 49.614C119.466 49.366 119.775 49.114 120.085 48.873C122.834 46.6843 125.644 44.5773 128.516 42.552C129.081 42.152 129.647 41.7573 130.216 41.368C131.826 40.2593 133.454 39.1773 135.101 38.122L135.46 37.892C137.567 36.546 139.702 35.2443 141.865 33.987C141.875 33.9804 141.886 33.9747 141.898 33.97C142.122 33.885 142.333 34.183 142.158 34.332Z"
      fill="white"
    />
    <path
      d="M140.723 43.097C137.85 44.589 134.506 44.8887 131.414 43.931C130.551 43.6639 129.723 43.2978 128.945 42.84C128.696 42.694 128.505 43.099 128.752 43.24C131.665 44.9076 135.119 45.3585 138.363 44.495C139.273 44.2527 140.151 43.9054 140.981 43.46C141.234 43.33 140.975 42.964 140.723 43.097Z"
      fill="white"
    />
    <path
      d="M119.566 48.595C119.426 47.8086 119.378 47.0085 119.423 46.211C119.524 44.3923 120.081 42.6282 121.042 41.081C121.746 40.339 122.483 39.6323 123.254 38.961C123.261 39.0049 123.257 39.0499 123.242 39.0917C123.226 39.1334 123.2 39.1704 123.166 39.199C122.605 39.7109 122.105 40.2861 121.677 40.913C120.146 43.1467 119.549 45.8901 120.012 48.558C120.02 48.5883 120.019 48.62 120.011 48.6501C120.003 48.6803 119.987 48.7078 119.965 48.73C119.933 48.7582 119.894 48.7775 119.852 48.7859C119.81 48.7943 119.767 48.7916 119.727 48.778C119.686 48.7645 119.65 48.7405 119.621 48.7086C119.593 48.6766 119.574 48.6378 119.565 48.596L119.566 48.595Z"
      fill="white"
    />
    <path
      d="M137.327 31.905C135.928 33.6284 135.116 35.7531 135.009 37.97C135.015 38.0283 135.044 38.0818 135.089 38.1192C135.134 38.1565 135.192 38.1748 135.251 38.17C135.309 38.1625 135.361 38.1333 135.398 38.0884C135.436 38.0435 135.454 37.9862 135.451 37.928C135.562 35.8291 136.338 33.8199 137.665 32.19C137.852 31.971 137.51 31.681 137.327 31.905Z"
      fill="white"
    />
    <path
      d="M121.772 36.84C121.762 36.969 121.749 37.097 121.736 37.225C121.632 38.1346 121.469 39.0365 121.248 39.925C121.143 40.361 121.029 40.793 120.904 41.225C120.889 41.281 120.872 41.336 120.856 41.39C120.489 42.6473 120.051 43.8826 119.543 45.09C118.609 47.3146 117.453 49.4393 116.093 51.432C115.993 51.578 115.893 51.723 115.793 51.868V28.352C115.893 28.346 115.993 28.346 116.093 28.352C116.141 28.352 116.189 28.352 116.237 28.352C116.661 28.3743 117.08 28.4558 117.481 28.594C117.622 28.641 117.759 28.694 117.894 28.752C118.722 29.1494 119.459 29.7136 120.058 30.4091C120.658 31.1047 121.108 31.9166 121.379 32.794C121.774 34.1039 121.907 35.4786 121.772 36.84Z"
      fill="#C7E6E0"
    />
    <path
      d="M117.894 28.753C117.89 28.7677 117.885 28.7819 117.877 28.795C117.268 29.977 116.673 31.1653 116.092 32.36C115.992 32.566 115.892 32.773 115.792 32.979V31.96C115.891 31.756 115.991 31.5517 116.092 31.347C116.541 30.437 116.998 29.5307 117.462 28.628C117.467 28.6175 117.473 28.6074 117.48 28.598C117.619 28.397 117.961 28.532 117.894 28.753Z"
      fill="white"
    />
    <path
      d="M121.82 37.083C121.793 37.131 121.766 37.183 121.736 37.227C121.295 37.9957 120.77 38.713 120.171 39.366C119.02 40.6147 117.634 41.6239 116.093 42.336C115.993 42.384 115.893 42.429 115.793 42.471V41.983C115.893 41.938 115.993 41.891 116.093 41.843C118.324 40.7787 120.178 39.0588 121.406 36.913C121.424 36.8823 121.448 36.8557 121.477 36.8351C121.505 36.8144 121.538 36.8003 121.573 36.7936C121.608 36.7868 121.644 36.7877 121.678 36.796C121.713 36.8044 121.745 36.8201 121.773 36.842C121.792 36.8546 121.808 36.8708 121.821 36.8897C121.833 36.9086 121.842 36.9298 121.847 36.9521C121.851 36.9744 121.851 36.9973 121.847 37.0196C121.842 37.0419 121.834 37.0631 121.821 37.082L121.82 37.083Z"
      fill="white"
    />
    <path
      d="M116.093 9.957V21.373H115.793V21.251H15.301V21.373H15.001V9.957C15.0023 8.64256 15.5251 7.38235 16.4547 6.45299C17.3842 5.52363 18.6445 5.00106 19.959 5H111.138C112.452 5.00185 113.711 5.52477 114.64 6.45404C115.569 7.38331 116.092 8.64307 116.093 9.957Z"
      fill="#1AB395"
    />
    <path
      d="M35.4288 16.19C37.0879 16.19 38.4328 14.8451 38.4328 13.186C38.4328 11.5269 37.0879 10.182 35.4288 10.182C33.7697 10.182 32.4248 11.5269 32.4248 13.186C32.4248 14.8451 33.7697 16.19 35.4288 16.19Z"
      fill="white"
    />
    <g filter="url(#filter1_d_32_1617)">
      <path
        d="M35.4239 16.188C37.083 16.188 38.4279 14.8431 38.4279 13.184C38.4279 11.5249 37.083 10.18 35.4239 10.18C33.7649 10.18 32.4199 11.5249 32.4199 13.184C32.4199 14.8431 33.7649 16.188 35.4239 16.188Z"
        fill="white"
      />
    </g>
    <path
      d="M95.6642 16.19C97.3232 16.19 98.6682 14.8451 98.6682 13.186C98.6682 11.5269 97.3232 10.182 95.6642 10.182C94.0051 10.182 92.6602 11.5269 92.6602 13.186C92.6602 14.8451 94.0051 16.19 95.6642 16.19Z"
      fill="white"
    />
    <g filter="url(#filter2_d_32_1617)">
      <path
        d="M95.6642 16.188C97.3232 16.188 98.6682 14.8431 98.6682 13.184C98.6682 11.5249 97.3232 10.18 95.6642 10.18C94.0051 10.18 92.6602 11.5249 92.6602 13.184C92.6602 14.8431 94.0051 16.188 95.6642 16.188Z"
        fill="white"
      />
    </g>
    <path
      d="M44.1091 46.679H26.2371C25.4799 46.6782 24.7539 46.377 24.2185 45.8416C23.683 45.3062 23.3819 44.5802 23.3811 43.823V35.379C23.3819 34.6218 23.683 33.8958 24.2185 33.3604C24.7539 32.825 25.4799 32.5238 26.2371 32.523H44.1091C44.8663 32.5238 45.5923 32.825 46.1277 33.3604C46.6631 33.8958 46.9643 34.6218 46.9651 35.379V43.826C46.9635 44.5827 46.662 45.3079 46.1267 45.8427C45.5913 46.3775 44.8658 46.6782 44.1091 46.679Z"
      fill="#1AB395"
    />
    <path
      d="M74.5112 46.679H56.6402C55.883 46.6782 55.157 46.377 54.6216 45.8416C54.0861 45.3062 53.785 44.5802 53.7842 43.823V35.379C53.785 34.6218 54.0861 33.8958 54.6216 33.3604C55.157 32.825 55.883 32.5238 56.6402 32.523H74.5122C75.2694 32.5238 75.9954 32.825 76.5308 33.3604C77.0662 33.8958 77.3674 34.6218 77.3682 35.379V43.826C77.3666 44.5829 77.065 45.3082 76.5294 45.843C75.9938 46.3778 75.2681 46.6785 74.5112 46.679Z"
      fill="#1AB395"
    />
    <path
      d="M74.5118 68.508H56.6368C55.8569 68.5069 55.1092 68.1966 54.5577 67.6451C54.0062 67.0936 53.6959 66.3459 53.6948 65.566V57.119C53.6959 56.339 54.0062 55.5914 54.5577 55.0399C55.1092 54.4884 55.8569 54.1781 56.6368 54.177H74.5088C75.2888 54.1781 76.0364 54.4884 76.588 55.0399C77.1395 55.5914 77.4497 56.339 77.4508 57.119V65.566C77.4497 66.3454 77.1399 67.0927 76.589 67.6441C76.0382 68.1955 75.2912 68.5061 74.5118 68.508Z"
      fill="#F2F2F2"
    />
    <path
      d="M44.0501 68.508H26.1781C25.3981 68.5069 24.6504 68.1966 24.0989 67.6451C23.5474 67.0936 23.2371 66.3459 23.2361 65.566V57.119C23.2371 56.339 23.5474 55.5914 24.0989 55.0399C24.6504 54.4884 25.3981 54.1781 26.1781 54.177H44.0501C44.83 54.1781 45.5777 54.4884 46.1292 55.0399C46.6807 55.5914 46.991 56.339 46.9921 57.119V65.566C46.991 66.3459 46.6807 67.0936 46.1292 67.6451C45.5777 68.1966 44.83 68.5069 44.0501 68.508Z"
      fill="#F2F2F2"
    />
    <path
      d="M104.914 68.508H87.0411C86.2612 68.5069 85.5135 68.1966 84.962 67.6451C84.4105 67.0936 84.1002 66.3459 84.0991 65.566V57.119C84.1002 56.339 84.4105 55.5914 84.962 55.0399C85.5135 54.4884 86.2612 54.1781 87.0411 54.177H104.913C105.693 54.1781 106.441 54.4884 106.992 55.0399C107.544 55.5914 107.854 56.339 107.855 57.119V65.566C107.854 66.3457 107.544 67.0933 106.993 67.6448C106.441 68.1962 105.694 68.5067 104.914 68.508Z"
      fill="#F2F2F2"
    />
    <path
      d="M104.914 46.701H87.0411C86.2612 46.6999 85.5135 46.3896 84.962 45.8381C84.4105 45.2866 84.1002 44.5389 84.0991 43.759V35.312C84.1002 34.532 84.4105 33.7844 84.962 33.2328C85.5135 32.6813 86.2612 32.3711 87.0411 32.37H104.913C105.693 32.3711 106.441 32.6813 106.992 33.2328C107.544 33.7844 107.854 34.532 107.855 35.312V43.763C107.853 44.5421 107.542 45.2886 106.991 45.8392C106.44 46.3898 105.693 46.6997 104.914 46.701Z"
      fill="#F2F2F2"
    />
    <path
      d="M35.1729 44.451C37.8493 44.451 40.0189 42.2814 40.0189 39.605C40.0189 36.9286 37.8493 34.759 35.1729 34.759C32.4965 34.759 30.3269 36.9286 30.3269 39.605C30.3269 42.2814 32.4965 44.451 35.1729 44.451Z"
      fill="white"
    />
    <path
      d="M34.693 41.454C34.5848 41.454 34.4795 41.4189 34.393 41.354H34.388L33.249 40.482C33.1907 40.4438 33.1409 40.3941 33.1027 40.3358C33.0645 40.2774 33.0388 40.2119 33.0272 40.1432C33.0155 40.0745 33.0181 40.0041 33.035 39.9365C33.0518 39.8688 33.0824 39.8054 33.1248 39.7501C33.1673 39.6949 33.2208 39.649 33.2818 39.6154C33.3428 39.5817 33.4101 39.5611 33.4795 39.5547C33.5489 39.5483 33.6189 39.5563 33.6851 39.5782C33.7512 39.6001 33.8121 39.6354 33.864 39.682L34.602 40.248L36.345 37.975C36.4266 37.8685 36.5471 37.7989 36.68 37.7812C36.813 37.7636 36.9475 37.7995 37.054 37.881C37.1602 37.9627 37.2297 38.0832 37.2473 38.2161C37.2649 38.349 37.2292 38.4834 37.148 38.59L35.098 41.264C35.0496 41.3246 34.9879 41.3732 34.9177 41.4062C34.8474 41.4391 34.7706 41.4555 34.693 41.454Z"
      fill="#1AB395"
    />
    <path
      d="M65.6619 44.381C68.3383 44.381 70.5079 42.2114 70.5079 39.535C70.5079 36.8586 68.3383 34.689 65.6619 34.689C62.9855 34.689 60.8159 36.8586 60.8159 39.535C60.8159 42.2114 62.9855 44.381 65.6619 44.381Z"
      fill="white"
    />
    <path
      d="M65.1819 41.384C65.0737 41.384 64.9684 41.3489 64.8818 41.284H64.8768L63.7379 40.412C63.6429 40.3268 63.5837 40.2089 63.572 40.0819C63.5603 39.9549 63.597 39.8282 63.6747 39.727C63.7524 39.6259 63.8655 39.5579 63.9912 39.5366C64.117 39.5152 64.2461 39.5422 64.3528 39.612L65.0909 40.178L66.8338 37.905C66.9154 37.7986 67.0359 37.7289 67.1689 37.7113C67.3018 37.6936 67.4363 37.7295 67.5428 37.811L67.5318 37.826L67.5428 37.812C67.649 37.8938 67.7185 38.0142 67.7361 38.1471C67.7538 38.28 67.7181 38.4144 67.6368 38.521L65.5869 41.195C65.5383 41.2554 65.4766 41.3039 65.4063 41.3366C65.3361 41.3694 65.2593 41.3856 65.1819 41.384Z"
      fill="#1AB395"
    />
    <defs>
      <filter
        id="filter0_d_32_1617"
        x="0"
        y="0"
        width="131"
        height="100"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.078 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1617"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1617"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_32_1617"
        x="31.4199"
        y="10.18"
        width="8.00781"
        height="8.008"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.251 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1617"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1617"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_32_1617"
        x="91.6602"
        y="10.18"
        width="8.00781"
        height="8.008"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.251 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1617"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1617"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
)

export default withIconStyles(IconAttendanceAddOn)
