import { notification } from '@edulastic/common'
import { DEFAULT_TEST_TITLE } from '../TestPage/utils'

export const checkInvalidTestTitle = (title) =>
  !title?.trim() ||
  title?.trim().toLowerCase() === DEFAULT_TEST_TITLE.toLowerCase()

export const validateTestForExpiredItems = ({ itemGroups }) => {
  if (
    itemGroups &&
    itemGroups.length &&
    (itemGroups.some(
      ({ premiumContentRestriction }) => premiumContentRestriction
    ) ||
      itemGroups.some(({ items = [] }) =>
        items.some((item) => item.premiumContentRestriction)
      ))
  ) {
    notification({ type: 'warn', messageKey: 'expiredItemWarning' })
    return false
  }
  return true
}
