/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSparkScience = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="166.985"
    height="33.954"
    viewBox="0 0 166.985 33.954"
    {...props}
  >
    <g transform="translate(-21.051 -24.696)">
      <path
        d="M119.174,79.451a8.038,8.038,0,0,1-1.423,5,4.942,4.942,0,0,1-7.375.405v6.4h-3.032V72.563h2.8l.125,1.373a4.454,4.454,0,0,1,3.631-1.622,4.6,4.6,0,0,1,3.875,1.84,8.253,8.253,0,0,1,1.4,5.11Zm-3.02-.262a5.629,5.629,0,0,0-.8-3.207,2.617,2.617,0,0,0-2.3-1.186,2.791,2.791,0,0,0-2.671,1.535v5.99a2.817,2.817,0,0,0,2.7,1.572,2.614,2.614,0,0,0,2.265-1.167A6.2,6.2,0,0,0,116.154,79.189Z"
        transform="translate(-59.09 -32.606)"
        fill="#637885"
      />
      <path
        d="M160.138,86.065a4.686,4.686,0,0,1-.349-1.261,5.1,5.1,0,0,1-6.864.349,3.712,3.712,0,0,1-1.285-2.87,3.834,3.834,0,0,1,1.6-3.313,7.772,7.772,0,0,1,4.586-1.154h1.859V76.93a2.366,2.366,0,0,0-.586-1.678,2.326,2.326,0,0,0-1.785-.63,2.67,2.67,0,0,0-1.7.518,1.6,1.6,0,0,0-.661,1.316h-3.033a3.365,3.365,0,0,1,.736-2.078,4.905,4.905,0,0,1,2-1.516,7.032,7.032,0,0,1,2.826-.549,5.669,5.669,0,0,1,3.781,1.192,4.251,4.251,0,0,1,1.447,3.351v6.09a6.9,6.9,0,0,0,.512,2.907v.212Zm-3.332-2.184a3.45,3.45,0,0,0,1.691-.437,2.919,2.919,0,0,0,1.192-1.173V79.725h-1.635a4.47,4.47,0,0,0-2.533.587,1.9,1.9,0,0,0-.849,1.66,1.772,1.772,0,0,0,.58,1.391A2.247,2.247,0,0,0,156.806,83.881Z"
        transform="translate(-89.421 -32.606)"
        fill="#637885"
      />
      <path
        d="M203.922,75.333a7.508,7.508,0,0,0-1.235-.1,2.781,2.781,0,0,0-2.808,1.6v9.234h-3.032v-13.5h2.9l.075,1.51a3.416,3.416,0,0,1,3.045-1.759,2.841,2.841,0,0,1,1.073.175Z"
        transform="translate(-120.376 -32.606)"
        fill="#637885"
      />
      <path
        d="M230.977,68.51,229.629,69.9V74.3H226.6V55.133h3.032V66.189L230.578,65l3.731-4.205h3.644l-5.016,5.628,5.553,7.874h-3.506Z"
        transform="translate(-140.749 -20.842)"
        fill="#637885"
      />
      <g transform="translate(21.051 24.696)">
        <g transform="translate(10.988 10.248)">
          <path
            d="M66.327,70.944a2.193,2.193,0,0,0-.842-1.847,10.136,10.136,0,0,0-3.039-1.31,14.657,14.657,0,0,1-3.494-1.473,4.663,4.663,0,0,1-2.483-4.068,4.427,4.427,0,0,1,1.791-3.619A7.246,7.246,0,0,1,62.908,57.2a7.855,7.855,0,0,1,3.382.7,5.554,5.554,0,0,1,2.334,1.99,5.108,5.108,0,0,1,.849,2.864H66.327a2.848,2.848,0,0,0-.892-2.228,3.682,3.682,0,0,0-2.552-.805,3.845,3.845,0,0,0-2.4.662,2.2,2.2,0,0,0-.855,1.847,1.991,1.991,0,0,0,.923,1.666,10.629,10.629,0,0,0,3.045,1.3A14.032,14.032,0,0,1,67,66.632a5.424,5.424,0,0,1,1.884,1.847,4.811,4.811,0,0,1,.6,2.44,4.323,4.323,0,0,1-1.741,3.612,7.551,7.551,0,0,1-4.723,1.341,8.871,8.871,0,0,1-3.625-.73,6.085,6.085,0,0,1-2.571-2.015,5.024,5.024,0,0,1-.917-3h3.157a2.939,2.939,0,0,0,1.023,2.4,4.481,4.481,0,0,0,2.932.848,3.894,3.894,0,0,0,2.477-.667A2.154,2.154,0,0,0,66.327,70.944Z"
            transform="translate(-55.907 -57.204)"
            fill="#637885"
          />
        </g>
        <path
          d="M39.714,47.265a.234.234,0,0,1-.143-.048l-2.3-1.753a.237.237,0,1,1,.287-.376l2.3,1.754a.236.236,0,0,1-.144.424Z"
          transform="translate(-32.095 -38.626)"
          fill="#637885"
        />
        <path
          d="M50.576,55.551a.235.235,0,0,1-.143-.049L47.584,53.33a.237.237,0,0,1,.287-.376l2.849,2.172a.237.237,0,0,1-.144.425Z"
          transform="translate(-39.156 -44.012)"
          fill="#637885"
        />
        <path
          d="M84.457,28.734a.216.216,0,0,1-.045,0,.236.236,0,0,1-.188-.276l.683-3.564a.236.236,0,1,1,.464.089l-.683,3.564A.236.236,0,0,1,84.457,28.734Z"
          transform="translate(-64.307 -24.696)"
          fill="#637885"
        />
        <path
          d="M81.418,44.154a.215.215,0,0,1-.045,0,.236.236,0,0,1-.188-.276l.723-3.774a.236.236,0,0,1,.464.089l-.723,3.774A.236.236,0,0,1,81.418,44.154Z"
          transform="translate(-62.226 -35.112)"
          fill="#637885"
        />
        <path
          d="M26.372,69.725h-.011l-5.084-.236a.237.237,0,0,1-.225-.247.228.228,0,0,1,.247-.225l5.084.236a.236.236,0,0,1-.011.473Z"
          transform="translate(-21.051 -55.044)"
          fill="#637885"
        />
        <path
          d="M43.327,70.511h-.011L41.3,70.417a.236.236,0,0,1-.225-.247.23.23,0,0,1,.247-.225l2.012.093a.236.236,0,0,1-.011.473Z"
          transform="translate(-34.765 -55.68)"
          fill="#637885"
        />
        <path
          d="M35.465,89.5a.236.236,0,0,1-.1-.452l1.3-.578a.236.236,0,1,1,.193.432l-1.3.578A.231.231,0,0,1,35.465,89.5Z"
          transform="translate(-30.759 -68.353)"
          fill="#637885"
        />
        <path
          d="M43.058,83.906a.236.236,0,0,1-.1-.452l3.57-1.594a.236.236,0,0,1,.193.432l-3.57,1.594A.233.233,0,0,1,43.058,83.906Z"
          transform="translate(-35.959 -63.825)"
          fill="#637885"
        />
        <path
          d="M101.626,48.042a.236.236,0,0,1-.16-.41l2.008-1.847a.236.236,0,0,1,.32.348l-2.008,1.847A.236.236,0,0,1,101.626,48.042Z"
          transform="translate(-76.064 -39.094)"
          fill="#637885"
        />
        <path
          d="M95.761,55.506a.237.237,0,0,1-.16-.41l.972-.894a.236.236,0,1,1,.32.348l-.972.894A.235.235,0,0,1,95.761,55.506Z"
          transform="translate(-72.047 -44.858)"
          fill="#637885"
        />
        <path
          d="M63.044,30.663a.236.236,0,0,1-.228-.172l-.8-2.849a.236.236,0,1,1,.455-.128l.8,2.849a.236.236,0,0,1-.228.3Z"
          transform="translate(-49.094 -26.507)"
          fill="#637885"
        />
        <path
          d="M66.63,43.39a.236.236,0,0,1-.227-.172l-.848-3.01a.236.236,0,0,1,.455-.128l.848,3.01a.236.236,0,0,1-.228.3Z"
          transform="translate(-51.519 -35.112)"
          fill="#637885"
        />
        <path
          d="M43.576,32.193a.233.233,0,0,1-.139-.045.236.236,0,0,1-.094-.231l.2-1.147-.833-.812a.237.237,0,0,1,.131-.4l1.151-.167.515-1.043a.237.237,0,0,1,.212-.132h0a.237.237,0,0,1,.212.132l.514,1.043,1.151.167a.237.237,0,0,1,.131.4l-.833.812.2,1.147a.237.237,0,0,1-.343.249l-1.03-.541-1.03.541A.239.239,0,0,1,43.576,32.193Zm-.2-2.239.579.564a.236.236,0,0,1,.068.209l-.137.8.716-.376a.236.236,0,0,1,.22,0l.716.376-.137-.8a.236.236,0,0,1,.068-.209l.579-.564-.8-.116a.236.236,0,0,1-.178-.129l-.357-.725-.357.725a.236.236,0,0,1-.178.129Z"
          transform="translate(-35.831 -27.104)"
          fill="#637885"
        />
        <path
          d="M91.945,37.126a.239.239,0,0,1-.09-.018.236.236,0,0,1-.145-.2l-.077-1.16-1-.595a.237.237,0,0,1,.033-.423l1.08-.431L92,33.16a.236.236,0,0,1,.412-.1l.744.894,1.158-.106a.239.239,0,0,1,.223.112.235.235,0,0,1,0,.25l-.62.984.459,1.068a.237.237,0,0,1-.276.322L92.976,36.3l-.875.767A.235.235,0,0,1,91.945,37.126Zm-.659-2.138.695.414a.235.235,0,0,1,.115.188l.053.806.608-.532a.234.234,0,0,1,.214-.051l.783.2-.319-.743a.238.238,0,0,1,.017-.219l.431-.684-.805.074a.239.239,0,0,1-.2-.084l-.517-.621-.179.789a.237.237,0,0,1-.143.167Zm.663-.519h0Z"
          transform="translate(-68.619 -30.366)"
          fill="#637885"
        />
        <path
          d="M41.934,101.7a.238.238,0,0,1-.158-.061l-.864-.778-1.131.271a.237.237,0,0,1-.271-.326l.473-1.063-.607-.992a.237.237,0,0,1,0-.25.233.233,0,0,1,.225-.109l1.157.121.756-.884a.236.236,0,0,1,.411.1l.243,1.137,1.074.445a.237.237,0,0,1,.028.423l-1.007.582-.091,1.159a.237.237,0,0,1-.236.218Zm-.957-1.334a.237.237,0,0,1,.158.061l.6.54.064-.805a.237.237,0,0,1,.118-.186l.7-.4-.746-.309a.238.238,0,0,1-.141-.169L41.56,98.3l-.525.615a.23.23,0,0,1-.2.081l-.8-.084.422.689a.237.237,0,0,1,.015.22l-.328.738.786-.188A.235.235,0,0,1,40.976,100.366Z"
          transform="translate(-33.574 -74.581)"
          fill="#637885"
        />
      </g>
      <g transform="translate(99.418 34.761)">
        <path
          d="M269.643,70.223H272.8a2.938,2.938,0,0,0,1.023,2.4,4.479,4.479,0,0,0,2.932.849,3.893,3.893,0,0,0,2.477-.668,2.154,2.154,0,0,0,.83-1.765,2.195,2.195,0,0,0-.842-1.847,10.146,10.146,0,0,0-3.038-1.31,14.651,14.651,0,0,1-3.494-1.472,4.662,4.662,0,0,1-2.483-4.068A4.428,4.428,0,0,1,272,58.718a7.248,7.248,0,0,1,4.648-1.422,7.856,7.856,0,0,1,3.382.7,5.556,5.556,0,0,1,2.333,1.99,5.106,5.106,0,0,1,.848,2.864h-3.144a2.848,2.848,0,0,0-.892-2.228,3.681,3.681,0,0,0-2.552-.8,3.846,3.846,0,0,0-2.4.661,2.2,2.2,0,0,0-.855,1.847,1.991,1.991,0,0,0,.923,1.666,10.63,10.63,0,0,0,3.045,1.3,14.013,14.013,0,0,1,3.406,1.435,5.423,5.423,0,0,1,1.884,1.847,4.812,4.812,0,0,1,.6,2.44,4.325,4.325,0,0,1-1.74,3.612,7.553,7.553,0,0,1-4.723,1.341,8.873,8.873,0,0,1-3.625-.73,6.09,6.09,0,0,1-2.57-2.015A5.021,5.021,0,0,1,269.643,70.223Z"
          transform="translate(-269.643 -57.084)"
          fill="#637885"
        />
        <path
          d="M318.764,79.2v-.312a7.156,7.156,0,0,1,1.647-4.935A5.706,5.706,0,0,1,324.9,72.1a5.549,5.549,0,0,1,3.924,1.4,5.03,5.03,0,0,1,1.591,3.687h-2.858a2.775,2.775,0,0,0-.792-1.909,2.55,2.55,0,0,0-1.89-.749,2.669,2.669,0,0,0-2.271,1.067,5.445,5.445,0,0,0-.811,3.238v.486a5.533,5.533,0,0,0,.792,3.276,2.666,2.666,0,0,0,2.29,1.079,2.741,2.741,0,0,0,1.884-.661,2.259,2.259,0,0,0,.8-1.635h2.858a4.456,4.456,0,0,1-.787,2.352,5.153,5.153,0,0,1-2,1.728,5.949,5.949,0,0,1-2.72.636,5.755,5.755,0,0,1-4.492-1.834A7.274,7.274,0,0,1,318.764,79.2Z"
          transform="translate(-303.279 -67.22)"
          fill="#637885"
        />
        <path
          d="M363.017,58.245a1.61,1.61,0,0,1,.443-1.16,1.674,1.674,0,0,1,1.267-.462,1.694,1.694,0,0,1,1.273.462,1.6,1.6,0,0,1,.45,1.16,1.559,1.559,0,0,1-.45,1.141,1.706,1.706,0,0,1-1.273.456,1.685,1.685,0,0,1-1.267-.456A1.572,1.572,0,0,1,363.017,58.245Zm3.219,3.506v13.5H363.2v-13.5Z"
          transform="translate(-333.581 -56.623)"
          fill="#637885"
        />
        <path
          d="M382.055,79.448v-.375a8.1,8.1,0,0,1,.78-3.612,5.933,5.933,0,0,1,2.19-2.477,5.789,5.789,0,0,1,3.144-.886,5.3,5.3,0,0,1,4.261,1.759,7.44,7.44,0,0,1,1.5,4.979v1.223h-8.822a4.053,4.053,0,0,0,1.117,2.645,3.353,3.353,0,0,0,2.465.973,4.1,4.1,0,0,0,3.394-1.685l1.635,1.56a5.452,5.452,0,0,1-2.165,1.878,6.777,6.777,0,0,1-3.038.668,6.271,6.271,0,0,1-4.673-1.815A6.585,6.585,0,0,1,382.055,79.448Zm3.107-1.61h5.778v-.225a3.686,3.686,0,0,0-.812-2.3,2.819,2.819,0,0,0-3.987.094A4.529,4.529,0,0,0,385.162,77.838Z"
          transform="translate(-346.618 -67.22)"
          fill="#637885"
        />
        <path
          d="M427.06,72.348h2.857l.088,1.56a4.854,4.854,0,0,1,3.93-1.81q4.217,0,4.293,4.829v8.921H435.2V77.1a2.781,2.781,0,0,0-.555-1.9,2.336,2.336,0,0,0-1.815-.618,2.922,2.922,0,0,0-2.733,1.66v9.608H427.06Z"
          transform="translate(-377.435 -67.22)"
          fill="#637885"
        />
        <path
          d="M470.48,79.2v-.312a7.157,7.157,0,0,1,1.647-4.935,5.706,5.706,0,0,1,4.492-1.853,5.55,5.55,0,0,1,3.924,1.4,5.028,5.028,0,0,1,1.591,3.687h-2.857a2.779,2.779,0,0,0-.793-1.909,2.55,2.55,0,0,0-1.89-.749,2.668,2.668,0,0,0-2.271,1.067,5.44,5.44,0,0,0-.811,3.238v.486A5.531,5.531,0,0,0,474.3,82.6a2.665,2.665,0,0,0,2.289,1.079,2.74,2.74,0,0,0,1.884-.661,2.261,2.261,0,0,0,.8-1.635h2.857a4.453,4.453,0,0,1-.786,2.352,5.154,5.154,0,0,1-2,1.728,5.948,5.948,0,0,1-2.72.636,5.756,5.756,0,0,1-4.492-1.834A7.276,7.276,0,0,1,470.48,79.2Z"
          transform="translate(-407.167 -67.22)"
          fill="#637885"
        />
        <path
          d="M513.07,79.448v-.375a8.1,8.1,0,0,1,.78-3.612,5.938,5.938,0,0,1,2.19-2.477,5.791,5.791,0,0,1,3.144-.886,5.3,5.3,0,0,1,4.261,1.759,7.442,7.442,0,0,1,1.5,4.979v1.223h-8.821a4.053,4.053,0,0,0,1.117,2.645,3.354,3.354,0,0,0,2.465.973,4.106,4.106,0,0,0,3.394-1.685l1.635,1.56a5.457,5.457,0,0,1-2.165,1.878,6.778,6.778,0,0,1-3.039.668,6.273,6.273,0,0,1-4.673-1.815A6.586,6.586,0,0,1,513.07,79.448Zm3.107-1.61h5.777v-.225a3.685,3.685,0,0,0-.811-2.3,2.819,2.819,0,0,0-3.987.094A4.528,4.528,0,0,0,516.177,77.838Z"
          transform="translate(-436.331 -67.22)"
          fill="#637885"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconSparkScience)
