/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconOpenBook = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="47.813"
    height="34.385"
    viewBox="0 0 47.813 34.385"
    {...props}
  >
    <g transform="translate(0.125 -58.178)">
      <g transform="translate(0 58.303)">
        <path
          d="M46.641,61.143H43.506V60.074a.922.922,0,0,0-.664-.922A25.969,25.969,0,0,0,36.2,58.3c-3.688,0-8.712.83-12.428,4.61C20.08,59.1,15.018,58.3,11.34,58.3a25.969,25.969,0,0,0-6.647.848.922.922,0,0,0-.664.922v1.06H.922A.922.922,0,0,0,0,62.056V91.5a.92.92,0,0,0,1.374.8c.092-.046,9.312-5.089,22.182-1.844h.221a.924.924,0,0,0,.221,0C36.9,87.188,46.124,92.221,46.18,92.3a.922.922,0,0,0,1.383-.793V62.065A.922.922,0,0,0,46.641,61.143ZM1.844,90.036V62.987h2.2V86.109a.922.922,0,0,0,1.06.922,40.345,40.345,0,0,1,5.762-.461,23.914,23.914,0,0,1,7.873,1.189A33.892,33.892,0,0,0,1.844,90.036Zm9.026-5.3a40.564,40.564,0,0,0-5.015.323V60.8a25.428,25.428,0,0,1,5.467-.608c3.448,0,8.205.793,11.506,4.517l.028,22.965C20.937,86.422,17.194,84.735,10.87,84.735ZM24.7,64.711c3.3-3.688,8.058-4.517,11.506-4.517a25.539,25.539,0,0,1,5.467.608V85.058a40.551,40.551,0,0,0-4.988-.332c-6.288,0-10.04,1.687-11.985,2.941ZM45.709,90.036a33.9,33.9,0,0,0-16.936-2.277,23.971,23.971,0,0,1,7.91-1.189,40.334,40.334,0,0,1,5.8.452.922.922,0,0,0,1.06-.922V62.987h2.2Z"
          transform="translate(0 -58.303)"
          fill="#637885"
          stroke="#f5f5f5"
          strokeWidth="0.25"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconOpenBook)
