/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconGaolsAndInterventionsAddOn = (props) => (
  <SVG
    width="109"
    height="81"
    viewBox="0 0 109 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.9604 40.007C22.8147 39.8735 22.6999 39.7096 22.6242 39.527C22.5485 39.3444 22.5137 39.1475 22.5221 38.95C22.5306 38.7525 22.5822 38.5593 22.6732 38.3838C22.7643 38.2084 22.8926 38.055 23.0492 37.9343L21.5322 33.3636L23.9494 34.013L25.0203 38.2571C25.2048 38.5347 25.2812 38.8702 25.2351 39.2003C25.1889 39.5304 25.0234 39.8322 24.7699 40.0485C24.5164 40.2649 24.1923 40.3808 23.8591 40.3745C23.5258 40.3681 23.2065 40.2398 22.9614 40.0139L22.9604 40.007Z"
      fill="#A0616A"
    />
    <path
      d="M27.3414 15.1723C27.7422 15.4022 30.1149 14.8327 30.5788 14.9946C30.3232 16.555 31.7119 15.9698 30.2038 18.0197C28.0859 21.3186 26.3297 24.8362 24.9658 28.5115C24.2749 30.8082 24.9589 34.0456 24.8552 35.1343C24.8051 35.7651 24.8081 36.399 24.8641 37.0293C24.0503 37.095 23.2332 37.1118 22.4173 37.0796C21.6241 34.4486 20.9978 31.7702 20.542 29.0603C20.1394 27.0951 20.1245 27.9262 20.3091 26.9432C20.4937 25.9601 20.5391 26.6747 20.3446 25.7341C20.3384 25.1989 20.4536 24.6693 20.6817 24.1852C20.9097 23.701 21.2447 23.2749 21.6613 22.9389C21.9742 22.0388 22.3788 21.1998 22.6888 20.3096C24.9549 18.3287 25.1039 17.1847 27.3414 15.1723Z"
      fill="#1AB395"
    />
    <path
      d="M93.296 78.8276H91.2903L90.3359 71.0915H93.2969L93.296 78.8276Z"
      fill="#FFB6B6"
    />
    <path
      d="M93.8066 80.771H87.3398V80.6891C87.3398 80.0216 87.605 79.3814 88.077 78.9094C88.549 78.4374 89.1892 78.1722 89.8567 78.1722L91.0411 77.2761L93.245 78.1722H93.8096L93.8066 80.771Z"
      fill="#2F2E41"
    />
    <path
      d="M83.9786 78.8276H81.973L81.0186 71.0915H83.9795L83.9786 78.8276Z"
      fill="#FFB6B6"
    />
    <path
      d="M84.4937 80.771H78.0269V80.6891C78.0269 80.0216 78.292 79.3814 78.764 78.9094C79.236 78.4374 79.8762 78.1722 80.5437 78.1722L81.7281 77.2761L83.932 78.1722H84.4966L84.4937 80.771Z"
      fill="#2F2E41"
    />
    <path
      d="M83.1095 36.5338L96.1546 36.8595C98.632 38.2048 98.6695 41.1174 97.8582 44.5759C97.8582 44.5759 98.1326 46.6308 97.5838 47.1796C97.035 47.7283 96.7616 47.7273 97.036 48.6867C97.3104 49.646 95.8625 51.2124 96.0382 51.3881C96.2677 51.748 96.4643 52.1279 96.6255 52.5232L95.2555 60.3333C95.2555 60.3333 94.2961 73.8985 94.0227 74.1729C93.7493 74.4473 93.4749 74.1729 93.7483 74.8579C94.0217 75.5429 94.2961 75.2695 94.0227 75.5429C93.7801 75.8046 93.5514 76.0791 93.3377 76.365H90.0244C90.0244 76.365 89.7747 74.9951 89.7747 74.8579C89.7747 74.7207 89.5003 73.8985 89.5003 73.7613C89.5003 73.6241 89.7422 73.3823 89.7422 73.3823C89.8258 73.1039 89.8823 72.8179 89.9109 72.5286C89.9109 72.2542 90.7331 61.7032 90.7331 61.7032L89.0887 48.0007L85.1151 61.4288C85.1151 61.4288 85.1151 73.2126 84.8407 73.487C84.5664 73.7613 84.5663 73.6241 84.7035 74.1719C84.8407 74.7197 85.3885 74.5835 84.9779 74.9941C84.5673 75.4047 84.4301 74.7197 84.5663 75.4047L84.7035 76.0897L80.5927 76.1489C80.5927 76.1489 80.0449 74.9941 80.3183 74.5825C80.5917 74.1709 80.5759 74.2864 80.2413 73.6113C79.9067 72.9362 79.7695 72.802 79.9067 72.6648C80.0439 72.5276 80.0439 71.7992 80.0439 71.7992L80.7289 58.6889C80.7289 58.6889 80.8661 44.8493 80.8661 44.4387C80.8766 44.227 80.8377 44.0158 80.7526 43.8218V43.2641L81.2776 41.2901L83.1095 36.5338Z"
      fill="#2F2E41"
    />
    <path
      d="M90.9462 9.40917C93.1849 9.40917 94.9998 7.59432 94.9998 5.35558C94.9998 3.11684 93.1849 1.30199 90.9462 1.30199C88.7074 1.30199 86.8926 3.11684 86.8926 5.35558C86.8926 7.59432 88.7074 9.40917 90.9462 9.40917Z"
      fill="#FFB6B6"
    />
    <path
      d="M88.9204 12.0869C88.0749 12.6663 87.4715 13.5359 87.2247 14.5307C86.6696 16.3278 86.3355 18.1859 86.2299 20.0638L85.913 25.6897L81.9907 37.66C85.3899 40.5361 98.1686 38.3134 98.1686 38.3134C98.1686 38.3134 98.5634 38.1831 98.1686 37.7903C97.7738 37.3974 97.3947 37.7458 97.7866 37.353C98.1784 36.9602 97.908 37.3974 97.7777 37.0056C97.6474 36.6138 97.7777 36.8753 97.908 36.744C98.0383 36.6128 96.8954 35.4363 96.8954 35.4363L97.9416 28.5648L99.2493 14.7054C97.9309 13.3218 96.3153 12.256 94.5246 11.5885L93.7093 10.1208L89.633 10.4465L88.9204 12.0869Z"
      fill="#3F3D56"
    />
    <path
      d="M88.3642 2.97594C88.5211 2.97721 88.6772 2.99776 88.8291 3.03713C89.1285 3.10925 89.4326 3.16037 89.7391 3.19011C90.6774 3.27846 91.559 3.67869 92.2432 4.32688C92.9273 4.97507 93.3746 5.83383 93.5134 6.766C93.7493 6.56071 94.2852 6.18268 94.7245 6.53406C94.7314 6.53998 94.7353 6.54294 94.7501 6.53406C95.0462 6.39588 95.5595 4.69232 95.5753 3.19208C95.5831 2.39558 95.4578 1.37996 94.8143 1.05722L94.7807 1.04042L94.7718 1.00392C94.7284 0.825272 94.2477 0.519293 93.4127 0.281427C91.8947 -0.150877 89.6769 -0.212073 88.4027 1.02562C88.304 1.44312 87.9477 1.55761 87.6309 1.65927C87.2795 1.77179 86.9765 1.86852 86.9292 2.29885C86.8894 2.91124 86.8923 3.52565 86.938 4.13762C87.0566 3.76287 87.2901 3.43487 87.6052 3.19998C87.8293 3.04923 88.0942 2.9707 88.3642 2.97494V2.97594Z"
      fill="#2F2E41"
    />
    <path
      d="M80.8199 25.3719L87.4407 21.1278L84.1056 16.6952L80.813 20.7419L70.5483 21.3716C70.2784 21.1048 69.9368 20.9223 69.5651 20.8463C69.1933 20.7703 68.8074 20.8041 68.4546 20.9436C68.1017 21.0832 67.7971 21.3223 67.5778 21.632C67.3585 21.9417 67.2341 22.3084 67.2197 22.6876C67.2053 23.0668 67.3015 23.442 67.4966 23.7674C67.6917 24.0928 67.9773 24.3544 68.3186 24.5203C68.6599 24.6862 69.042 24.7493 69.4184 24.7017C69.7949 24.6542 70.1494 24.4982 70.4387 24.2526L80.8199 25.3719Z"
      fill="#FFB6B6"
    />
    <path
      d="M93.5858 15.2127C93.9698 17.5381 86.7134 21.9796 86.7134 21.9796C86.7134 21.4338 82.3824 25.5545 82.187 25.1014C81.6313 23.8183 81.2405 20.7675 80.4647 20.28C80.0206 20.0006 83.1385 17.3802 83.1385 17.3802C83.1385 17.3802 84.7709 15.7358 86.9127 13.6622C87.5073 13.0645 88.2435 12.627 89.0527 12.3904C89.8619 12.1538 90.7178 12.1258 91.5408 12.309C92.1059 12.5717 92.5923 12.9781 92.9511 13.4877C93.31 13.9973 93.5289 14.5921 93.5858 15.2127Z"
      fill="#3F3D56"
    />
    <g filter="url(#filter0_d_32_1697)">
      <path
        d="M86.2162 41.9159C86.2192 44.4048 85.8024 46.8763 84.9834 49.2266C84.9459 49.3362 84.9065 49.4437 84.868 49.5523C83.1906 54.1053 80.0713 57.9854 75.985 60.6017C75.7057 60.7814 75.4244 60.9521 75.1372 61.1189C71.8737 63.0107 68.1793 64.0336 64.4075 64.0898C64.2832 64.0898 64.1598 64.0898 64.0354 64.0898C63.8755 64.0898 63.7176 64.0898 63.5597 64.0898C59.7828 64.0145 56.0884 62.9712 52.8301 61.0597C52.5432 60.8919 52.2606 60.7175 51.9823 60.5366C47.4472 57.5966 44.1405 53.1031 42.6824 47.8989C41.2244 42.6946 41.7151 37.1372 44.0626 32.269C44.1149 32.1595 44.1682 32.0519 44.2245 31.9433C46.0733 28.2744 48.9045 25.1909 52.4026 23.0364C55.9008 20.8819 59.9285 19.7412 64.0369 19.7412C68.1453 19.7412 72.173 20.8819 75.6712 23.0364C79.1694 25.1909 82.0006 28.2744 83.8494 31.9433C83.9047 32.0528 83.959 32.1604 84.0113 32.269C85.4666 35.2764 86.2205 38.5749 86.2162 41.9159Z"
        fill="#F2F2F2"
      />
    </g>
    <path
      d="M79.3457 49.2256V49.5513H81.3315V49.2256H79.3457ZM75.3731 49.2256V49.5513H77.3589V49.2256H75.3731ZM71.4004 49.2256V49.5513H73.3862V49.2256H71.4004ZM67.4277 49.2256V49.5513H69.4135V49.2256H67.4277ZM63.4551 49.2256V49.5513H65.4409V49.2256H63.4551ZM59.4824 49.2256V49.5513H61.4682V49.2256H59.4824ZM55.5098 49.2256V49.5513H57.4956V49.2256H55.5098ZM51.5391 49.2256V49.5513H53.5239V49.2256H51.5391ZM47.5664 49.2256V49.5513H49.5522V49.2256H47.5664ZM43.5938 49.2256V49.5513H45.5795V49.2256H43.5938ZM84.9716 49.2256H83.3144V49.5513H84.8551L84.9716 49.2256Z"
      fill="#E6E6E6"
    />
    <path
      d="M83.3184 46.9427V47.2684H85.3042V46.9427H83.3184ZM79.3457 46.9427V47.2684H81.3315V46.9427H79.3457ZM75.3731 46.9427V47.2684H77.3589V46.9427H75.3731ZM71.4004 46.9427V47.2684H73.3862V46.9427H71.4004ZM67.4277 46.9427V47.2684H69.4135V46.9427H67.4277ZM63.4551 46.9427V47.2684H65.4409V46.9427H63.4551ZM59.4824 46.9427V47.2684H61.4682V46.9427H59.4824ZM55.5098 46.9427V47.2684H57.4956V46.9427H55.5098ZM51.5391 46.9427V47.2684H53.5239V46.9427H51.5391ZM47.5664 46.9427V47.2684H49.5522V46.9427H47.5664ZM43.5938 46.9427V47.2684H45.5796V46.9427H43.5938Z"
      fill="#E6E6E6"
    />
    <path
      d="M83.3184 42.214V42.5397H85.3042V42.214H83.3184ZM79.3457 42.214V42.5397H81.3315V42.214H79.3457ZM75.3731 42.214V42.5397H77.3589V42.214H75.3731ZM71.4004 42.214V42.5397H73.3862V42.214H71.4004ZM67.4277 42.214V42.5397H69.4135V42.214H67.4277ZM63.4551 42.214V42.5397H65.4409V42.214H63.4551ZM59.4824 42.214V42.5397H61.4682V42.214H59.4824ZM55.5098 42.214V42.5397H57.4956V42.214H55.5098ZM51.5391 42.214V42.5397H53.5239V42.214H51.5391ZM47.5664 42.214V42.5397H49.5522V42.214H47.5664ZM43.5938 42.214V42.5397H45.5796V42.214H43.5938Z"
      fill="#E6E6E6"
    />
    <path
      d="M83.3184 38.9529V39.2786H85.3042V38.9529H83.3184ZM79.3457 38.9529V39.2786H81.3315V38.9529H79.3457ZM75.3731 38.9529V39.2786H77.3589V38.9529H75.3731ZM71.4004 38.9529V39.2786H73.3862V38.9529H71.4004ZM67.4277 38.9529V39.2786H69.4135V38.9529H67.4277ZM63.4551 38.9529V39.2786H65.4409V38.9529H63.4551ZM59.4824 38.9529V39.2786H61.4682V38.9529H59.4824ZM55.5098 38.9529V39.2786H57.4956V38.9529H55.5098ZM51.5391 38.9529V39.2786H53.5239V38.9529H51.5391ZM47.5664 38.9529V39.2786H49.5522V38.9529H47.5664ZM43.5938 38.9529V39.2786H45.5796V38.9529H43.5938Z"
      fill="#E6E6E6"
    />
    <path
      d="M83.843 31.9413H83.3179V32.267H84.0089C83.9526 32.1575 83.8983 32.0499 83.843 31.9413ZM79.3453 31.9413V32.267H81.3311V31.9413H79.3453ZM75.3726 31.9413V32.267H77.3584V31.9413H75.3726ZM71.4 31.9413V32.267H73.3858V31.9413H71.4ZM67.4273 31.9413V32.267H69.4131V31.9413H67.4273ZM63.4546 31.9413V32.267H65.4404V31.9413H63.4546ZM59.482 31.9413V32.267H61.4678V31.9413H59.482ZM55.5093 31.9413V32.267H57.4951V31.9413H55.5093ZM51.5386 31.9413V32.267H53.5235V31.9413H51.5386ZM47.566 31.9413V32.267H49.5518V31.9413H47.566ZM44.2101 31.9413C44.1549 32.0509 44.1007 32.1585 44.0483 32.267H45.585V31.9413H44.2101Z"
      fill="#E6E6E6"
    />
    <path
      d="M75.9797 33.0389V60.6007C75.7013 60.7804 75.4191 60.9511 75.1318 61.1179V33.0389H75.9797Z"
      fill="#3F3D56"
    />
    <path
      d="M64.403 42.1706V64.0878C64.2787 64.0878 64.1553 64.0878 64.0309 64.0878C63.871 64.0878 63.7131 64.0878 63.5552 64.0878V42.1765L64.403 42.1706Z"
      fill="#3F3D56"
    />
    <path
      d="M52.8259 33.0389V61.0567C52.539 60.8889 52.2564 60.7146 51.978 60.5336V33.0389H52.8259Z"
      fill="#3F3D56"
    />
    <path
      d="M75.4458 35.229C77.1569 35.229 78.544 33.8419 78.544 32.1308C78.544 30.4198 77.1569 29.0327 75.4458 29.0327C73.7348 29.0327 72.3477 30.4198 72.3477 32.1308C72.3477 33.8419 73.7348 35.229 75.4458 35.229Z"
      fill="#1AB395"
    />
    <path
      d="M77.0358 30.8438C76.9892 30.7995 76.9274 30.7748 76.8631 30.7748C76.7988 30.7748 76.737 30.7995 76.6904 30.8438C76.0331 31.368 75.4427 31.9709 74.9325 32.6391L74.5565 31.9117C74.4124 31.6324 73.9899 31.8791 74.134 32.1584L74.6838 33.2224C74.7059 33.2586 74.737 33.2884 74.774 33.3091C74.811 33.3298 74.8527 33.3407 74.8951 33.3407C74.9374 33.3407 74.9791 33.3298 75.0161 33.3091C75.0531 33.2884 75.0841 33.2586 75.1063 33.2224C75.6501 32.4566 76.3002 31.772 77.0368 31.1892C77.0821 31.1431 77.1074 31.081 77.1072 31.0163C77.1071 30.9516 77.0814 30.8896 77.0358 30.8438Z"
      fill="white"
    />
    <path
      d="M64.0313 44.3607C65.7424 44.3607 67.1295 42.9736 67.1295 41.2625C67.1295 39.5514 65.7424 38.1643 64.0313 38.1643C62.3202 38.1643 60.9331 39.5514 60.9331 41.2625C60.9331 42.9736 62.3202 44.3607 64.0313 44.3607Z"
      fill="#1AB395"
    />
    <path
      d="M65.6208 39.9755C65.5742 39.9312 65.5123 39.9065 65.448 39.9065C65.3838 39.9065 65.3219 39.9312 65.2753 39.9755C64.6181 40.4997 64.0277 41.1026 63.5175 41.7708L63.1415 41.0434C62.9974 40.7641 62.5749 41.0108 62.719 41.2902L63.2688 42.3541C63.2909 42.3903 63.322 42.4201 63.3589 42.4408C63.3959 42.4615 63.4376 42.4724 63.48 42.4724C63.5224 42.4724 63.5641 42.4615 63.601 42.4408C63.638 42.4201 63.6691 42.3903 63.6912 42.3541C64.2351 41.5883 64.8851 40.9037 65.6218 40.3209C65.6671 40.2748 65.6924 40.2127 65.6922 40.148C65.692 40.0833 65.6663 40.0213 65.6208 39.9755Z"
      fill="white"
    />
    <path
      d="M52.4536 35.8814C54.1647 35.8814 55.5518 34.4943 55.5518 32.7832C55.5518 31.0721 54.1647 29.685 52.4536 29.685C50.7426 29.685 49.3555 31.0721 49.3555 32.7832C49.3555 34.4943 50.7426 35.8814 52.4536 35.8814Z"
      fill="#1AB395"
    />
    <path
      d="M54.0437 31.4962C53.9971 31.4519 53.9352 31.4272 53.8709 31.4272C53.8067 31.4272 53.7448 31.4519 53.6982 31.4962C53.041 32.0204 52.4506 32.6233 51.9404 33.2915L51.5644 32.5641C51.4193 32.2848 50.9978 32.5315 51.1419 32.8108L51.6917 33.8748C51.7138 33.911 51.7449 33.9408 51.7818 33.9615C51.8188 33.9822 51.8605 33.9931 51.9029 33.9931C51.9453 33.9931 51.987 33.9822 52.0239 33.9615C52.0609 33.9408 52.092 33.911 52.1141 33.8748C52.658 33.109 53.308 32.4244 54.0447 31.8416C54.09 31.7955 54.1153 31.7334 54.1151 31.6687C54.1149 31.604 54.0892 31.542 54.0437 31.4962Z"
      fill="white"
    />
    <path
      d="M96.8852 30.5763L98.9253 22.9833L93.4751 21.9519L93.7712 27.1643L86.6096 34.5381C86.2305 34.5227 85.8551 34.6178 85.5291 34.812C85.2032 35.0062 84.9408 35.2911 84.7739 35.6319C84.6071 35.9726 84.543 36.3545 84.5894 36.7311C84.6359 37.1077 84.7908 37.4626 85.0355 37.7526C85.2802 38.0426 85.604 38.2551 85.9673 38.3643C86.3307 38.4734 86.718 38.4745 87.082 38.3674C87.4459 38.2603 87.7709 38.0496 88.0172 37.761C88.2635 37.4724 88.4205 37.1184 88.4691 36.7421L96.8852 30.5763Z"
      fill="#FFB6B6"
    />
    <path
      d="M99.483 14.4734C101.334 15.9342 98.959 24.1036 98.959 24.1036C98.5918 23.6999 98.1644 29.6633 97.7143 29.46C96.4371 28.8826 94.096 26.8938 93.1939 27.0557C92.6777 27.1485 93.2185 23.1077 93.2185 23.1077C93.2185 23.1077 93.3173 20.7932 93.5048 17.8174C93.5421 16.9751 93.7918 16.1559 94.2308 15.4361C94.6699 14.7163 95.2838 14.1193 96.0157 13.7006C96.6107 13.5146 97.2441 13.4881 97.8526 13.6237C98.4611 13.7593 99.0233 14.0523 99.483 14.4734Z"
      fill="#3F3D56"
    />
    <path
      d="M35.5818 31.5209L37.8243 41.528L23.4556 40.6338L27.3611 30.8063L35.5818 31.5209Z"
      fill="#A0616A"
    />
    <path
      d="M33.1885 77.7064H34.8022L35.5701 71.4804H33.1875L33.1885 77.7064Z"
      fill="#A0616A"
    />
    <path
      d="M32.6406 76.9099L35.192 76.7579V77.8514L37.618 79.5293C37.7376 79.6122 37.8277 79.731 37.8751 79.8686C37.9225 80.0061 37.9247 80.1553 37.8814 80.2942C37.8381 80.4331 37.7516 80.5546 37.6344 80.6409C37.5173 80.7272 37.3756 80.7738 37.2301 80.7739H34.1921L33.669 79.6883L33.4647 80.7739H32.3198L32.6406 76.9099Z"
      fill="#2F2E41"
    />
    <path
      d="M17.9336 76.9019L19.5128 77.2445L21.5855 71.3225L19.2572 70.8171L17.9336 76.9019Z"
      fill="#A0616A"
    />
    <path
      d="M17.5686 76.0087L20.0944 76.4035L19.8624 77.4715L21.8779 79.6231C21.9773 79.7294 22.0402 79.8647 22.0573 80.0093C22.0745 80.1539 22.0449 80.3001 21.9731 80.4267C21.9012 80.5533 21.7908 80.6537 21.6579 80.7132C21.525 80.7726 21.3766 80.788 21.2343 80.7571L18.2655 80.1136L17.9832 78.946L17.5538 79.9597L16.4346 79.7169L17.5686 76.0087Z"
      fill="#2F2E41"
    />
    <path
      d="M38.1905 49.8967C37.0575 56.1227 36.7742 58.8103 36.7742 58.8103C36.7742 58.8103 37.624 59.7973 37.0574 60.3668C36.4909 60.9363 37.0574 62.0644 37.0574 62.0644L35.7842 75.7896L34.7587 75.6238L32.2912 75.229L31.4029 75.0849L31.9695 62.2095C31.9695 62.2095 31.403 61.0764 31.6862 60.7932C31.9695 60.5099 31.4029 58.8123 31.4029 58.8123L30.6953 45.3704L26.3031 60.0825C26.3031 60.0825 26.7285 60.6491 26.3031 61.0695C25.7933 61.8731 25.367 62.7266 25.0309 63.617L22.4223 72.7457L22.2012 73.5205L20.8401 73.2658L19.3596 72.9875L19.0872 72.9372L18.6026 72.8454L18.2907 72.7871L18.1851 72.7684L17.6729 72.6697L17.8278 71.9788L17.9087 71.6166L20.1156 61.8137L20.166 61.5877L20.2203 61.3488C20.1429 61.1641 20.1152 60.9623 20.14 60.7635C20.1647 60.5647 20.2411 60.3759 20.3614 60.2158C20.485 60.0864 20.5753 59.9291 20.6246 59.7572C20.6739 59.5853 20.6808 59.4039 20.6447 59.2288C20.6306 59.214 20.6177 59.1982 20.6062 59.1814C20.5572 59.1124 20.5235 59.0336 20.5075 58.9505C20.4879 58.8176 20.5035 58.6819 20.5529 58.557C20.6023 58.4321 20.6836 58.3224 20.7888 58.2388C20.8682 58.1528 20.9347 58.0558 20.9862 57.9506C21.313 57.136 21.5307 56.2818 21.6337 55.4101C21.6337 55.4101 22.0166 41.7392 23.2691 39.6507C23.3352 39.5412 24.6657 37.4458 24.7358 37.4033C26.1501 36.5555 37.1719 37.7271 37.1719 37.7271C37.1719 37.7271 37.8391 39.5204 37.8697 39.707C38.6694 43.0463 38.7786 46.5137 38.1905 49.8967Z"
      fill="#2F2E41"
    />
    <path
      d="M34.8429 16.9666C34.8429 16.9666 35.0818 15.7289 32.79 13.4381C31.4566 13.1488 30.0767 13.1488 28.7433 13.4381C25.3737 17.8855 25.9797 17.1008 25.1003 19.5861C24.8688 20.145 24.7862 20.7544 24.8605 21.3548C25.3859 25.6494 25.6949 29.9677 25.7862 34.2933C25.651 34.8322 24.7084 35.5063 25.651 35.5063C26.5936 35.5063 38.7603 35.2931 38.4909 34.8895C38.2214 34.4858 34.8429 16.9666 34.8429 16.9666Z"
      fill="#1AB395"
    />
    <path
      d="M49.2913 33.4109C49.1066 33.4824 48.9085 33.5126 48.7108 33.4992C48.5132 33.4858 48.3209 33.4293 48.1475 33.3336C47.9741 33.2379 47.8238 33.1054 47.7071 32.9453C47.5904 32.7852 47.5103 32.6016 47.4723 32.4072L42.7041 31.7301L44.3622 29.8548L48.636 30.7855C48.9664 30.7443 49.3006 30.8255 49.5753 31.0138C49.85 31.202 50.0463 31.4844 50.1271 31.8075C50.2078 32.1306 50.1675 32.4721 50.0137 32.7675C49.8599 33.0629 49.6023 33.2918 49.2913 33.4109Z"
      fill="#A0616A"
    />
    <path
      d="M29.1182 16.1711C29.5317 15.9704 29.9245 15.7294 30.2907 15.4516C31.5738 16.3754 31.6725 14.8712 32.8322 17.1344C34.8427 20.4999 37.21 23.6389 39.8932 26.4971C41.6422 28.1384 44.845 28.9645 45.7737 29.5459C46.3162 29.8717 46.8851 30.1513 47.4743 30.3819C47.1709 31.1397 46.8221 31.8787 46.4301 32.5947C43.7205 32.1344 41.0428 31.5034 38.4127 30.7056C36.4733 30.1914 37.2115 30.5743 36.414 29.9713C35.6165 29.3682 36.2759 29.6456 35.3471 29.4008C34.4183 29.156 33.7107 27.9262 33.4314 26.9767C32.7651 26.2957 32.1936 25.5604 31.5343 24.8863C30.7714 21.9697 29.9236 19.0689 29.1182 16.1711Z"
      fill="#1AB395"
    />
    <path
      d="M32.1282 11.7118C34.2835 11.7118 36.0308 9.9646 36.0308 7.80926C36.0308 5.65392 34.2835 3.90668 32.1282 3.90668C29.9728 3.90668 28.2256 5.65392 28.2256 7.80926C28.2256 9.9646 29.9728 11.7118 32.1282 11.7118Z"
      fill="#A0616A"
    />
    <path
      d="M34.7365 14.9433C34.5519 14.6788 33.1681 12.0593 32.7575 11.0693C32.7152 11.2839 32.6885 11.5013 32.6776 11.7197V11.8007L32.5967 11.8115C32.4753 11.8273 29.6051 12.1757 28.1108 10.4821C27.2225 9.47828 27.0251 7.97409 27.5136 6.00799C27.4246 5.99 27.3395 5.95661 27.262 5.9093C27.1328 5.81984 27.0248 5.70327 26.9454 5.56775C26.8659 5.43223 26.817 5.281 26.802 5.12463L26.7191 5.14635C26.4613 5.03603 26.2307 4.87078 26.0433 4.66214C25.8559 4.45349 25.7163 4.20648 25.6342 3.93832C25.5521 3.67017 25.5296 3.38732 25.5681 3.10954C25.6066 2.83175 25.7052 2.56573 25.8571 2.33C26.009 2.09427 26.2105 1.89453 26.4476 1.74471C26.6846 1.59488 26.9515 1.49858 27.2296 1.46253C27.5077 1.42649 27.7904 1.45157 28.0578 1.53601C28.3252 1.62045 28.571 1.76222 28.778 1.95144C28.9908 1.90382 29.2126 1.91647 29.4185 1.98796C29.6177 2.06836 29.7912 2.20139 29.9205 2.37281C30.0499 2.54423 30.1302 2.7476 30.1529 2.96115C30.7364 2.66705 31.3791 2.5091 32.0324 2.49918C32.6858 2.48927 33.333 2.62763 33.9252 2.90389C34.0781 2.97447 34.2123 3.08005 34.3169 3.21207C34.4215 3.34409 34.4935 3.49886 34.5273 3.66388C35.1184 3.73827 35.6608 4.02941 36.0496 4.48089C36.4383 4.93237 36.6457 5.51206 36.6315 6.10768V6.20638H36.5328C36.1248 6.21502 35.7268 6.33448 35.3815 6.55199C35.0362 6.7695 34.7566 7.07684 34.5727 7.44111C34.3229 7.8914 34.1992 8.40074 34.2147 8.91543C34.2301 9.43012 34.3841 9.9311 34.6605 10.3656C34.961 10.8266 35.1662 11.3432 35.264 11.8847C35.3618 12.4263 35.3502 12.982 35.23 13.519L34.8638 15.1239L34.7365 14.9433Z"
      fill="#2F2E41"
    />
    <path
      d="M46.0599 80.8026C46.0599 80.8549 46.0391 80.9051 46.0021 80.9422C45.9651 80.9792 45.9148 81 45.8625 81H0.160634C0.115464 80.9914 0.074687 80.9673 0.0453618 80.9319C0.0160366 80.8965 0 80.852 0 80.806C0 80.76 0.0160366 80.7155 0.0453618 80.6801C0.074687 80.6447 0.115464 80.6207 0.160634 80.6121H45.8665C45.9188 80.6121 45.969 80.6328 46.0061 80.6699C46.0431 80.7069 46.0639 80.7571 46.0639 80.8094L46.0599 80.8026Z"
      fill="#CCCCCC"
    />
    <path
      d="M108.351 80.8026C108.351 80.8549 108.33 80.9051 108.293 80.9422C108.256 80.9792 108.206 81 108.154 81H62.4516C62.4064 80.9914 62.3657 80.9673 62.3364 80.9319C62.3071 80.8965 62.291 80.852 62.291 80.806C62.291 80.76 62.3071 80.7155 62.3364 80.6801C62.3657 80.6447 62.4064 80.6207 62.4516 80.6121H108.157C108.21 80.6121 108.26 80.6328 108.297 80.6699C108.334 80.7069 108.355 80.7571 108.355 80.8094L108.351 80.8026Z"
      fill="#CCCCCC"
    />
    <defs>
      <filter
        id="filter0_d_32_1697"
        x="35.8594"
        y="16.7412"
        width="56.3569"
        height="56.3486"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1697"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1697"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
)

export default withIconStyles(IconGaolsAndInterventionsAddOn)
