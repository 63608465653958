import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconStemCross = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="33.565"
    height="41.409"
    viewBox="0 0 33.565 41.409"
    {...props}
  >
    <g transform="translate(-48.491)">
      <g transform="translate(67.7 18.763)">
        <g transform="translate(0)">
          <path
            d="M286.809,232a.809.809,0,1,0,.809.809A.809.809,0,0,0,286.809,232Z"
            transform="translate(-286 -232)"
            fill="#637885"
          />
        </g>
      </g>
      <g transform="translate(64.465 13.911)">
        <g transform="translate(0)">
          <path
            d="M246.809,172a.809.809,0,1,0,.809.809A.809.809,0,0,0,246.809,172Z"
            transform="translate(-246 -172)"
            fill="#637885"
          />
        </g>
      </g>
      <g transform="translate(54.759 34.939)">
        <g transform="translate(0)">
          <path
            d="M126.809,432a.809.809,0,1,0,.809.809A.809.809,0,0,0,126.809,432Z"
            transform="translate(-126 -432)"
            fill="#637885"
          />
        </g>
      </g>
      <g transform="translate(48.491)">
        <g transform="translate(0)">
          <path
            d="M81.321,32.972,70.935,14.508V4.714A2.426,2.426,0,0,0,70.126,0H60.421a2.426,2.426,0,0,0-.809,4.714v9.794L49.226,32.972a5.661,5.661,0,0,0,4.934,8.437H76.387a5.661,5.661,0,0,0,4.934-8.437ZM61.126,15.116a.808.808,0,0,0,.1-.4V4.853h.809a.809.809,0,1,0,0-1.618H60.421a.809.809,0,1,1,0-1.618h9.705a.809.809,0,0,1,0,1.618H68.508a.809.809,0,0,0,0,1.618h.809V14.72a.808.808,0,0,0,.1.4l4.767,8.475a15.594,15.594,0,0,0-8.478,1.733,13.9,13.9,0,0,1-10.837.908ZM79.877,37.789a4.005,4.005,0,0,1-3.49,2H54.16a4.044,4.044,0,0,1-3.524-6.027l3.43-6.1a15.5,15.5,0,0,0,5.131.874q.388,0,.777-.019a15.7,15.7,0,0,0,6.48-1.762,13.963,13.963,0,0,1,8.717-1.422l4.74,8.426A4.006,4.006,0,0,1,79.877,37.789Z"
            transform="translate(-48.491)"
            fill="#637885"
          />
        </g>
      </g>
      <g transform="translate(59.612 18.763)">
        <g transform="translate(0)">
          <path
            d="M188.426,232a2.426,2.426,0,1,0,2.426,2.426A2.429,2.429,0,0,0,188.426,232Zm0,3.235a.809.809,0,1,1,.809-.809A.81.81,0,0,1,188.426,235.235Z"
            transform="translate(-186 -232)"
            fill="#637885"
          />
        </g>
      </g>
      <g transform="translate(57.994 34.939)">
        <g transform="translate(0)">
          <path
            d="M182.984,432H166.809a.809.809,0,0,0,0,1.618h16.175a.809.809,0,0,0,0-1.618Z"
            transform="translate(-166 -432)"
            fill="#637885"
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconStemCross)
