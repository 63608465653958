import { assignmentStatusOptions } from '@edulastic/constants'

export const TAB_KEYS = {
  ASSIGNMENTS: '1',
  MY_CLASSES: '2',
}

const statusToLowercase = {
  'IN PROGRESS': 'In Progress',
  'IN GRADING': 'In Grading',
  DONE: 'Done',
  'NOT OPEN': 'Not Open',
}

export const getAssignmentStatus = (status, isPaused) => {
  return `${statusToLowercase[status]}${
    isPaused && status !== assignmentStatusOptions.DONE ? ' (Paused)' : ''
  }`
}

export const dashboardToggleSteps = [
  {
    target: '[data-joyride-id="dashboardToggle"]',
    title: 'Explore the New Dashboard',
    content:
      'Switch to the new dashboard for quick access to your top tasks. You can easily toggle back to the old view anytime',
    placement: 'bottom',
    disableBeacon: true,
  },
]

export const newDashboardOverviewSteps = [
  {
    target: '[data-joyride-id="dashboardToggle"]',
    title: 'Welcome to new Dashboard',
    content:
      'Get quick access to all your common tasks. Switch back anytime to older dashboard with the toggle.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="userLifecycleBanner"]',
    title: 'Get Started',
    content:
      'Quickly Create / Import your test  or assign a test from our library.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="classesTab"]',
    title: 'View your classes',
    content:
      'Manage your active classes and view related assignments and reports.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="gridListView"]',
    title: 'View class as grid or list',
    content:
      'Select the desired class view as grid or as list, based on your preference.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="assignmentTab"]',
    title: 'Access recent assignments',
    content:
      'View your recent assignments, check submission and grading status and associated reports.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="newTestBanner"]',
    title: 'Quick access to popular test categories',
    content:
      'Jump right into Test creation flow of your choice and start creating test.',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="contentSearch"]',
    title: 'Unified content search',
    content:
      'Easily search test, items or assignment right from the dashboard.',
    placement: 'bottom',
    disableBeacon: true,
  },
]

const newDashboardOverviewStepsWithoutLifecycleBanner = [
  ...newDashboardOverviewSteps,
]
newDashboardOverviewStepsWithoutLifecycleBanner.splice(1, 1)

export { newDashboardOverviewStepsWithoutLifecycleBanner }
