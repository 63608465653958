/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPuzzel = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="40.135"
    height="41.472"
    viewBox="0 0 40.135 41.472"
    {...props}
  >
    <g transform="translate(-8.25)">
      <g transform="translate(30.468 0)">
        <path
          d="M291.781,21.492a3.247,3.247,0,0,1-2.484-1.16,3.339,3.339,0,0,1-.784-2.415h-5.158a.81.81,0,0,1-.81-.81V11.645a.81.81,0,0,1,.956-.8l.048.009a1.665,1.665,0,1,0,0-3.275l-.048.009a.81.81,0,0,1-.956-.8V.81a.81.81,0,0,1,.81-.81h13.437a3.675,3.675,0,0,1,3.67,3.67V17.108a.81.81,0,0,1-.81.81h-4.6a3.285,3.285,0,0,1-3.223,3.574ZM284.164,16.3h5.3a.81.81,0,0,1,.8.956l-.11.6a1.725,1.725,0,0,0,.382,1.43,1.615,1.615,0,0,0,1.272.586,1.665,1.665,0,0,0,1.612-1.964l-.12-.655a.81.81,0,0,1,.8-.956h4.748V3.67a2.053,2.053,0,0,0-2.05-2.05H284.164V5.95a3.286,3.286,0,0,1,0,6.54Z"
          transform="translate(-282.544 0)"
          fill="#637885"
        />
      </g>
      <g transform="translate(8.25 7.257)">
        <g transform="translate(0)">
          <path
            d="M41.655,110.438a.81.81,0,0,0,.81-.81V106.7a.81.81,0,0,0-.81-.81H36.1a.81.81,0,0,0-.8.956l.12.655a1.665,1.665,0,0,1-1.612,1.964,1.616,1.616,0,0,1-1.272-.586,1.725,1.725,0,0,1-.382-1.43l.11-.6a.81.81,0,0,0-.8-.956h-5.3v-3.808a3.286,3.286,0,0,0,0-6.54V90.4a.81.81,0,0,0-.81-.81H11.92a3.674,3.674,0,0,0-3.67,3.67v26.875a3.674,3.674,0,0,0,3.67,3.67H38.795a3.674,3.674,0,0,0,3.67-3.67v-2.351a.81.81,0,0,0-1.62,0v2.351a2.053,2.053,0,0,1-2.05,2.05H26.168v-5.225a.81.81,0,0,0-.955-.8l-.049.009a1.665,1.665,0,1,1,0-3.275l.049.009a.81.81,0,0,0,.955-.8v-4.6h4.348a3.338,3.338,0,0,0,.784,2.415,3.274,3.274,0,0,0,5.755-2.415h3.791v2.123a.81.81,0,0,0,.81.81ZM9.87,93.258a2.053,2.053,0,0,1,2.05-2.05H24.548v5.174a.81.81,0,0,0,.956.8l.049-.009h0a1.665,1.665,0,1,1,0,3.275l-.049-.009a.81.81,0,0,0-.956.8v4.652H21.072a3.289,3.289,0,0,0-6.311,0H9.87ZM22.76,112.01a3.285,3.285,0,0,0,1.787,5.793v4.38H11.92a2.053,2.053,0,0,1-2.05-2.05V107.506h5.573a.81.81,0,0,0,.809-.775,1.666,1.666,0,0,1,3.327,0,.81.81,0,0,0,.809.775h4.158v3.757a3.276,3.276,0,0,0-1.787.747Z"
            transform="translate(-8.25 -89.588)"
            fill="#637885"
          />
        </g>
        <g transform="translate(32.596 23.267)">
          <path
            d="M411.486,378.458a.81.81,0,1,1,.795-.651.816.816,0,0,1-.795.651Z"
            transform="translate(-410.678 -376.841)"
            fill="#637885"
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconPuzzel)
