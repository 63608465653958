export const userPickFields = [
  // changes made here should be also made in backend end file "src/models/user.js"
  // put it in alphabetical order
  '_id',
  'v1Id',
  'atlasId',
  'cleverId',
  'currentSignUpState',
  'districtIds',
  'email',
  'features',
  'firstName',
  'googleId',
  'institutionIds',
  'location',
  'isClientAccessGiven',
  'isUserGoogleLoggedIn',
  'lastName',
  'middleName',
  'msoId',
  'orgData',
  'otherAccounts',
  'lastSigninSSO',
  'role',
  'permissions',
  'tts',
  'thumbnail',
  'title',
  'children',
  'personId',
  'username',
  'isPowerTeacher',
  'status',
  'isAdmin',
  'refreshToken',
  'preferredLanguage',
  'openIdProvider',
  'tokenCreatedTime',
  'currentStandardSetStandards',
  'recommendedContentUpdated',
  'sparkPlaylistCollectionsVisited',
  'teachingStandards',
  'referrer',
  'currentDistrictId',
  'utm_source',
  'isProxy',
]
