export const assignmentNewSearchAndFolderTutorialSteps = [
  {
    target: '[data-joyride-id="assignmentsSearch"]',
    title: 'Search Assignment',
    content: 'Search your assignments by name.',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="folderDropdown"]',
    title: 'Changing Folders',
    content:
      'Organize your assignments better with the new folder dropdown. You can view and manage your assignments within different folders for easier access.',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="addToFolder"]',
    title: 'Add to folder',
    content: 'Add the assignment to a specific folder from the Actions menu.',
    placement: 'left',
    disableBeacon: true,
  },
]
