import { segmentApi } from '@edulastic/api'

// CONSTANTS
export const FEATURE_BANNER = 'FEATURE_BANNER'

// METHODS
/**
 * Retrieves closed feature banner information from the localStorage.
 *
 * @param {string} userId - to get bannerIds of given userId
 * @returns {Array} - An array of banner IDs corresponding to the specified key.
 */
export const getClosedFeatureBannerInfo = (userId) => {
  const bannerIds = localStorage.getItem(`bannerIds:${userId}`)
  if (bannerIds) {
    return JSON.parse(bannerIds)
  }
  return []
}

/**
 * Sets the closed feature banner information in the localStorage.
 * If a key is provided, it updates the corresponding key in the stored object.
 * If no key is provided, it defaults to 'dashboardBanners'.
 *
 * @param {string} bannerId  - banner id to be saved in localStorage
 * @param {string} userId - to set the bannerIds as per user account
 * @returns {void}
 */
export const setClosedFeatureBannerInfo = (bannerId, userId) => {
  const bannerIds = getClosedFeatureBannerInfo(userId)
  bannerIds.push(bannerId)
  localStorage.setItem(`bannerIds:${userId}`, JSON.stringify(bannerIds))
}

export const getOriginFromUrl = () => {
  const currentPage = window.location.pathname.toLowerCase()
  const urlParts = currentPage.split('/')
  const authorIndex = urlParts.indexOf('author')
  return urlParts[authorIndex + 1]
}

export const handleBannerAction = (bannerInfo, userRole, history) => {
  const origin = getOriginFromUrl()
  const { targetUrl, analyticalEventProperty } = bannerInfo
  segmentApi.genericEventTrack(FEATURE_BANNER, {
    title: analyticalEventProperty.title,
    origin,
  })
  const urlToNavigate = targetUrl[userRole]
  history.push(urlToNavigate)
}

export const saveBannerIdToLocalStorage = (bannerId, userId) => {
  setClosedFeatureBannerInfo(bannerId, userId)
}

export const showBannerCB = (bannerInfo, user, isBannerClosed) => {
  const { validFrom, validTill, bannerId, tags = [] } = bannerInfo
  const closedBannersId = getClosedFeatureBannerInfo(user._id)
  const currentDate = new Date().getTime()
  const isSubscriptionValid = user?.features?.premium
    ? tags.includes('PREMIUM')
    : tags.includes('FREE')
  return (
    !closedBannersId.includes(bannerId) &&
    currentDate >= validFrom &&
    currentDate <= validTill &&
    !isBannerClosed &&
    isSubscriptionValid
  )
}

export const strContainsHtml = (str) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')

  return doc.body.children.length > 0
}

// * Note: This getTransformedBannerData function only returns the data necessary for the component.
export const getTransformedBannerData = (
  bannerTiles,
  isPremium,
  placeholderId
) => {
  const bannerInfo = bannerTiles?.find((tile) => {
    const placeholderIds = tile.placeholderIds
    return placeholderIds?.includes(placeholderId)
  })

  if (bannerInfo) {
    const {
      analyticalEventProperty,
      targetUrl,
      buttonText,
      buttonTextColor,
    } = bannerInfo.config

    return {
      bannerId: bannerInfo._id,
      description: bannerInfo.description,
      title: bannerInfo.title,
      type: bannerInfo.type,
      validFrom: bannerInfo.validFrom,
      validTill: bannerInfo.validTill,
      analyticalEventProperty,
      targetUrl,
      buttonTextColor,
      buttonText,
      tags: bannerInfo.tags,
      imageUrl: isPremium
        ? bannerInfo.premiumImageUrl || bannerInfo.imageUrl
        : bannerInfo.imageUrl || bannerInfo.premiumImageUrl,
    }
  }
  return {}
}

export const getJsx = (str) => {
  if (strContainsHtml(str)) {
    return str
  }
  return `<p>${str}</p>`
}

export const getLibraryBannerTitle = (title) => {
  const jsxTitle = getJsx(title)
  const screenWidth = window.innerWidth

  if (screenWidth <= 1200) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(jsxTitle, 'text/html')
    const tooltipTitle = `<p>${doc.body.textContent}</p>` // rendering tooltip title without inline style to maintain visibility of text
    return `<Tooltip title={${tooltipTitle}}  placement="bottom">${jsxTitle}</Tooltip>`
  }
  return jsxTitle
}
