/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconEarlyWarningAddOn = (props) => (
  <SVG
    width="126"
    height="81"
    viewBox="0 0 126 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M114.133 80.761L110.56 69.9751C110.56 69.9751 114.491 73.9061 114.491 77.1321L113.786 69.6729C115.105 71.7064 115.741 74.1079 115.601 76.5278C115.399 80.6632 114.133 80.761 114.133 80.761Z"
      fill="#E6E6E6"
    />
    <path
      d="M37.3841 70.9386L33.8965 60.4158C33.8965 60.4158 37.7328 64.2522 37.7328 67.4001L37.0443 60.1198C38.3317 62.1042 38.9521 64.4478 38.8151 66.8093C38.6182 70.8335 37.3841 70.9386 37.3841 70.9386Z"
      fill="#E6E6E6"
    />
    <path
      d="M91.8263 75.7973C91.8263 82.7213 78.0986 80.5911 61.2862 80.6076C44.4737 80.6241 30.9474 82.7753 30.9414 75.8513C30.9354 68.9273 44.6632 67.4091 61.4771 67.394C78.291 67.379 91.8203 68.8733 91.8263 75.7973Z"
      fill="#E6E6E6"
    />
    <path d="M125.133 80.6017H0V80.9189H125.133V80.6017Z" fill="#3F3D56" />
    <path
      d="M80.0425 66.447H77.8447L76.7925 57.9686H80.0365L80.0425 66.447Z"
      fill="#A0616A"
    />
    <path
      d="M76.0319 65.8171H80.5146V68.4869H73.5996V68.2434C73.5994 67.9242 73.6623 67.6081 73.7845 67.3133C73.9067 67.0184 74.086 66.7506 74.312 66.5252C74.5379 66.2998 74.8062 66.1212 75.1014 65.9997C75.3965 65.8782 75.7127 65.8161 76.0319 65.8171Z"
      fill="#2F2E41"
    />
    <path
      d="M96.1992 78.9601H94.0015L92.9492 70.4817H96.1932L96.1992 78.9601Z"
      fill="#A0616A"
    />
    <path
      d="M92.1871 78.3302H96.6698V81H89.7549V80.7565C89.7547 80.4373 89.8174 80.1212 89.9397 79.8263C90.0619 79.5315 90.2412 79.2637 90.4672 79.0383C90.6931 78.8129 90.9614 78.6342 91.2566 78.5127C91.5517 78.3912 91.8679 78.3292 92.1871 78.3302Z"
      fill="#2F2E41"
    />
    <path
      d="M106.339 53.773C106.491 53.5887 106.6 53.3733 106.66 53.1419C106.72 52.9106 106.728 52.6689 106.685 52.434C106.641 52.1991 106.546 51.9766 106.407 51.7822C106.268 51.5879 106.088 51.4264 105.88 51.3092L100.769 30.5131L97.5054 31.9172L103.577 52.0247C103.404 52.3873 103.37 52.8007 103.481 53.1867C103.592 53.5727 103.841 53.9046 104.18 54.1197C104.52 54.3348 104.926 54.4181 105.323 54.354C105.719 54.2898 106.078 54.0826 106.333 53.7715L106.339 53.773Z"
      fill="#A0616A"
    />
    <path
      d="M102.36 11.742C102.178 11.5516 101.956 11.4035 101.71 11.3081C101.465 11.2126 101.201 11.1722 100.938 11.1895C100.675 11.2069 100.419 11.2816 100.188 11.4084C99.9574 11.5353 99.7569 11.7112 99.6013 11.9239L91.7002 12.8258L92.9028 15.8729L100.088 14.5275C100.463 14.7579 100.908 14.8446 101.341 14.7714C101.774 14.6981 102.166 14.47 102.444 14.1295C102.722 13.7889 102.866 13.3591 102.851 12.9199C102.835 12.4807 102.661 12.0621 102.36 11.742Z"
      fill="#A0616A"
    />
    <path
      d="M89.3359 51.6159L92.7844 77.4944L97.0762 76.6105C97.0762 76.6105 96.4448 57.7641 98.5915 55.4927C100.738 53.2213 95.8315 49.2407 95.8315 49.2407L89.3359 51.6159Z"
      fill="#2F2E41"
    />
    <path
      d="M90.7603 44.4874L89.1758 46.8641C89.1758 46.8641 76.9799 41.7951 76.9799 49.8706C77.1296 54.5367 76.8113 59.2061 76.0299 63.8088C75.7773 64.1876 78.9507 65.1707 80.4645 64.9182C80.4645 64.9182 82.333 51.8954 82.2067 51.1378C82.2067 51.1378 94.1712 57.0005 96.4426 56.4984C97.2302 56.3009 97.9465 55.8862 98.51 55.3015C99.0734 54.7168 99.4613 53.9856 99.6295 53.1912C100.008 52.181 99.4716 44.1627 99.4716 44.1627L91.8997 41.8612L90.7603 44.4874Z"
      fill="#2F2E41"
    />
    <path
      d="M90.9741 30.2215L90.5231 29.6803C90.5231 29.6803 85.4662 18.3037 87.4535 15.2325C89.4408 12.1614 98.1086 11.7104 98.3791 11.8923C98.6497 12.0742 97.7327 14.4177 98.3641 15.5001C98.3641 15.5001 91.649 16.9552 91.198 17.1371C90.7471 17.319 94.6781 23.992 94.6781 23.992L94.2271 28.0508L90.9741 30.2215Z"
      fill="#CCCCCC"
    />
    <path
      d="M99.8242 24.6249C99.8242 24.6249 95.1295 25.1661 94.9476 25.1661C94.7657 25.1661 94.6771 23.992 94.6771 23.992L90.5236 29.6803C90.5236 29.6803 88.4776 46.1831 89.0203 45.9155C89.563 45.6479 99.5551 47.2008 99.6453 46.4777C99.6038 44.9721 99.6641 43.4654 99.8257 41.968C100.096 41.7876 104.52 30.5913 104.52 30.5913C104.284 29.3246 103.574 28.1956 102.535 27.4345C101.427 26.719 100.499 25.7575 99.8242 24.6249Z"
      fill="#CCCCCC"
    />
    <path
      d="M103.074 29.049L104.519 30.5838C104.519 30.5838 106.665 48.223 105.491 48.7642C104.366 49.1226 103.187 49.2834 102.007 49.2392L99.7324 40.8766L103.074 29.049Z"
      fill="#CCCCCC"
    />
    <path
      d="M95.3128 23.5079C97.6083 23.5079 99.4693 21.647 99.4693 19.3514C99.4693 17.0559 97.6083 15.1949 95.3128 15.1949C93.0172 15.1949 91.1562 17.0559 91.1562 19.3514C91.1562 21.647 93.0172 23.5079 95.3128 23.5079Z"
      fill="#A0616A"
    />
    <path
      d="M94.3366 19.9994C94.2809 19.1365 93.1926 19.1079 92.3282 19.0974C91.4638 19.0869 90.4311 19.12 89.9426 18.4074C89.7976 18.1604 89.7212 17.8791 89.7212 17.5927C89.7212 17.3062 89.7976 17.0249 89.9426 16.7779C90.2403 16.2888 90.6262 15.8591 91.0806 15.5107C92.1583 14.4995 93.3727 13.6448 94.6883 12.9717C96.0189 12.2981 97.5583 12.1651 98.9847 12.6003C100.027 13.188 100.939 13.9829 101.663 14.9361C102.387 15.8893 102.908 16.9807 103.194 18.1429C103.267 19.0479 103.151 19.9582 102.852 20.8158C102.554 21.6733 102.08 22.4592 101.461 23.1231C100.247 24.4686 97.4724 23.9259 95.7256 24.4039C95.9681 23.9934 96.126 23.5385 96.1899 23.0661C96.2537 22.5936 96.2223 22.1131 96.0975 21.653C95.9726 21.1929 95.7569 20.7624 95.463 20.387C95.1691 20.0116 94.8029 19.6988 94.3862 19.4672L94.3366 19.9994Z"
      fill="#2F2E41"
    />
    <path
      d="M57.295 73.7588C62.8052 73.7588 67.2721 69.2919 67.2721 63.7817C67.2721 58.2715 62.8052 53.8046 57.295 53.8046C51.7848 53.8046 47.3179 58.2715 47.3179 63.7817C47.3179 69.2919 51.7848 73.7588 57.295 73.7588Z"
      fill="#1AB395"
    />
    <path
      d="M50.6327 53.0965C49.2499 53.0966 47.8966 52.6958 46.7368 51.9426C45.5771 51.1894 44.6605 50.1162 44.0981 48.8528L28.2658 13.2001C27.7613 12.0227 27.501 10.7552 27.5005 9.47428C27.5 8.19336 27.7593 6.92564 28.2628 5.74783C28.7663 4.57003 29.5035 3.5066 30.4298 2.62188C31.3561 1.73717 32.4522 1.04956 33.6519 0.600645C34.8516 0.151732 36.1299 -0.0491462 37.4094 0.0101553C38.689 0.0694568 39.9432 0.387703 41.0962 0.945653C42.2492 1.5036 43.2771 2.28966 44.1176 3.25625C44.9581 4.22285 45.5938 5.34991 45.9862 6.56924L57.45 43.8575C57.7782 44.9248 57.8515 46.0542 57.664 47.155C57.4765 48.2557 57.0335 49.2972 56.3705 50.1957C55.7075 51.0941 54.8431 51.8246 53.8466 52.3284C52.8501 52.8322 51.7493 53.0953 50.6327 53.0965Z"
      fill="#1AB395"
    />
  </SVG>
)

export default withIconStyles(IconEarlyWarningAddOn)
