import { createAction, createReducer } from 'redux-starter-kit'
import { createSelector } from 'reselect'
import { roleuser, tutorials } from '@edulastic/constants'
import { isEmpty } from 'lodash'
import { getTimestampFromMongoId } from '@edulastic/common/src/helpers'
import {
  getAssignmentsByTestSelector,
  getAssignmentsSummary,
} from '../author/src/selectors/assignments'
import { getUserId, getUserRole } from '../author/src/selectors/user'
import { assignmentNewSearchAndFolderTutorialSteps } from '../author/Assignments/components/Container/constants'

export const SET_CURRENT_TUTORIAL = '[tutorial] set tutorial pack'

export const GET_VIEWED_TUTORIALS_REQUEST =
  '[tutorial] get viewed tutorials request'
export const GET_VIEWED_TUTORIALS_SUCCESS =
  '[tutorial] get viewed tutorials request success'
export const GET_VIEWED_TUTORIALS_FAILURE =
  '[tutorial] get viewed tutorials request failure'
export const SET_VIEWED_TUTORIALS_REQUEST = `[tutorial] set viewed tutorials request`
export const SET_VIEWED_TUTORIALS_SUCCESS = `[tutorial] set viewed tutorials success`
export const SET_VIEWED_TUTORIALS_FAILURE = `[tutorial] set viewed tutorials failure`

const initialState = {
  currentTutorial: null,
  viewedTutorials: [],
  error: null,
}

export const setCurrentTutorialAction = createAction(SET_CURRENT_TUTORIAL)
export const getViewedTutorialsAction = createAction(
  GET_VIEWED_TUTORIALS_REQUEST
)
export const setViewedTutorialsAction = createAction(
  SET_VIEWED_TUTORIALS_REQUEST
)

const reducer = createReducer(initialState, {
  [SET_CURRENT_TUTORIAL]: (state, { payload }) => {
    state.currentTutorial = payload
  },
  [GET_VIEWED_TUTORIALS_SUCCESS]: (state, { payload }) => {
    state.viewedTutorials = payload
    state.error = null
  },
  [SET_VIEWED_TUTORIALS_SUCCESS]: (state, { payload }) => {
    state.viewedTutorials = payload
    state.error = null
  },
  [GET_VIEWED_TUTORIALS_FAILURE]: (state, { payload }) => {
    state.viewedTutorials = Object.values(tutorials)
    state.error = payload
  },
  [SET_VIEWED_TUTORIALS_FAILURE]: (state, { payload }) => {
    state.error = payload
  },
})

const stateSelector = (state) => state.tutorial

export const viewedTutorialsSelector = createSelector(
  stateSelector,
  getUserId,
  (state, userId) => {
    if (process.env.REACT_APP_QA_ENV || !userId) {
      return Object.values(tutorials)
    }
    const userCreatedTime = getTimestampFromMongoId(userId)
    const mayThirtyFirst2024 = 1717113600000 // new Date('2024-05-31').getTime()
    if (userCreatedTime > mayThirtyFirst2024) {
      return [
        ...state.viewedTutorials,
        tutorials.newSearchAndFolderView,
      ].filter((item) => !!item)
    }
    return state.viewedTutorials
  }
)

export const getAssignmentTutorialStepsSelector = createSelector(
  getAssignmentsSummary,
  getAssignmentsByTestSelector,
  getUserRole,
  (daAssignments, teacherAssignments, userRole) => {
    if (
      roleuser.DA_SA_ROLE_ARRAY.includes(userRole) &&
      isEmpty(daAssignments)
    ) {
      return assignmentNewSearchAndFolderTutorialSteps.slice(0, 2)
    }
    if (userRole === roleuser.TEACHER && isEmpty(teacherAssignments)) {
      return assignmentNewSearchAndFolderTutorialSteps.slice(0, 2)
    }
    return assignmentNewSearchAndFolderTutorialSteps
  }
)

export default reducer
