/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSurveyInsightsAddOn = (props) => (
  <SVG
    width="91"
    height="81"
    viewBox="0 0 91 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M77.1545 30.4338L78.4558 27.9503C77.2893 26.5414 73.4608 24.8323 73.4608 24.8323L68.2801 20.1166C68.2248 19.9063 68.1151 19.7143 67.9621 19.5599C67.809 19.4055 67.618 19.2941 67.4082 19.2369C67.1985 19.1798 66.9773 19.1788 66.7671 19.2343C66.5568 19.2897 66.3649 19.3995 66.2106 19.5527C66.0563 19.7059 65.945 19.897 65.888 20.1068C65.831 20.3166 65.8303 20.5377 65.8859 20.7479C65.9415 20.9581 66.0515 21.15 66.2048 21.3042C66.358 21.4584 66.5492 21.5695 66.7591 21.6263L72.3561 28.3394L77.1545 30.4338Z"
      fill="#9E616A"
    />
    <path
      d="M76.5244 30.2599L78.3615 30.8447C79.6973 31.27 81.1369 31.238 82.4525 30.7536C82.8506 30.6055 83.1993 30.3489 83.4591 30.013C83.719 29.677 83.8797 29.2751 83.9231 28.8526C83.9781 28.2795 83.8134 27.7069 83.4623 27.2506C83.1112 26.7943 82.5999 26.4884 82.0318 26.3947C80.3092 26.1117 78.089 25.9717 77.2973 26.9147L77.289 26.9245L76.5244 30.2599Z"
      fill="#3F3D56"
    />
    <path
      d="M63.3109 37.8663C68.4216 37.8663 72.5647 33.7232 72.5647 28.6125C72.5647 23.5018 68.4216 19.3587 63.3109 19.3587C58.2002 19.3587 54.0571 23.5018 54.0571 28.6125C54.0571 33.7232 58.2002 37.8663 63.3109 37.8663Z"
      fill="white"
    />
    <path
      d="M59.5331 24.9926C59.4061 25.675 59.198 26.3397 58.913 26.9726C57.2806 30.4255 61.9293 29.3004 63.5903 29.3004C65.407 29.3004 67.1448 30.2554 66.8791 26.0116C66.8594 25.1455 66.5065 24.3204 65.894 23.7079C65.2814 23.0953 64.4563 22.7425 63.5903 22.7228C62.0414 22.7228 59.8589 23.4121 59.5331 24.9926Z"
      fill="#2F2E41"
    />
    <path
      d="M63.3179 29.2989C64.7939 29.2989 65.9904 28.1024 65.9904 26.6264C65.9904 25.1505 64.7939 23.954 63.3179 23.954C61.842 23.954 60.6455 25.1505 60.6455 26.6264C60.6455 28.1024 61.842 29.2989 63.3179 29.2989Z"
      fill="#FFB6B6"
    />
    <path
      d="M61.5032 23.9029C61.9715 23.6175 62.5079 23.4632 63.0562 23.456C63.6046 23.4489 64.1448 23.5891 64.6204 23.8622C65.096 24.1352 65.4895 24.531 65.7599 25.0081C66.0302 25.4853 66.1674 26.0262 66.1572 26.5745C64.7837 27.0339 63.3028 27.0625 61.9126 26.6566L61.5694 25.8174L61.367 26.6573C61.1684 26.7838 60.9379 26.8509 60.7024 26.8509C60.467 26.8509 60.2365 26.7838 60.0379 26.6573C60.0195 26.1101 60.1451 25.5676 60.4023 25.0842C60.6595 24.6008 61.0391 24.1934 61.5032 23.9029Z"
      fill="#2F2E41"
    />
    <path
      d="M70.504 34.7664C68.901 36.5901 66.6451 37.7103 64.2234 37.8853C61.8016 38.0603 59.4082 37.2761 57.5596 35.7019L58.3535 33.487C58.5329 33.0937 58.8159 32.7566 59.1723 32.5118C59.5287 32.2671 59.945 32.124 60.3765 32.0978L60.8815 32.0669L62.0856 31.0283C62.0856 31.0283 65.33 30.9124 65.2818 30.8568L67.2469 32.0391V32.0308L68.019 32.0782C68.4506 32.1042 68.8669 32.2473 69.2233 32.4921C69.5797 32.7368 69.8627 33.074 70.0419 33.4675L70.504 34.7664Z"
      fill="#1AB395"
    />
    <path
      d="M71.1206 79.4045H69.5831L68.8516 73.4741H71.1206V79.4045Z"
      fill="#9E616A"
    />
    <path
      d="M71.5129 80.8947H66.5557V80.8322C66.5557 80.3204 66.759 79.8296 67.1208 79.4677C67.4827 79.1058 67.9735 78.9025 68.4853 78.9025L69.3884 78.2155L71.0779 78.9025H71.5107L71.5129 80.8947Z"
      fill="#2F2E41"
    />
    <path
      d="M84.6719 79.4045H83.1344L82.4028 73.4741H84.6719V79.4045Z"
      fill="#9E616A"
    />
    <path
      d="M85.0627 80.8939H80.1055V80.8314C80.1055 80.3196 80.3088 79.8288 80.6706 79.4669C81.0325 79.105 81.5233 78.9017 82.0351 78.9017L82.9382 78.2147L84.6277 78.9017H85.0605L85.0627 80.8939Z"
      fill="#2F2E41"
    />
    <path
      d="M82.3339 47.6642L83.6269 59.9494C83.6269 59.9494 85.348 72.2504 85.0251 73.9475C84.7023 75.6446 85.1057 75.8869 85.1057 75.8869L82.1555 75.6769C82.2058 75.1335 82.0627 74.5899 81.7514 74.1417C81.2667 73.657 81.5091 72.2022 81.5091 72.2022L75.7894 52.191L72.314 62.6979C72.314 62.6979 71.7481 74.2553 71.6675 74.4171C71.587 74.5789 71.3439 76.7606 71.3439 76.7606H68.3532C68.3532 76.7606 68.7573 75.4677 68.1913 75.0636C67.6254 74.6594 67.1377 74.9831 67.6254 74.2553C67.839 73.969 67.9921 73.6422 68.0754 73.2948C68.1587 72.9474 68.1705 72.5867 68.1101 72.2346C67.9905 71.5679 67.9362 70.8911 67.9482 70.2139C67.9482 70.2139 68.1906 48.3889 69.5648 47.6642C70.939 46.9395 82.3339 47.6642 82.3339 47.6642Z"
      fill="#2F2E41"
    />
    <path
      d="M76.2083 22.789C77.9648 22.789 79.3887 21.3651 79.3887 19.6086C79.3887 17.8521 77.9648 16.4282 76.2083 16.4282C74.4518 16.4282 73.0278 17.8521 73.0278 19.6086C73.0278 21.3651 74.4518 22.789 76.2083 22.789Z"
      fill="#9E616A"
    />
    <path
      d="M83.4313 49.0031C77.2714 50.275 69.1089 48.0879 69.1089 48.0879L71.2861 38.649L70.5125 28.7698C70.4584 28.1947 70.5911 27.6175 70.8908 27.1237C71.1905 26.6299 71.6413 26.2457 72.1764 26.0282L73.6545 25.4743L74.8759 23.7253L78.3657 23.8396L79.761 25.676L80.862 26.26L83.2484 27.5258L83.259 27.5311V27.5363L82.5064 34.8011L82.0548 39.1811C82.0548 39.1811 83.4268 44.1075 83.1454 44.7607C82.8639 45.414 83.1085 45.0979 83.177 45.4464C83.2454 45.7948 83.4539 45.8174 83.4855 46.5045C83.5171 47.1916 83.4313 49.0031 83.4313 49.0031Z"
      fill="#3F3D56"
    />
    <path
      d="M90.8791 81H43.2976C43.2709 80.9977 43.246 80.9855 43.2279 80.9658C43.2098 80.9461 43.1997 80.9203 43.1997 80.8935C43.1997 80.8667 43.2098 80.8409 43.2279 80.8212C43.246 80.8014 43.2709 80.7892 43.2976 80.787H90.8791C90.9058 80.7892 90.9306 80.8014 90.9488 80.8212C90.9669 80.8409 90.977 80.8667 90.977 80.8935C90.977 80.9203 90.9669 80.9461 90.9488 80.9658C90.9306 80.9855 90.9058 80.9977 90.8791 81Z"
      fill="#CCCCCC"
    />
    <path
      d="M76.0543 22.637C76.6122 22.6313 77.1687 22.5779 77.7175 22.4775C78.5376 22.1387 79.252 21.5865 79.7864 20.8782C80.0227 20.4096 80.1808 19.9055 80.2545 19.3858C80.3618 18.782 80.3411 18.1624 80.1937 17.567C80.0463 16.9717 79.7755 16.414 79.3988 15.93C79.2535 15.7273 79.0561 15.5676 78.8276 15.4679C78.8072 15.4604 78.7869 15.4543 78.7659 15.4491C78.4346 15.371 78.1343 15.1957 77.9034 14.9456C77.8585 14.895 77.8099 14.8477 77.7582 14.8041C77.4992 14.5968 77.1949 14.4537 76.8701 14.3864C76.0952 14.3052 75.3144 14.4695 74.638 14.856C74.183 15.0291 73.6856 15.0572 73.214 14.9366C73.191 14.9328 73.1673 14.9351 73.1454 14.9432C73.1234 14.9514 73.104 14.9652 73.0891 14.9832C72.9194 15.1511 72.6925 15.2484 72.4539 15.2557C72.3079 15.2662 72.1597 15.4717 71.9813 15.7403C71.9407 15.8013 71.8933 15.872 71.8594 15.9157L71.8526 15.8344L71.7721 15.9277C71.646 16.0742 71.559 16.2502 71.5192 16.4393C71.4794 16.6285 71.4881 16.8246 71.5445 17.0095C71.6009 17.1943 71.7032 17.3619 71.8418 17.4966C71.9804 17.6313 72.1509 17.7287 72.3373 17.7798C72.4854 17.8152 72.6367 17.8354 72.7888 17.84C72.8806 17.8461 72.9762 17.8521 73.0681 17.8656C73.3619 17.9184 73.6371 18.0462 73.867 18.2368C74.0968 18.4273 74.2735 18.6741 74.3798 18.9531C74.4048 19.0176 74.4443 19.0756 74.4953 19.1224C74.5462 19.1692 74.6073 19.2037 74.6737 19.2232C74.7401 19.2427 74.8101 19.2467 74.8783 19.2348C74.9465 19.223 75.011 19.1956 75.0669 19.1548C75.16 19.0783 75.2702 19.0255 75.3882 19.0009C75.5061 18.9762 75.6283 18.9805 75.7442 19.0133C75.845 19.0658 75.9247 19.1514 75.97 19.2556C76.0244 19.3717 76.1122 19.4689 76.2221 19.5349C76.5984 19.7162 76.6443 20.5606 76.5299 21.2658C76.4193 21.9484 76.1785 22.4466 75.9452 22.4767C75.7653 22.5 75.745 22.5136 75.7322 22.546L75.7209 22.5746L75.742 22.6009C75.8423 22.6352 75.9488 22.6476 76.0543 22.637Z"
      fill="#2F2E41"
    />
    <path
      d="M63.3097 37.9815C61.4565 37.9815 59.645 37.4319 58.1041 36.4024C56.5633 35.3728 55.3624 33.9095 54.6532 32.1974C53.944 30.4853 53.7584 28.6014 54.12 26.7838C54.4815 24.9663 55.3739 23.2968 56.6843 21.9864C57.9947 20.676 59.6642 19.7837 61.4817 19.4221C63.2992 19.0606 65.1832 19.2461 66.8953 19.9553C68.6074 20.6645 70.0707 21.8654 71.1003 23.4063C72.1298 24.9471 72.6793 26.7586 72.6793 28.6118C72.6793 31.0968 71.6922 33.48 69.935 35.2371C68.1779 36.9943 65.7946 37.9815 63.3097 37.9815ZM63.3097 19.4746C61.5024 19.4746 59.7356 20.0106 58.2329 21.0147C56.7302 22.0187 55.559 23.4459 54.8673 25.1156C54.1757 26.7853 53.9948 28.6227 54.3474 30.3953C54.6999 32.1678 55.5702 33.796 56.8482 35.074C58.1261 36.3519 59.7544 37.2222 61.527 37.5748C63.2995 37.9274 65.1368 37.7465 66.8066 37.0548C68.4763 36.3632 69.9034 35.192 70.9075 33.6893C71.9116 32.1865 72.4475 30.4198 72.4475 28.6125C72.4476 27.4125 72.2113 26.2241 71.7522 25.1154C71.293 24.0066 70.6199 22.9992 69.7713 22.1506C68.9228 21.302 67.9154 20.6288 66.8067 20.1696C65.698 19.7103 64.5097 19.4739 63.3097 19.4739V19.4746Z"
      fill="#3F3D56"
    />
    <path
      d="M30.9686 18.6235C36.0794 18.6235 40.2224 14.4804 40.2224 9.36969C40.2224 4.25897 36.0794 0.115906 30.9686 0.115906C25.8579 0.115906 21.7148 4.25897 21.7148 9.36969C21.7148 14.4804 25.8579 18.6235 30.9686 18.6235Z"
      fill="white"
    />
    <path
      d="M31.3965 10.3127C33.0628 10.3127 34.4136 8.96186 34.4136 7.29556C34.4136 5.62925 33.0628 4.27844 31.3965 4.27844C29.7302 4.27844 28.3794 5.62925 28.3794 7.29556C28.3794 8.96186 29.7302 10.3127 31.3965 10.3127Z"
      fill="#FFB8B8"
    />
    <path
      d="M34.0539 5.30197C34.1682 5.13956 34.2337 4.94786 34.2428 4.74944C34.2518 4.55103 34.204 4.35417 34.1049 4.18202C34.0058 4.00988 33.8596 3.86962 33.6835 3.77778C33.5074 3.68593 33.3087 3.64632 33.1109 3.66359C33.1109 3.66359 31.6373 2.327 30.1351 3.419C29.859 3.44193 29.5946 3.54063 29.371 3.70423C29.1474 3.86782 28.9732 4.08996 28.8678 4.34619C28.7901 4.31081 28.7053 4.2936 28.6199 4.29588C28.5346 4.29815 28.4508 4.31984 28.3751 4.3593C28.2994 4.39876 28.2336 4.45496 28.1829 4.52363C28.1321 4.59229 28.0976 4.67162 28.0821 4.7556C27.8131 5.5994 27.8131 6.50595 28.0821 7.34975C28.2227 7.83346 28.4345 8.29356 28.7105 8.71495C28.7105 8.71495 28.3794 7.1902 30.2653 7.05323C32.1513 6.91626 33.8778 5.73547 34.0381 7.23763C34.1984 8.73978 34.3677 8.06095 34.3677 8.06095C34.3677 8.06095 35.8608 5.91382 34.0539 5.30197Z"
      fill="#2F2E41"
    />
    <path
      d="M37.9274 15.5243C36.3243 17.348 34.0685 18.4682 31.6467 18.6432C29.225 18.8182 26.8315 18.034 24.9829 16.4598L25.7769 14.2449C25.9563 13.8516 26.2393 13.5145 26.5957 13.2697C26.9521 13.025 27.3683 12.8819 27.7998 12.8557L28.3048 12.8248L29.509 11.7863C29.509 11.7863 32.7534 11.6703 32.7052 11.6147L34.6702 12.797V12.7887L35.4423 12.8361C35.8739 12.8621 36.2902 13.0052 36.6466 13.25C37.003 13.4947 37.286 13.8319 37.4653 14.2254L37.9274 15.5243Z"
      fill="#1AB395"
    />
    <path
      d="M30.9683 18.7394C29.1152 18.7394 27.3036 18.1899 25.7628 17.1603C24.222 16.1307 23.021 14.6674 22.3119 12.9553C21.6027 11.2432 21.4171 9.35929 21.7787 7.54175C22.1402 5.72421 23.0326 4.05469 24.3429 2.74432C25.6533 1.43395 27.3228 0.541572 29.1404 0.180041C30.9579 -0.181491 32.8419 0.00405786 34.5539 0.713227C36.266 1.4224 37.7294 2.62334 38.7589 4.16417C39.7885 5.70501 40.338 7.51654 40.338 9.36969C40.338 11.8547 39.3508 14.2379 37.5937 15.9951C35.8365 17.7522 33.4533 18.7394 30.9683 18.7394ZM30.9683 0.232555C29.161 0.232555 27.3943 0.768482 25.8916 1.77257C24.3889 2.77665 23.2176 4.20379 22.526 5.87352C21.8344 7.54325 21.6534 9.38058 22.006 11.1532C22.3586 12.9257 23.2289 14.5539 24.5069 15.8319C25.7848 17.1099 27.413 17.9802 29.1856 18.3327C30.9582 18.6853 32.7955 18.5044 34.4652 17.8127C36.135 17.1211 37.5621 15.9499 38.5662 14.4472C39.5703 12.9445 40.1062 11.1777 40.1062 9.37044C40.1063 8.17037 39.87 6.98204 39.4108 5.87329C38.9517 4.76454 38.2786 3.75711 37.43 2.90849C36.5815 2.05988 35.5741 1.38672 34.4654 0.927452C33.3567 0.468184 32.1684 0.232555 30.9683 0.232555Z"
      fill="#3F3D56"
    />
    <path
      d="M9.37049 33.2289C14.4812 33.2289 18.6243 29.0858 18.6243 23.9751C18.6243 18.8644 14.4812 14.7213 9.37049 14.7213C4.25976 14.7213 0.116699 18.8644 0.116699 23.9751C0.116699 29.0858 4.25976 33.2289 9.37049 33.2289Z"
      fill="white"
    />
    <path
      d="M5.08144 20.9648C5.25173 20.3483 5.56199 19.7795 5.98805 19.3025C6.4141 18.8255 6.94447 18.4533 7.53789 18.2148C8.2252 18.0684 8.938 18.0938 9.61315 18.2888C10.2883 18.4837 10.9049 18.8422 11.4084 19.3324C13.0641 20.9813 13.4675 26.6476 12.3304 28.4387C12.1046 28.4267 11.3242 28.4176 11.0946 28.4094L10.771 27.3301V28.3988C10.4062 28.3878 10.0387 28.38 9.66845 28.3755C8.42673 28.3601 7.23935 27.8639 6.35586 26.9912C5.47238 26.1186 4.96162 24.9374 4.93094 23.6959C4.86727 22.7828 4.91782 21.8654 5.08144 20.9648Z"
      fill="#2F2E41"
    />
    <path
      d="M9.23276 25.0061C10.6892 25.0061 11.8698 23.8255 11.8698 22.3691C11.8698 20.9126 10.6892 19.732 9.23276 19.732C7.77635 19.732 6.5957 20.9126 6.5957 22.3691C6.5957 23.8255 7.77635 25.0061 9.23276 25.0061Z"
      fill="#9E616A"
    />
    <path
      d="M6.95703 20.0534L9.99371 18.5331C10.6143 18.8128 11.1488 19.2533 11.5419 19.8091C11.9349 20.3649 12.1721 21.0157 12.2289 21.694L12.3042 22.5971L11.0323 22.2735L10.8931 21.1145L10.6771 22.1832L10.0901 22.0327L10.0953 20.2332L9.50831 22.0319L7.41235 21.5503L6.95703 20.0534Z"
      fill="#2F2E41"
    />
    <path
      d="M16.2345 30.0989C14.6314 31.9225 12.3756 33.0427 9.95386 33.2178C7.53211 33.3928 5.13866 32.6086 3.29004 31.0344L4.08401 28.8195C4.26338 28.4262 4.54643 28.0891 4.9028 27.8443C5.25917 27.5996 5.67544 27.4564 6.10696 27.4302L6.61195 27.3994L7.81608 26.3608C7.81608 26.3608 11.0605 26.2449 11.0123 26.1892L12.9773 27.3715V27.3633L13.7495 27.4107C14.181 27.4367 14.5974 27.5798 14.9538 27.8245C15.3102 28.0693 15.5932 28.4065 15.7724 28.8L16.2345 30.0989Z"
      fill="#1AB395"
    />
    <path
      d="M9.36969 33.344C7.51654 33.344 5.70501 32.7945 4.16417 31.765C2.62334 30.7354 1.4224 29.2721 0.713231 27.56C0.00406212 25.8479 -0.181492 23.964 0.180039 22.1464C0.541571 20.3289 1.43395 18.6594 2.74432 17.349C4.0547 16.0386 5.72421 15.1462 7.54176 14.7847C9.3593 14.4232 11.2432 14.6087 12.9553 15.3179C14.6674 16.0271 16.1307 17.228 17.1603 18.7688C18.1898 20.3097 18.7394 22.1212 18.7394 23.9744C18.7394 26.4594 17.7522 28.8426 15.9951 30.5997C14.2379 32.3569 11.8547 33.344 9.36969 33.344ZM9.36969 14.8372C7.56239 14.8372 5.79567 15.3732 4.29295 16.3772C2.79023 17.3813 1.61901 18.8085 0.927388 20.4782C0.235763 22.1479 0.0548029 23.9853 0.40739 25.7578C0.759978 27.5304 1.63027 29.1586 2.90823 30.4366C4.18619 31.7145 5.8144 32.5848 7.58698 32.9374C9.35955 33.29 11.1969 33.109 12.8666 32.4174C14.5363 31.7258 15.9635 30.5546 16.9676 29.0518C17.9717 27.5491 18.5076 25.7824 18.5076 23.9751C18.5076 21.5516 17.5448 19.2273 15.8312 17.5137C14.1175 15.8 11.7932 14.8372 9.36969 14.8372Z"
      fill="#3F3D56"
    />
    <path
      d="M71.8562 33.4126L70.4557 30.9832C68.6412 31.2135 65.1477 33.5307 65.1477 33.5307L58.3902 35.3798C58.183 35.3138 57.9622 35.3035 57.7498 35.35C57.5373 35.3965 57.3409 35.4981 57.1802 35.6446C57.0195 35.7911 56.9003 35.9773 56.8345 36.1845C56.7686 36.3918 56.7586 36.6127 56.8052 36.8251C56.8519 37.0374 56.9536 37.2337 57.1002 37.3943C57.2469 37.5548 57.4332 37.6739 57.6405 37.7396C57.8478 37.8053 58.0687 37.8152 58.2811 37.7683C58.4934 37.7215 58.6896 37.6195 58.8501 37.4728L67.5168 36.3439L71.8562 33.4126Z"
      fill="#9E616A"
    />
    <path
      d="M71.3734 33.8528L74.5101 31.2255L74.9662 29.1107C75.0539 28.6956 75.0238 28.2643 74.8794 27.8653C74.735 27.4664 74.482 27.1158 74.1489 26.853C73.6947 26.5004 73.1236 26.3332 72.551 26.3852C71.9783 26.4371 71.4466 26.7044 71.0633 27.1329C69.9013 28.4364 68.5941 30.2358 68.9658 31.4099L68.9696 31.4219L71.3734 33.8528Z"
      fill="#3F3D56"
    />
    <path
      d="M23.3017 42.9794H11.517C11.2012 42.979 10.8984 42.8533 10.6751 42.63C10.4517 42.4067 10.3261 42.1039 10.3257 41.788V34.5293C10.3261 34.2135 10.4517 33.9107 10.6751 33.6874C10.8984 33.4641 11.2012 33.3384 11.517 33.338H23.3017C23.6176 33.3384 23.9204 33.4641 24.1437 33.6874C24.367 33.9107 24.4927 34.2135 24.4931 34.5293V41.788C24.4927 42.1039 24.367 42.4067 24.1437 42.63C23.9204 42.8533 23.6176 42.979 23.3017 42.9794Z"
      fill="#E6E6E6"
    />
    <path
      d="M18.6189 42.0439H12.3446C12.0488 42.0437 11.7651 41.9262 11.5559 41.7171C11.3466 41.508 11.2289 41.2244 11.2285 40.9286V35.3888C11.2289 35.093 11.3466 34.8094 11.5559 34.6003C11.7651 34.3912 12.0488 34.2737 12.3446 34.2735H22.4729C22.7686 34.2739 23.052 34.3915 23.2611 34.6006C23.4702 34.8097 23.5878 35.0932 23.5882 35.3888V37.0746C23.5866 38.3921 23.0625 39.6551 22.131 40.5867C21.1994 41.5183 19.9363 42.0424 18.6189 42.0439Z"
      fill="white"
    />
    <path
      d="M21.4631 37.3064H13.357C13.2906 37.3014 13.2285 37.2715 13.1832 37.2226C13.1379 37.1738 13.1128 37.1096 13.1128 37.043C13.1128 36.9764 13.1379 36.9123 13.1832 36.8634C13.2285 36.8146 13.2906 36.7846 13.357 36.7796H21.4646C21.531 36.7846 21.5931 36.8146 21.6384 36.8634C21.6837 36.9123 21.7088 36.9764 21.7088 37.043C21.7088 37.1096 21.6837 37.1738 21.6384 37.2226C21.5931 37.2715 21.531 37.3014 21.4646 37.3064H21.4631Z"
      fill="#1AB395"
    />
    <path
      d="M14.6239 38.3517H13.3573C13.3211 38.3545 13.2846 38.3497 13.2503 38.3377C13.216 38.3257 13.1845 38.3068 13.1578 38.282C13.1311 38.2573 13.1099 38.2273 13.0953 38.194C13.0808 38.1607 13.0732 38.1247 13.0732 38.0883C13.0732 38.052 13.0808 38.016 13.0953 37.9826C13.1099 37.9493 13.1311 37.9193 13.1578 37.8946C13.1845 37.8699 13.216 37.8509 13.2503 37.8389C13.2846 37.8269 13.3211 37.8222 13.3573 37.8249H14.6254C14.6919 37.8299 14.7539 37.8599 14.7992 37.9087C14.8445 37.9576 14.8697 38.0217 14.8697 38.0883C14.8697 38.1549 14.8445 38.2191 14.7992 38.2679C14.7539 38.3168 14.6919 38.3467 14.6254 38.3517H14.6239Z"
      fill="#1AB395"
    />
    <path
      d="M16.8701 39.3963H13.357C13.2906 39.3913 13.2285 39.3614 13.1832 39.3125C13.1379 39.2637 13.1128 39.1995 13.1128 39.1329C13.1128 39.0663 13.1379 39.0022 13.1832 38.9533C13.2285 38.9045 13.2906 38.8746 13.357 38.8695H16.8716C16.9078 38.8668 16.9443 38.8716 16.9786 38.8836C17.013 38.8956 17.0445 38.9145 17.0711 38.9392C17.0978 38.964 17.1191 38.9939 17.1336 39.0273C17.1482 39.0606 17.1557 39.0966 17.1557 39.1329C17.1557 39.1693 17.1482 39.2053 17.1336 39.2386C17.1191 39.2719 17.0978 39.3019 17.0711 39.3266C17.0445 39.3514 17.013 39.3703 16.9786 39.3823C16.9443 39.3943 16.9078 39.3991 16.8716 39.3963H16.8701Z"
      fill="#1AB395"
    />
    <path
      d="M9.9147 35.6876C11.2917 35.6876 12.408 34.5713 12.408 33.1942C12.408 31.8172 11.2917 30.7009 9.9147 30.7009C8.53768 30.7009 7.42139 31.8172 7.42139 33.1942C7.42139 34.5713 8.53768 35.6876 9.9147 35.6876Z"
      fill="#3F3D56"
    />
    <path
      d="M53.9048 10.0583H42.1201C41.8042 10.0579 41.5014 9.93229 41.2781 9.70895C41.0548 9.48562 40.9291 9.18283 40.9287 8.86698V1.6083C40.9291 1.29246 41.0548 0.989671 41.2781 0.766337C41.5014 0.543003 41.8042 0.41736 42.1201 0.416962H53.9048C54.2206 0.41736 54.5234 0.543003 54.7468 0.766337C54.9701 0.989671 55.0957 1.29246 55.0961 1.6083V8.86698C55.0957 9.18283 54.9701 9.48562 54.7468 9.70895C54.5234 9.93229 54.2206 10.0579 53.9048 10.0583Z"
      fill="#E6E6E6"
    />
    <path
      d="M49.2224 9.12283H42.9481C42.6523 9.12263 42.3686 9.00508 42.1594 8.79598C41.9502 8.58688 41.8324 8.30331 41.832 8.0075V2.46772C41.8324 2.17191 41.9502 1.88834 42.1594 1.67924C42.3686 1.47014 42.6523 1.35259 42.9481 1.35239H53.0764C53.3721 1.35278 53.6555 1.47042 53.8646 1.6795C54.0737 1.88858 54.1913 2.17204 54.1917 2.46772V4.15351C54.1901 5.47096 53.6661 6.734 52.7345 7.66559C51.8029 8.59717 50.5399 9.12124 49.2224 9.12283Z"
      fill="white"
    />
    <path
      d="M52.0652 4.38605H43.9591C43.8927 4.38103 43.8306 4.3511 43.7853 4.30225C43.74 4.25341 43.7148 4.18926 43.7148 4.12265C43.7148 4.05604 43.74 3.99189 43.7853 3.94305C43.8306 3.89421 43.8927 3.86427 43.9591 3.85924H52.0667C52.1029 3.8565 52.1394 3.86127 52.1737 3.87326C52.208 3.88526 52.2395 3.90422 52.2662 3.92895C52.2928 3.95368 52.3141 3.98364 52.3287 4.01697C52.3432 4.0503 52.3507 4.08628 52.3507 4.12265C52.3507 4.15902 52.3432 4.19499 52.3287 4.22832C52.3141 4.26166 52.2928 4.29163 52.2662 4.31636C52.2395 4.34108 52.208 4.36004 52.1737 4.37203C52.1394 4.38402 52.1029 4.38879 52.0667 4.38605H52.0652Z"
      fill="#1AB395"
    />
    <path
      d="M45.2265 5.43067H43.9599C43.9236 5.43341 43.8872 5.42864 43.8529 5.41665C43.8185 5.40465 43.787 5.3857 43.7603 5.36097C43.7337 5.33625 43.7124 5.30627 43.6978 5.27294C43.6833 5.23961 43.6758 5.20364 43.6758 5.16727C43.6758 5.1309 43.6833 5.09492 43.6978 5.06159C43.7124 5.02825 43.7337 4.99829 43.7603 4.97356C43.787 4.94884 43.8185 4.92987 43.8529 4.91788C43.8872 4.90589 43.9236 4.90112 43.9599 4.90386H45.228C45.2643 4.90112 45.3007 4.90589 45.335 4.91788C45.3693 4.92987 45.4009 4.94884 45.4275 4.97356C45.4542 4.99829 45.4754 5.02825 45.49 5.06159C45.5045 5.09492 45.512 5.1309 45.512 5.16727C45.512 5.20364 45.5045 5.23961 45.49 5.27294C45.4754 5.30627 45.4542 5.33625 45.4275 5.36097C45.4009 5.3857 45.3693 5.40465 45.335 5.41665C45.3007 5.42864 45.2643 5.43341 45.228 5.43067H45.2265Z"
      fill="#1AB395"
    />
    <path
      d="M47.4729 6.47522H43.9599C43.9236 6.47797 43.8872 6.47319 43.8529 6.4612C43.8185 6.44921 43.787 6.43026 43.7603 6.40553C43.7337 6.3808 43.7124 6.35083 43.6978 6.3175C43.6833 6.28416 43.6758 6.24819 43.6758 6.21182C43.6758 6.17546 43.6833 6.13947 43.6978 6.10614C43.7124 6.07281 43.7337 6.04285 43.7603 6.01812C43.787 5.99339 43.8185 5.97443 43.8529 5.96244C43.8872 5.95044 43.9236 5.94567 43.9599 5.94842H47.4744C47.5107 5.94567 47.5471 5.95044 47.5815 5.96244C47.6158 5.97443 47.6473 5.99339 47.674 6.01812C47.7007 6.04285 47.7219 6.07281 47.7365 6.10614C47.751 6.13947 47.7585 6.17546 47.7585 6.21182C47.7585 6.24819 47.751 6.28416 47.7365 6.3175C47.7219 6.35083 47.7007 6.3808 47.674 6.40553C47.6473 6.43026 47.6158 6.44921 47.5815 6.4612C47.5471 6.47319 47.5107 6.47797 47.4744 6.47522H47.4729Z"
      fill="#1AB395"
    />
    <path
      d="M40.5168 11.4604C41.8938 11.4604 43.0101 10.3441 43.0101 8.96704C43.0101 7.59002 41.8938 6.47372 40.5168 6.47372C39.1397 6.47372 38.0234 7.59002 38.0234 8.96704C38.0234 10.3441 39.1397 11.4604 40.5168 11.4604Z"
      fill="#3F3D56"
    />
  </SVG>
)

export default withIconStyles(IconSurveyInsightsAddOn)
