/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWholeLearnerReportAddOn = (props) => (
  <SVG
    width="134"
    height="121"
    viewBox="0 0 134 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_32_1584)">
      <path
        d="M85.9688 46.1759H20.5976C19.022 46.174 17.5115 45.5473 16.3974 44.4332C15.2833 43.3191 14.6566 41.8086 14.6548 40.233V15.9452C14.6566 14.3696 15.2833 12.8591 16.3974 11.745C17.5115 10.6309 19.022 10.0041 20.5976 10.0023H85.9688C87.5444 10.0041 89.0549 10.6309 90.169 11.745C91.2831 12.8591 91.9098 14.3696 91.9116 15.9452V40.233C91.9098 41.8086 91.2831 43.3191 90.169 44.4332C89.0549 45.5473 87.5444 46.174 85.9688 46.1759Z"
        fill="white"
      />
    </g>
    <path
      d="M85.9722 46.1735H20.601C19.0254 46.1717 17.5149 45.545 16.4008 44.4309C15.2867 43.3168 14.66 41.8063 14.6582 40.2307V15.9428C14.66 14.3673 15.2867 12.8567 16.4008 11.7426C17.5149 10.6285 19.0254 10.0018 20.601 10H85.9722C87.5478 10.0018 89.0583 10.6285 90.1724 11.7426C91.2865 12.8567 91.9132 14.3673 91.915 15.9428V40.2307C91.9132 41.8063 91.2865 43.3168 90.1724 44.4309C89.0583 45.545 87.5478 46.1717 85.9722 46.1735ZM20.601 10.5196C19.1624 10.5212 17.7831 11.0934 16.7659 12.1107C15.7486 13.128 15.1764 14.5073 15.1747 15.9459V40.2338C15.1764 41.6724 15.7486 43.0517 16.7659 44.069C17.7831 45.0862 19.1624 45.6585 20.601 45.6601H85.9722C87.4109 45.6585 88.7901 45.0862 89.8074 44.069C90.8247 43.0517 91.3969 41.6724 91.3985 40.2338V15.9428C91.3969 14.5042 90.8247 13.1249 89.8074 12.1077C88.7901 11.0904 87.4109 10.5182 85.9722 10.5165L20.601 10.5196Z"
      fill="#E5E5E5"
    />
    <path
      d="M99.1254 49.4777H64.3571C64.1378 49.4752 63.9194 49.4477 63.7063 49.3956C63.0705 49.2424 62.505 48.8793 62.1011 48.3649C61.6972 47.8506 61.4785 47.2151 61.4805 46.5611V34.7415C61.4805 33.9679 61.7878 33.2261 62.3347 32.6792C62.8817 32.1322 63.6235 31.8249 64.3971 31.8249H99.1292C99.5973 31.8238 100.059 31.9369 100.473 32.1542C100.638 32.2407 100.794 32.3436 100.939 32.4612C101.285 32.732 101.565 33.0783 101.756 33.4736C101.948 33.8689 102.047 34.3028 102.046 34.7422V46.5619C102.046 46.9452 101.97 47.3246 101.823 47.6787C101.676 48.0327 101.461 48.3543 101.19 48.6251C100.919 48.8959 100.597 49.1106 100.242 49.2568C99.8882 49.4031 99.5086 49.4782 99.1254 49.4777Z"
      fill="white"
    />
    <path
      d="M96.7935 49.2206C98.372 45.383 100.035 41.5838 101.782 37.823V44.2003C100.591 46.0131 99.2122 47.6957 97.6692 49.2206H96.7935Z"
      fill="#E6E6E6"
    />
    <path
      d="M100.773 32.6638C97.6495 38.2682 94.381 43.7874 90.9676 49.2213H90.3735C93.8437 43.7024 97.1696 38.0911 100.351 32.3875C100.501 32.4654 100.642 32.5579 100.773 32.6638Z"
      fill="#E6E6E6"
    />
    <path
      d="M83.4797 38.7824C81.4383 42.3915 79.1855 45.877 76.7333 49.2206H73.1904C75.5109 46.3941 77.8329 43.5673 80.1564 40.7403C80.7501 39.8641 81.5783 39.1726 82.5464 38.7448L83.4797 38.7824Z"
      fill="#E6E6E6"
    />
    <path
      d="M79.5831 38.2029C79.2139 38.5867 78.8593 38.9881 78.5262 39.4041C77.7925 40.2875 77.068 41.1793 76.3427 42.0681C75.8906 42.6207 75.4385 43.1741 74.9911 43.7267C74.8491 43.6349 74.7144 43.5322 74.5882 43.4197C73.8507 42.807 73.3868 41.9264 73.2986 40.9717C73.2103 40.017 73.5049 39.0663 74.1176 38.3288C74.7303 37.5913 75.6109 37.1274 76.5657 37.0392C77.5204 36.951 78.4711 37.2456 79.2085 37.8583C79.342 37.9635 79.4672 38.0787 79.5831 38.2029Z"
      fill="#53B095"
    />
    <path
      d="M93.1231 40.715L86.3329 38.2321L82.9612 36.9995C81.8629 36.5988 80.7677 36.1414 79.6333 35.8521C78.647 35.5701 77.5996 35.5834 76.6208 35.8904C75.7479 36.2138 74.9491 36.7097 74.2722 37.3487C73.9724 37.625 73.682 37.91 73.4011 38.2037C73.0319 38.5875 72.6773 38.9889 72.3442 39.4049C71.6105 40.2883 70.8859 41.1801 70.1606 42.0689C69.7086 42.6215 69.2565 43.1749 68.8091 43.7275C67.3155 45.5541 65.8317 47.3852 64.3575 49.2206C64.1587 49.2177 63.961 49.1919 63.7681 49.1438C65.3082 47.2317 66.855 45.3239 68.4085 43.4205C68.5865 43.1979 68.7699 42.9738 68.9511 42.7512C69.7083 41.8236 70.4664 40.8969 71.2252 39.9713C71.8116 39.2552 72.3933 38.5345 73.0288 37.8606C73.0856 37.7985 73.1424 37.7394 73.2023 37.6772C74.4917 36.344 76.1012 35.1659 78.0292 35.1344C79.1584 35.1745 80.2711 35.4187 81.3133 35.8551C82.4477 36.2657 83.579 36.6848 84.7134 37.1008L91.5504 39.6021L93.2612 40.2276C93.559 40.3382 93.4301 40.8286 93.1231 40.715Z"
      fill="#E6E6E6"
    />
    <path
      d="M99.1254 49.4777H64.3571C64.1378 49.4752 63.9194 49.4477 63.7063 49.3956C63.0705 49.2424 62.505 48.8793 62.1011 48.3649C61.6972 47.8506 61.4785 47.2151 61.4805 46.5611V34.7415C61.4805 33.9679 61.7878 33.2261 62.3347 32.6792C62.8817 32.1322 63.6235 31.8249 64.3971 31.8249H99.1292C99.5973 31.8238 100.059 31.9369 100.473 32.1542C100.638 32.2407 100.794 32.3436 100.939 32.4612C101.285 32.732 101.565 33.0783 101.756 33.4736C101.948 33.8689 102.047 34.3028 102.046 34.7422V46.5619C102.046 46.9452 101.97 47.3246 101.823 47.6787C101.676 48.0327 101.461 48.3543 101.19 48.6251C100.919 48.8959 100.597 49.1106 100.242 49.2568C99.8882 49.4031 99.5086 49.4782 99.1254 49.4777ZM64.3932 32.3445C63.7572 32.3447 63.1473 32.5972 62.6972 33.0466C62.2471 33.4959 61.9937 34.1055 61.9925 34.7415V46.5611C61.9909 47.0995 62.1709 47.6226 62.5035 48.0459C62.8361 48.4691 63.3018 48.7678 63.8253 48.8936C63.9994 48.9362 64.1779 48.9589 64.3571 48.9611H99.1254C99.7619 48.9605 100.372 48.7074 100.822 48.2573C101.272 47.8072 101.526 47.1969 101.526 46.5604V34.7415C101.527 34.3799 101.446 34.0228 101.288 33.6976C101.129 33.3724 100.899 33.0876 100.614 32.8649C100.495 32.7689 100.367 32.6851 100.231 32.6147C99.8894 32.4358 99.5098 32.3428 99.1246 32.3438L64.3932 32.3445Z"
      fill="#E5E5E5"
    />
    <path
      d="M56.069 60.7693H5.94283C4.36726 60.7675 2.85674 60.1408 1.74264 59.0267C0.628537 57.9125 0.00182817 56.402 0 54.8264V46.0415C0.00182817 44.466 0.628537 42.9554 1.74264 41.8413C2.85674 40.7272 4.36726 40.1005 5.94283 40.0987H56.0697C57.6453 40.1005 59.1558 40.7272 60.2699 41.8413C61.384 42.9554 62.0107 44.466 62.0126 46.0415V54.8264C62.0107 56.4022 61.3839 57.9128 60.2697 59.0269C59.1554 60.1411 57.6447 60.7677 56.069 60.7693Z"
      fill="white"
    />
    <path
      d="M56.069 60.7693H5.94283C4.36726 60.7675 2.85674 60.1408 1.74264 59.0267C0.628537 57.9125 0.00182817 56.402 0 54.8264V46.0415C0.00182817 44.466 0.628537 42.9554 1.74264 41.8413C2.85674 40.7272 4.36726 40.1005 5.94283 40.0987H56.0697C57.6453 40.1005 59.1558 40.7272 60.2699 41.8413C61.384 42.9554 62.0107 44.466 62.0126 46.0415V54.8264C62.0107 56.4022 61.3839 57.9128 60.2697 59.0269C59.1554 60.1411 57.6447 60.7677 56.069 60.7693ZM5.94207 40.6152C4.50343 40.6169 3.12417 41.1891 2.10689 42.2063C1.08962 43.2236 0.51739 44.6029 0.515765 46.0415V54.8264C0.51739 56.2651 1.08962 57.6444 2.10689 58.6616C3.12417 59.6789 4.50343 60.2511 5.94207 60.2528H56.069C57.5076 60.2511 58.8868 59.6789 59.9041 58.6616C60.9214 57.6444 61.4936 56.2651 61.4953 54.8264V46.0415C61.4936 44.6029 60.9214 43.2236 59.9041 42.2063C58.8868 41.1891 57.5076 40.6169 56.069 40.6152H5.94207Z"
      fill="#E5E5E5"
    />
    <path
      d="M20.6617 55.828H12.6029C12.172 55.8276 11.759 55.6563 11.4544 55.3517C11.1497 55.0471 10.9784 54.6341 10.978 54.2032V46.6647C10.9784 46.2339 11.1497 45.8209 11.4544 45.5163C11.759 45.2117 12.172 45.0403 12.6029 45.0399H20.6617C21.0925 45.0403 21.5056 45.2117 21.8102 45.5163C22.1148 45.8209 22.2861 46.2339 22.2865 46.6647V54.2032C22.2861 54.6341 22.1148 55.0471 21.8102 55.3517C21.5056 55.6563 21.0925 55.8276 20.6617 55.828Z"
      fill="#53B095"
    />
    <path
      d="M29.4478 55.828H21.389C20.9582 55.8276 20.5451 55.6563 20.2405 55.3517C19.9359 55.0471 19.7646 54.6341 19.7642 54.2032V46.6647C19.7646 46.2339 19.9359 45.8209 20.2405 45.5163C20.5451 45.2117 20.9582 45.0403 21.389 45.0399H29.4478C29.8786 45.0403 30.2917 45.2117 30.5963 45.5163C30.9009 45.8209 31.0723 46.2339 31.0727 46.6647V54.2032C31.0723 54.6341 30.9009 55.0471 30.5963 55.3517C30.2917 55.6563 29.8786 55.8276 29.4478 55.828Z"
      fill="#E6E6E6"
    />
    <path
      d="M40.6544 55.828H32.5955C32.1647 55.8276 31.7517 55.6563 31.447 55.3517C31.1424 55.0471 30.9711 54.6341 30.9707 54.2032V46.6647C30.9711 46.2339 31.1424 45.8209 31.447 45.5163C31.7517 45.2117 32.1647 45.0403 32.5955 45.0399H40.6544C41.0852 45.0403 41.4982 45.2117 41.8028 45.5163C42.1075 45.8209 42.2788 46.2339 42.2792 46.6647V54.2032C42.2788 54.6341 42.1075 55.0471 41.8028 55.3517C41.4982 55.6563 41.0852 55.8276 40.6544 55.828Z"
      fill="#E6E6E6"
    />
    <path
      d="M57.724 24.334H47.3887C47.2506 24.3378 47.1131 24.3138 46.9845 24.2636C46.8558 24.2134 46.7385 24.1378 46.6395 24.0415C46.5405 23.9452 46.4618 23.83 46.4081 23.7027C46.3543 23.5754 46.3267 23.4387 46.3267 23.3006C46.3267 23.1624 46.3543 23.0257 46.4081 22.8984C46.4618 22.7712 46.5405 22.656 46.6395 22.5597C46.7385 22.4633 46.8558 22.3878 46.9845 22.3375C47.1131 22.2873 47.2506 22.2634 47.3887 22.2671H57.724C57.862 22.2634 57.9995 22.2873 58.1281 22.3375C58.2568 22.3878 58.3741 22.4633 58.4731 22.5597C58.5721 22.656 58.6508 22.7712 58.7045 22.8984C58.7583 23.0257 58.786 23.1624 58.786 23.3006C58.786 23.4387 58.7583 23.5754 58.7045 23.7027C58.6508 23.83 58.5721 23.9452 58.4731 24.0415C58.3741 24.1378 58.2568 24.2134 58.1281 24.2636C57.9995 24.3138 57.862 24.3378 57.724 24.334Z"
      fill="#CCCCCC"
    />
    <path
      d="M76.8725 30.0182H44.0576C43.9195 30.022 43.7821 29.998 43.6534 29.9478C43.5247 29.8975 43.4074 29.822 43.3084 29.7257C43.2094 29.6293 43.1308 29.5141 43.077 29.3869C43.0233 29.2596 42.9956 29.1229 42.9956 28.9848C42.9956 28.8466 43.0233 28.7099 43.077 28.5826C43.1308 28.4554 43.2094 28.3402 43.3084 28.2438C43.4074 28.1475 43.5247 28.072 43.6534 28.0217C43.7821 27.9715 43.9195 27.9475 44.0576 27.9513H76.8725C77.1417 27.9586 77.3975 28.0707 77.5853 28.2638C77.7731 28.4568 77.8782 28.7154 77.8782 28.9848C77.8782 29.2541 77.7731 29.5128 77.5853 29.7058C77.3975 29.8988 77.1417 30.0109 76.8725 30.0182Z"
      fill="#CCCCCC"
    />
    <g filter="url(#filter1_d_32_1584)">
      <path
        d="M97.67 90.9969H32.2988C30.7234 90.9951 29.2129 90.3685 28.0989 89.2546C26.9848 88.1406 26.358 86.6303 26.356 85.0549V60.7662C26.3578 59.1907 26.9845 57.6801 28.0986 56.566C29.2127 55.4519 30.7232 54.8252 32.2988 54.8234H97.67C99.2456 54.8252 100.756 55.4519 101.87 56.566C102.984 57.6801 103.611 59.1907 103.613 60.7662V85.0549C103.611 86.6303 102.984 88.1406 101.87 89.2546C100.756 90.3685 99.2454 90.9951 97.67 90.9969Z"
        fill="white"
      />
    </g>
    <path
      d="M97.671 91H32.2998C30.7243 90.9982 29.2139 90.3716 28.0998 89.2576C26.9858 88.1437 26.359 86.6334 26.3569 85.0579V60.7693C26.3588 59.1937 26.9855 57.6832 28.0996 56.5691C29.2137 55.455 30.7242 54.8283 32.2998 54.8265H97.671C99.2465 54.8283 100.757 55.455 101.871 56.5691C102.985 57.6832 103.612 59.1937 103.614 60.7693V85.0579C103.612 86.6334 102.985 88.1437 101.871 89.2576C100.757 90.3716 99.2464 90.9982 97.671 91ZM32.2998 55.343C30.8611 55.3446 29.4819 55.9168 28.4646 56.9341C27.4473 57.9514 26.8751 59.3306 26.8735 60.7693V85.0579C26.8751 86.4966 27.4473 87.8758 28.4646 88.8931C29.4819 89.9104 30.8611 90.4826 32.2998 90.4842H97.671C99.1101 90.4834 100.49 89.9116 101.508 88.8942C102.526 87.8768 103.099 86.4971 103.1 85.0579V60.7693C103.099 59.3306 102.526 57.9514 101.509 56.9341C100.492 55.9168 99.1126 55.3446 97.674 55.343H32.2998Z"
      fill="#E5E5E5"
    />
    <path
      d="M67.7848 64.4495H56.8047C56.5267 64.4495 56.26 64.3391 56.0634 64.1424C55.8668 63.9458 55.7563 63.6791 55.7563 63.4011C55.7563 63.123 55.8668 62.8564 56.0634 62.6597C56.26 62.4631 56.5267 62.3527 56.8047 62.3527H67.7855C68.0636 62.3527 68.3303 62.4631 68.5269 62.6597C68.7235 62.8564 68.834 63.123 68.834 63.4011C68.834 63.6791 68.7235 63.9458 68.5269 64.1424C68.3303 64.3391 68.0628 64.4495 67.7848 64.4495Z"
      fill="#53B095"
    />
    <path
      d="M88.1008 68.7391H52.5059C52.2279 68.7391 51.9612 68.6287 51.7646 68.432C51.568 68.2354 51.4575 67.9688 51.4575 67.6907C51.4575 67.4126 51.568 67.146 51.7646 66.9494C51.9612 66.7527 52.2279 66.6423 52.5059 66.6423H88.1008C88.3788 66.6423 88.6455 66.7527 88.8421 66.9494C89.0387 67.146 89.1492 67.4126 89.1492 67.6907C89.1492 67.9688 89.0387 68.2354 88.8421 68.432C88.6455 68.6287 88.3788 68.7391 88.1008 68.7391Z"
      fill="#E6E6E6"
    />
    <path
      d="M88.1008 73.6612H52.5059C52.2279 73.6612 51.9612 73.5507 51.7646 73.3541C51.568 73.1575 51.4575 72.8908 51.4575 72.6127C51.4575 72.3347 51.568 72.068 51.7646 71.8714C51.9612 71.6748 52.2279 71.5643 52.5059 71.5643H88.1008C88.3788 71.5643 88.6455 71.6748 88.8421 71.8714C89.0387 72.068 89.1492 72.3347 89.1492 72.6127C89.1492 72.8908 89.0387 73.1575 88.8421 73.3541C88.6455 73.5507 88.3788 73.6612 88.1008 73.6612Z"
      fill="#E6E6E6"
    />
    <path
      d="M88.1008 78.584H52.5059C52.2279 78.584 51.9612 78.4735 51.7646 78.2769C51.568 78.0803 51.4575 77.8136 51.4575 77.5356C51.4575 77.2575 51.568 76.9908 51.7646 76.7942C51.9612 76.5976 52.2279 76.4871 52.5059 76.4871H88.1008C88.3788 76.4871 88.6455 76.5976 88.8421 76.7942C89.0387 76.9908 89.1492 77.2575 89.1492 77.5356C89.1492 77.8136 89.0387 78.0803 88.8421 78.2769C88.6455 78.4735 88.3788 78.584 88.1008 78.584Z"
      fill="#E6E6E6"
    />
    <path
      d="M88.1008 83.5075H52.5059C52.2279 83.5075 51.9612 83.3971 51.7646 83.2005C51.568 83.0039 51.4575 82.7372 51.4575 82.4591C51.4575 82.1811 51.568 81.9144 51.7646 81.7178C51.9612 81.5212 52.2279 81.4107 52.5059 81.4107H88.1008C88.3788 81.4107 88.6455 81.5212 88.8421 81.7178C89.0387 81.9144 89.1492 82.1811 89.1492 82.4591C89.1492 82.7372 89.0387 83.0039 88.8421 83.2005C88.6455 83.3971 88.3788 83.5075 88.1008 83.5075Z"
      fill="#E6E6E6"
    />
    <path
      d="M40.2784 23.9894C40.2673 26.4884 39.3907 28.9063 37.7979 30.8319C36.205 32.7574 33.9942 34.0717 31.5417 34.551C29.0891 35.0303 26.5462 34.6451 24.3455 33.461C22.1449 32.2768 20.4225 30.3668 19.4714 28.0559C18.5202 25.745 18.3991 23.176 19.1285 20.7858C19.858 18.3956 21.3929 16.332 23.4723 14.946C25.5517 13.56 28.0471 12.9372 30.5339 13.1836C33.0208 13.43 35.3454 14.5303 37.1124 16.2974C38.1198 17.3085 38.9179 18.5083 39.4612 19.8282C40.0044 21.1481 40.2821 22.5621 40.2784 23.9894Z"
      fill="white"
    />
    <path
      d="M29.4199 35.0393C26.5249 34.9853 23.7666 33.7974 21.7384 31.7309C19.7101 29.6645 18.5737 26.8846 18.5737 23.989C18.5737 21.0935 19.7101 18.3136 21.7384 16.2471C23.7666 14.1807 26.5249 12.9927 29.4199 12.9388H29.4683C32.3635 12.9987 35.1195 14.1925 37.1436 16.2634C39.1676 18.3344 40.2981 21.117 40.2917 24.0128C40.2854 26.9085 39.1428 29.6862 37.1097 31.7482C35.0766 33.8103 32.3154 34.992 29.4199 35.0393ZM29.4683 13.3225H29.4207C26.9661 13.3334 24.5911 14.1943 22.6997 15.7589C20.8082 17.3234 19.5173 19.4949 19.0464 21.904C18.5755 24.313 18.9538 26.8108 20.1169 28.9724C21.28 31.134 23.156 32.8258 25.4259 33.7601C27.6958 34.6945 30.2192 34.8135 32.567 34.0971C34.9147 33.3806 36.9418 31.8729 38.3032 29.8305C39.6647 27.788 40.2764 25.3369 40.0345 22.8943C39.7925 20.4516 38.7118 18.1682 36.9761 16.4325C35.9923 15.4434 34.8222 14.6592 33.5333 14.1254C32.2445 13.5916 30.8633 13.3187 29.4683 13.3225Z"
      fill="#53B095"
    />
    <path
      d="M32.2378 21.6969L32.1994 21.7667C32.3461 21.332 32.3694 20.8651 32.2665 20.4179C32.1637 19.9707 31.9388 19.5609 31.6169 19.2339C30.9876 18.635 30.1748 18.2659 29.3097 18.1863C28.686 18.0763 28.0446 18.1262 27.4455 18.3313C26.5859 18.7447 25.8682 19.4036 25.3831 20.2248C25.0947 20.5736 24.9289 21.0075 24.9111 21.4597C24.98 21.8718 25.1442 22.2621 25.3908 22.5995C25.5126 22.7705 25.5962 22.9657 25.636 23.1719C25.6757 23.3781 25.6706 23.5904 25.6211 23.7945C25.5112 24.0126 25.3681 24.2123 25.1967 24.3862C25.111 24.4773 25.0481 24.5874 25.013 24.7074C24.9779 24.8275 24.9717 24.9541 24.9948 25.077C25.027 25.1686 25.0825 25.2504 25.1559 25.3142C25.2292 25.3779 25.3178 25.4216 25.4131 25.4408C25.5975 25.4716 25.786 25.4672 25.9688 25.4277L30.7573 24.704C31.0826 24.6548 31.3913 24.5277 31.6569 24.3334C31.9225 24.1392 32.1372 23.8836 32.2827 23.5884C32.4282 23.2933 32.5002 22.9674 32.4925 22.6384C32.4848 22.3094 32.3977 21.9872 32.2386 21.6992L32.2378 21.6969Z"
      fill="#2F2E41"
    />
    <path
      d="M28.565 24.4184C29.8579 24.4184 30.9059 23.37 30.9059 22.0768C30.9059 20.7835 29.8579 19.7351 28.565 19.7351C27.2722 19.7351 26.2241 20.7835 26.2241 22.0768C26.2241 23.37 27.2722 24.4184 28.565 24.4184Z"
      fill="#FFB9B9"
    />
    <path
      d="M34.9045 27.3856C33.5511 28.9257 31.6464 29.8717 29.6016 30.0195C27.5567 30.1672 25.5358 29.5049 23.9751 28.1754L24.6451 26.3057C24.7966 25.9737 25.0355 25.6891 25.3363 25.4825C25.6372 25.2759 25.9886 25.1551 26.3528 25.133L26.7788 25.1069L27.795 24.2304C27.795 24.2304 30.5335 24.1329 30.4928 24.0861L32.1514 25.0839V25.077L32.8038 25.1169C33.1681 25.1388 33.5196 25.2596 33.8204 25.4662C34.1213 25.6728 34.3602 25.9575 34.5115 26.2896L34.9045 27.3856Z"
      fill="#53B095"
    />
    <path
      d="M30.8999 20.0459C30.8393 19.7259 30.5161 19.5801 30.2337 19.5087C29.4234 19.3018 28.5805 19.2554 27.7523 19.3721C27.1294 19.4226 26.543 19.6867 26.0922 20.1196C25.7392 20.5165 25.5392 21.0261 25.5281 21.5572C25.5102 21.8747 25.5812 22.191 25.733 22.4705C25.8007 22.6008 25.9013 22.7111 26.0247 22.7907C26.1482 22.8702 26.2902 22.9162 26.4368 22.9241L26.4652 23.0216C27.0713 22.9239 27.649 22.6965 28.159 22.3548C28.669 22.0132 29.0992 21.5654 29.4201 21.0422C29.2912 21.503 29.0345 21.9178 28.6795 22.2387C29.4741 21.9218 30.1649 21.3904 30.675 20.7037C30.7557 20.6154 30.8173 20.5114 30.856 20.3983C30.8947 20.2851 30.9097 20.1652 30.8999 20.0459Z"
      fill="#2F2E41"
    />
    <defs>
      <filter
        id="filter0_d_32_1584"
        x="4.65479"
        y="0.00231934"
        width="117.257"
        height="76.1735"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="10" dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.059 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1584"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1584"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_32_1584"
        x="16.356"
        y="44.8234"
        width="117.257"
        height="76.1735"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="10" dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.059 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_32_1584"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_32_1584"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
)

export default withIconStyles(IconWholeLearnerReportAddOn)
