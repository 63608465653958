/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSchool = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="52.75"
    height="47.821"
    viewBox="0 0 52.75 47.821"
    {...props}
  >
    <g opacity="0.5">
      <g transform="translate(0 0)">
        <path
          d="M51.841,43.9H40.209V41.652a.909.909,0,0,0-.464-.791l-12.46-7V30.293h2.465v1.355a.91.91,0,0,0,.909.909h7.431a.909.909,0,0,0,.855-1.219L38.2,29.22l.755-2.074a.91.91,0,0,0-.855-1.219H34.715V24.609a.91.91,0,0,0-.909-.909H27.285v-1.11a.909.909,0,0,0-1.819,0V33.858l-12.46,6.994a.909.909,0,0,0-.464.791V43.89H.909A.91.91,0,0,0,0,44.8V68.591a.91.91,0,0,0,.909.909H51.841a.91.91,0,0,0,.909-.909V44.808A.91.91,0,0,0,51.841,43.9ZM34.715,29.383l-.036-.036V27.783h2.074l-.373,1.164a.909.909,0,0,0,0,.628l.427,1.164H31.568v-.446h2.237A.91.91,0,0,0,34.715,29.383Zm-7.431-3.865H32.9v2.956H27.285ZM12.542,67.682H1.819V45.718H12.542Zm12.924,0H20.691V57.3h4.775Zm6.594,0H27.285V57.3H32.06Zm6.366,0H33.878v-11.3a.91.91,0,0,0-.909-.909H19.781a.91.91,0,0,0-.909.909v11.3H14.324V42.216l12.051-6.785L38.39,42.18l.036,2.619Zm12.505,0H40.209V45.718H50.931Z"
          transform="translate(0 -21.68)"
          fill="#637885"
        />
      </g>
      <g transform="translate(22.564 16.535)">
        <path
          d="M202.291,167.12a3.811,3.811,0,1,0,3.811,3.8A3.82,3.82,0,0,0,202.291,167.12Zm0,5.8a1.992,1.992,0,1,1,1.992-1.983h.009A1.992,1.992,0,0,1,202.291,172.923Z"
          transform="translate(-198.48 -167.12)"
          fill="#637885"
        />
      </g>
      <g transform="translate(4.011 26.039)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(8.495 26.039)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(17.089 26.039)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(24.556 26.039)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(32.023 26.039)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(17.089 30.277)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(24.556 30.277)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(32.023 30.277)">
        <rect width="3.638" height="1.819" fill="#637885" />
      </g>
      <g transform="translate(4.011 33.196)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(8.495 33.196)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(4.011 40.363)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(8.495 40.363)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(42.437 26.039)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(46.921 26.039)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(42.437 33.196)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(46.921 33.196)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(42.437 40.363)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
      <g transform="translate(46.921 40.363)">
        <rect width="1.819" height="3.638" fill="#637885" />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconSchool)
