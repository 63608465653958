const homePlaylistPath = '/home/playlist'
const homeStudentAssignmentsPath = '/home/assignments'
const studentSebQuitConfirmpath = '/student/seb-quit-confirm'
const homeStudentGradesPath = '/home/grades'

export {
  homePlaylistPath,
  homeStudentAssignmentsPath,
  studentSebQuitConfirmpath,
  homeStudentGradesPath,
}
